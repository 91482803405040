import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchStoreFront,
  getStoreFront,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import { useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { generateOptions, parseCsv } from "../../utils/Utils";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import ProgressBar from "../../components/progressBar/ProgressBar";
import {
  fetchCoupon,
  getCoupons,
  updateCoupon,
  updateGeneralCoupon,
} from "../../app/reducers/Coupon/couponSlice";
import { fetchBrands, getBrands } from "../../app/reducers/Brand/brandSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import {
  COUPON_PAGE_TYPE,
  COUPON_USES_TYPE,
} from "../../utils/dropdownOptions";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { array, boolean, number, object, string } from "yup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";

const EditGeneralCOupon = () => {
  const { id } = useParams();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { elementEditData } = useSelector(getCoupons);
  const dispatch = useDispatch();
  const { brands } = useSelector(getBrands);

  const brandArr = useMemo(() => (brands ? brands : [][brands]));
  useEffect(() => {
    dispatch(fetchBrands());
  }, []);
  useEffect(() => {
    dispatch(fetchCoupon({ _id: id, __t: "generalCoupon" }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
      is_public: elementEditData?.is_public || false,
    },
    validationSchema: object({
      code: string().required(),
      expiryDate: string().required(),
      maxUse: number().required(),
      status: boolean().required(),
      rate: number().required(),
      photo: string(),
      description: string(),
      minPrice: number(),
      category: string(),
      brand: array(),
      type: string().required(),
      is_public: boolean().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(updateGeneralCoupon({ ...values }));
    },
  });
  return (
    <PageWithCard heading="Edit General Coupon">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikInputGroup
          name={"group_name"}
          label={"Group Name"}
          formik={formik}
          required
        />
        <FormikInputGroup
          label="Coupon Code"
          name="code"
          formik={formik}
          required
        />
        <FormikInputDateGroup
          label="Valid Till"
          name="expiryDate"
          formik={formik}
          required
        />
        <FormikInputGroup
          type="number"
          label="Maximum Use"
          name="maxUse"
          formik={formik}
          required
        />
        <FormikInputGroup
          type="number"
          label="Rate"
          name="rate"
          formik={formik}
          required
        />
        <FormikDirectFileUpload
          name={`photo`}
          formik={formik}
          label={`Photo`}
          location={`eyesdeal/coupon/`}
          randomize={true}
        />
        <FormikTextareaGroup
          name="description"
          formik={formik}
          label="Description"
        />
        <FormikSelectGroup
          name={`type`}
          label="Select Type"
          formik={formik}
          options={generateOptions({
            array: COUPON_PAGE_TYPE,
            labelField: "label",
            valueField: "value",
          })}
        />
        <FormikSelectGroup
          formik={formik}
          name="category"
          label="Select Category"
          options={generateOptions({
            array: [
              { label: "Eye Glasses", value: "eyeGlasses" },
              { label: "Accessories", value: "accessories" },
              { label: "Sun Glasses", value: "sunGlasses" },
              { label: "Spectacle Lens", value: "spectacleLens" },
              { label: "Contact Lens", value: "contactLens" },
              { label: "Reading Glasses", value: "readingGlasses" },
              { label: "Contact Solutions", value: "contactSolutions" },
            ],
            valueField: "value",
            labelField: "label",
          })}
        />
        <FormikMultiSelect
          formik={formik}
          name="brand"
          label="Select Brand"
          options={generateOptions({
            array: brandArr,
            valueField: "_id",
            labelField: "name",
          })}
        />
        <FormikInputGroup
          type="number"
          label="Min Price"
          name="minPrice"
          formik={formik}
          required
        />
        <FormikSelectGroup
          name={"uses_type"}
          label="Uses type"
          formik={formik}
          options={COUPON_USES_TYPE}
        />
        <FormikCheckbox name="status" label="Status" formik={formik} />
        <FormikCheckbox
          name="is_public"
          label="Show in public"
          formik={formik}
        />
        <FormikCheckbox
          name="is_global"
          label="Global Coupon"
          formik={formik}
        />
        <div>
          <PrimaryButton type="submit">submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default EditGeneralCOupon;
