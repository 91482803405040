import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react';
import { BILL_NUMBER_PADDING } from '../../utils/dropdownOptions';
import { ChevronDown, ChevronRight } from 'react-feather';

const ExpandingSaleRow = ({ sale }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="border border-black">{moment(sale.createdAt).format('DD/MM/YYYY')}</div>
      <div className="border border-black">
        {sale.store.storeNumber}
        {numeral(sale.saleNumber).format(BILL_NUMBER_PADDING)}
      </div>
      <div className="flex justify-end border border-black">
        {sale.netAmount}{' '}
        {expanded ? (
          <ChevronDown
            onClick={e => {
              e.stopPropagation();
              setExpanded(false);
            }}
          />
        ) : (
          <ChevronRight
            className="cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              setExpanded(true);
            }}
          />
        )}
      </div>
      {expanded && (
        <div className="col-span-3 p-4">
          <div className="grid grid-cols-5">
            <div className="border border-black p-2">Product</div>
            <div className="border border-black p-2">Lens</div>
            <div className="border border-black p-2">MRP</div>
            <div className="border border-black p-2">Discount</div>
            <div className="border border-black p-2">Total</div>
            {sale?.orders.map(ele => (
              <React.Fragment key={ele._id}>
                <div className="border border-black p-2">
                  {!_.isEmpty(ele.product) && ele.product.sku}
                </div>
                <div className="border border-black p-2">
                  {!_.isEmpty(ele.lens) && ele.lens.sku}
                </div>
                <div className="border border-black p-2 flex flex-col">
                  <span>{!_.isEmpty(ele.product) && `Product : ${ele.product.mrp}`}</span>
                  <span>{!_.isEmpty(ele.lens) && `Lens : ${ele.lens.mrp}`}</span>
                </div>
                <div className="border border-black p-2 flex flex-col">
                  <span>
                    {!_.isEmpty(ele.product) && `Product : ${ele.product.perPieceDiscount}`}
                  </span>
                  <span>{!_.isEmpty(ele.lens) && `Lens : ${ele.lens.perPieceDiscount}`}</span>
                </div>
                <div className="border border-black p-2 flex flex-col">
                  <span>
                    {!_.isEmpty(ele.product) && `Product : ${ele.product.perPieceAmount}`}
                  </span>
                  <span>{!_.isEmpty(ele.lens) && `Lens : ${ele.lens.perPieceAmount}`}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpandingSaleRow;
