import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMediaLibrary,
  openAssetsModal,
  setMediaPage,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import MediaLibraryAddFile from "./MediaLibraryAddFile";
import MediaLibraryAddImage from "./MediaLibraryAddImage";

const AddAssetModal = () => {
  const { mediaLibraryPage, addAssetsModalOpen } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();

  const setModalOpen = (state) => {
    dispatch(openAssetsModal(state));
  };
  return (
    <ModalBasic
      title="Edit User"
      modalOpen={addAssetsModalOpen}
      setModalOpen={setModalOpen}
    >
      <div className="p-2">
        <div className="border-b border-slate-200">
          <ul className="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li className="cursor-pointer pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className={`${
                  mediaLibraryPage === "image"
                    ? "text-indigo-500"
                    : "text-slate-500 hover:text-slate-600"
                } whitespace-nowrap`}
                onClick={() => {
                  dispatch(setMediaPage("image"));
                }}
              >
                Add Image
              </a>
            </li>
            <li className="cursor-pointer pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className={`${
                  mediaLibraryPage === "file"
                    ? "text-indigo-500"
                    : "text-slate-500 hover:text-slate-600"
                } whitespace-nowrap`}
                onClick={() => {
                  dispatch(setMediaPage("file"));
                }}
              >
                Add File
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="p-2">
        {
          {
            image: <MediaLibraryAddImage />,
            file: <MediaLibraryAddFile />,
          }[mediaLibraryPage]
        }
      </div>
    </ModalBasic>
  );
};

export default AddAssetModal;
