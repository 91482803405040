export const COMPONENTS_LIST = [
  { label: "Product Slider", value: "productSlider" },
  { label: "Image Banner", value: "imageBanner" },
  { label: "Two Column Container", value: "twoColumnContainer" },
  { label: "Testimonial Container", value: "testimonialContainer" },
  { label: "Collapse Container", value: "collapseContainer" },
  { label: "Paragraph Container", value: "paragraphContainer" },
  { label: "Storefront Banner", value: "storefrontBanner" },
  { label: "Storefront Slider", value: "storefrontSlider" },
  { label: "Storefront Grid", value: "storefrontGrid" },
  { label: "Image grid container", value: "imageGridContainer" },
  { label: "Image Slider", value: "imageSlider" },
];

export const PRODUCT_TYPE = [
  { label: "accessories", value: "accessories" },
  { label: "eyeGlasses", value: "eyeGlasses" },
  { label: "sunGlasses", value: "sunGlasses" },
  { label: "contactLens", value: "contactLens" },
  { label: "readingGlasses", value: "readingGlasses" },
];
export const GENDER = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
  { label: "unisex", value: "unisex" },
  { label: "kids", value: "kids" },
];
export const ROLES = [
  { label: "Admin", value: "admin" },
  { label: "Attendee", value: "attendee" },
  { label: "Crew", value: "crew" },
];
export const VOLUME = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
export const FORM = [
  { label: "Liquid", value: "LIQ" },
  { label: "Powder", value: "POW" },
  { label: "SaltFree", value: "SF" },
  { label: "Press Cake", value: "PC" },
  { label: "Granules", value: "GR" },
  { label: "Crystal", value: "CR" },
];

export const TSHIRT_SIZE = [
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "Xxl", value: "Xxl" },
  { label: "XXxl", value: "XXxl" },
];
export const FOOD_PREFERENCE = [
  { label: "Veg", value: "Veg" },
  { label: "Non veg", value: "Non veg" },
  { label: "Jain", value: "Jain" },
];
export const MUSIC_PREFERENCE = [
  { label: "Rock", value: "Rock" },
  { label: "Jazz", value: "Jazz" },
  { label: "Bollywood party", value: "Bollywood party" },
  { label: "Sufi", value: "Sufi" },
  { label: "Ghazal", value: "Ghazal" },
];
export const CREW_MEMBERS = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];
export const TITLE = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
];

export const BILL_NUMBER_PADDING = "00000";

export const PRESCRIBEDBY = [
  { label: "Doctor", value: "doctor" },
  { label: "Employee", value: "employee" },
  { label: "Old Specs", value: "Old Specs" },
];
export const PRESCRIPTIONTYPE = [
  { label: "Specs", value: "specs" },
  { label: "Contact", value: "contacts" },
];
export const RELATIONS = [
  { label: "self", value: "self" },
  { label: "Son", value: "son" },
  { label: "Daughter", value: "daughter" },
  { label: "Brother", value: "brother" },
  { label: "Sister", value: "sister" },
  { label: "Friend", value: "friend" },
  { label: "Mother", value: "mother" },
  { label: "Father", value: "father" },
];

export const COUPON_PAGE_TYPE = [
  { label: "Percentage", value: "percentage" },
  { label: "Amount", value: "amount" },
];

export const PAYMENT_METHODS = [
  { label: "Cash", value: "cash" },
  { label: "UPI", value: "bank" },
  { label: "Card", value: "card" },
];

export const DEPARTMENTS = [
  { label: "LIAISONING", value: "LIAISONING" },
  { label: "ACCOUNTS", value: "ACCOUNTS" },
  { label: "DRAFTING", value: "DRAFTING" },
  { label: "CIVIL ENGINEERING", value: "CIVIL ENGINEERING" },
  { label: "INTERIOR DESIGNING", value: "INTERIOR DESIGNING" },
  { label: "URBAN/REGIONAL DESIGNING", value: "URBAN/REGIONAL DESIGNING" },
  { label: "OFFICE MANAGEMENT AND HR", value: "OFFICE MANAGEMENT AND HR" },
  { label: "DESIGNING/ARCHITECTURE", value: "DESIGNING/ARCHITECTURE" },

  { label: "OFFICE CARE", value: "OFFICE CARE" },
  { label: "HOD (DESIGNING/ARCHITECT)", value: "HOD (DESIGNING/ARCHITECT)" },
  { label: "ARCHITECT", value: "ARCHITECT" },
  { label: "DESIGNING", value: "DESIGNING" },
  { label: "RECEPTION", value: "RECEPTION" },
];

export const DESIGNATIONS = [
  { label: "JUNIOR ARCHITECT", value: "JUNIOR ARCHITECT" },
  { label: "FRONT DESK", value: "FRONT DESK" },
  { label: "CAD TECHNICIAN", value: "CAD TECHNICIAN" },
  { label: "HEAD DRAFTING DEPARTMENT", value: "HEAD DRAFTING DEPARTMENT" },
  { label: "OFFICE COORDINATOR", value: "OFFICE COORDINATOR" },
  { label: "URBAN DESIGNER", value: "URBAN DESIGNER" },
  { label: "CAD OPERATOR", value: "CAD OPERATOR" },
  { label: "LIAISON PERSONAL", value: "LIAISON PERSONAL" },
  { label: "SOFTWARE ENGINEER", value: "SOFTWARE ENGINEER" },
  { label: "SITE COORDINATOR", value: "SITE COORDINATOR" },
  { label: "COORDINATOR", value: "COORDINATOR" },
  { label: "LIAISON WORK COORDINATOR", value: "LIAISON WORK COORDINATOR" },
  { label: "OFFICE CARETAKER", value: "OFFICE CARETAKER" },
  { label: "PRINCIPAL ARCHITECT", value: "PRINCIPAL ARCHITECT" },
  { label: "MEP DESIGNER", value: "MEP DESIGNER" },
  { label: "ACCOUNTANT", value: "ACCOUNTANT" },
  { label: "HR & ADMIN", value: "HR & ADMIN" },
  { label: "CIVIL ENGINEER", value: "CIVIL ENGINEER" },
  { label: "SENIOR ARCHITECT", value: "SENIOR ARCHITECT" },
  { label: "LANDSCAPE DESIGNER", value: "LANDSCAPE DESIGNER" },
  { label: "DESIGNER / ARCHITECT", value: "DESIGNER / ARCHITECT" },
  { label: "FRONT OFFICE EXECUTIVE", value: "FRONT OFFICE EXECUTIVE" },
  { label: "TRAINEE", value: "TRAINEE" },
  { label: "DRIVER", value: "DRIVER" },
  { label: "PROJECT MANAGEMENT", value: "PROJECT MANAGEMENT" },
  { label: "COORDINATOR ARCHITECT", value: "COORDINATOR ARCHITECT" },
  { label: "STRUCTURAL ENGINEER", value: "STRUCTURAL ENGINEER" },
  { label: "TELEPHONE OPERATOR", value: "TELEPHONE OPERATOR" },
  { label: "SITE ENGINEER", value: "SITE ENGINEER" },
];

export const AREA_OF_CHECKING = [
  { label: "EXCAVATION", value: "EXCAVATION" },
  { label: "LINE OUT", value: "LINE OUT" },
  { label: "CENTERLINE", value: "CENTERLINE" },
  { label: "FOOTING", value: "FOOTING" },
  { label: "COLUMN STARTER", value: "COLUMN STARTER" },
  { label: "SLAB", value: "SLAB" },
  { label: "SITE VISIT & SITE PHOTOGRAPHS", value: "SITE VISIT PHOTOGRAPH" },
  { label: "OTHERS", value: "OTHERS" },
];

export const PAGE_STATUS = [
  { label: "Draft", value: "draft" },
  { label: "Published", value: "published" },
];

export const generateRx = ({ start, end, step, showDecimal = true }) => {
  const result = [];
  for (let i = start; i <= end; i += step) {
    if (showDecimal) {
      if (i === 0) {
        result.push(
          {
            label: i.toFixed(2).toString(),
            value: "0",
          },
          {
            label: "Select",
            value: "",
          }
        );
      } else {
        result.push({
          label:
            i > 0 ? "+" + i.toFixed(2).toString() : i.toFixed(2).toString(),
          value:
            i > 0 ? "+" + i.toFixed(2).toString() : i.toFixed(2).toString(),
        });
      }
    } else {
      if (i === 0) {
        result.push({
          label: i.toString(),
          value: "",
        });
      } else {
        result.push({
          label: i,
          value: i,
        });
      }
    }
  }
  return result;
};

export const DISTANCEVISIONSPECS = [
  { label: "6/5", value: "6/5" },
  { label: "6/6", value: "6/6" },
  { label: "6/9", value: "6/9" },
  { label: "6/12", value: "6/12" },
  { label: "6/18", value: "6/18" },
  { label: "6/24", value: "6/24" },
  { label: "6/36", value: "6/36" },
];
export const NEARVISIONSPECS = [
  { label: "N/5", value: "N/5" },
  { label: "N/6", value: "N/6" },
  { label: "N/8", value: "N/8" },
  { label: "N/10", value: "N/10" },
  { label: "N/12", value: "N/12" },
  { label: "N/18", value: "N/18" },
  { label: "N/24", value: "N/24" },
  { label: "N/36", value: "N/36" },
];

export const CONTACTSADD = [
  { label: "LOW", value: "low" },
  { label: "High", value: "high" },
];

export const COUPON_USES_TYPE = [
  { label: "None", value: "none" },
  { label: "Per user", value: "per_user" },
  { label: "Per website", value: "per_website" },
];
