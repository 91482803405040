import React from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { CONTACTSADD, generateRx } from '../../utils/dropdownOptions';
const RxRow = ({ options, name, formik }) => {
  return (
    <FormikSelectGroup
      formik={formik}
      options={options}
      name={name}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
};
const ContactsPrescriptionTable = ({ formik }) => {
  return (
    <>
      <h1>Contact Power</h1>
      <table className="border-collapse border border-slate-400 table-auto w-full divide-y divide-slate-200">
        {/* Table header */}
        <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
          <tr>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left"></div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RESPH</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RECYL</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RAXIS</div>
            </th>

            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">ADD</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold">||</div>
            </th>

            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LESPH</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LECYL</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LAXIS</div>
            </th>

            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold">Add</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="max-w-[20px] border text-center">D</td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -9, end: 9, step: 0.25 })}
                name="right.distance.sph"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -4, end: 4, step: 0.25 })}
                name="right.distance.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="right.distance.axis"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={CONTACTSADD} name="right.distance.add" />
            </td>
            <td></td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -9, end: 9, step: 0.25 })}
                name="left.distance.sph"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -4, end: 4, step: 0.25 })}
                name="left.distance.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="left.distance.axis"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={CONTACTSADD} name="left.distance.add" />
            </td>
          </tr>
          <tr>
            <td className="max-w-[70px] border text-center">N</td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -9, end: 9, step: 0.25 })}
                name="right.near.sph"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -4, end: 4, step: 0.25 })}
                name="right.near.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="right.near.axis"
              />
            </td>
            <td className="border"></td>
            <td></td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -9, end: 9, step: 0.25 })}
                name="left.near.sph"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -4, end: 4, step: 0.25 })}
                name="left.near.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="left.near.axis"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full gap-2 flex mt-2 border p-2">
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">K(R)</label>
          <FormikInputGroup formik={formik} name="right.k" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">dia(R)</label>

          <FormikInputGroup formik={formik} name="right.dia" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Bc(R)</label>

          <FormikInputGroup formik={formik} name="right.bc" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">K(L)</label>

          <FormikInputGroup formik={formik} name="left.k" />
        </div>

        <div className="grow flex gap-1 items-center">
          <label className="text-xs">dia(L)</label>

          <FormikInputGroup formik={formik} name="left.dia" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Bc(L)</label>

          <FormikInputGroup formik={formik} name="left.bc" />
        </div>
      </div>
    </>
  );
};

export default ContactsPrescriptionTable;
