import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "website";

export const fetchTypes = fetchData.generateThunk(SLICE_NAME, "/website/layout");
export const fetchFilters = fetchData.generateThunk(SLICE_NAME, "/website/generateFilters");

const websiteSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
              ...fetchData.generateExtraReducers(fetchTypes, SLICE_NAME),   
              ...fetchData.generateExtraReducers(fetchFilters, SLICE_NAME),   
  },
});

export const getWebsite = (state) => state.website;
export default websiteSlice.reducer;
