import React, { useEffect, useRef, useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { Download } from "react-feather";
import { CSVLink } from "react-csv";
import QueryString from "qs";
import { Link } from "react-router-dom";

const ViewGiftCard = () => {
  const [giftCardGroups, setGiftCardGroups] = useState([]);

  const getGiftCard = async () => {
    try {
      console.log("called");
      const getGiftCardRes = await authAxiosInstance.get("/coupon/gift-cards");
      console.log("response", getGiftCardRes);
      setGiftCardGroups(getGiftCardRes.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGiftCard();
  }, []);

  const [couponArrForCsv, setCouponArrForCsv] = useState([]);

  const csvRefInstance = useRef();
  useEffect(() => {
    if (
      couponArrForCsv.length &&
      csvRefInstance.current &&
      csvRefInstance.current.link
    ) {
      csvRefInstance.current.link.click();
      setCouponArrForCsv([]);
    }
  }, [couponArrForCsv]);

  const cols = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: "Group name",
      Cell: ({ row }) => {
        return (
          <div className="text-blue-600 cursor-pointer">
            <Link to={row.original?._id}>{row.original._id}</Link>
          </div>
        );
        // return <div className="text-blue-500" >{row.original._id}</div>;
      },
      accessor: "_id",
    },
    {
      Header: "No. of coupons",
      accessor: "totalCount",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <span>
            <CSVLink ref={csvRefInstance} data={couponArrForCsv} />
            <button
              onClick={async () => {
                const string = QueryString.stringify({
                  __t: "generalCoupon",
                  populate: true,
                  group_name: row.original._id,
                  limit: 500,
                });
                const fetchRes = await authAxiosInstance.get(
                  `/coupon/admin?${string}`
                );
                console.log("fetchRes", fetchRes);
                setCouponArrForCsv(fetchRes.data.data.docs);
              }}
            >
              <Download />
            </button>
          </span>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="Gift Cards">
      <TableWithHeadingAndSearch
        columns={cols}
        data={giftCardGroups}
        heading={"Group wise data"}
      />
    </PageWithCard>
  );
};

export default ViewGiftCard;
