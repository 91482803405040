import React from "react";
import FormikInputGroup from "../formik/FormikInputGroup";
import { FieldArray, FormikProvider } from "formik";
import FormikDirectFileUpload from "../formik/FormikDirectFileUpload";
import DangerButton from "../infrastructure/Buttons/DangerButton";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchStoreFront,
  fetchStoreFronts,
  getStoreFront,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";

const StorefrontBanner = ({ edit = false, index, formik }) => {
  const dispatch = useDispatch();
  const { storeFront } = useSelector(getStoreFront);
  useEffect(() => {
    dispatch(fetchStoreFronts());
  }, []);

  const storefrontArr = useMemo(() =>
    storeFront.docs ? storeFront.docs : [][storeFront.docs]
  );

  return (
    <>
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />

      <FormikDirectFileUpload
        name={`content.${index}.component.photos.desktop_photo`}
        formik={formik}
        label={`Desktop Photo`}
        location={`eyesdeal/website/storefrontBanner/images/`}
        randomize={true}
      />
      {/* <FormikInputGroup
        name={`content.${index}.component.photos.desktop_height`}
        label="Desktop photo height"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.photos.desktop_width`}
        label="Desktop photo width"
        formik={formik}
      /> */}
      <FormikDirectFileUpload
        name={`content.${index}.component.photos.mobile_photo`}
        formik={formik}
        label={`Mobile Photo`}
        location={`eyesdeal/website/storefrontBanner/images/`}
        randomize={true}
        height={`content.${index}.component.photos.mobile_height`}
        width={`content.${index}.component.photos.mobile_width`}
      />
      {/* <FormikInputGroup
        name={`content.${index}.component.photos.mobile_height`}
        label="Mobile photo height"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.photos.mobile_width`}
        label="Mobile photo width"
        formik={formik}
      /> */}
      <FormikSelectGroup
        name={`content.${index}.component.storefront_id`}
        label="select storefront"
        formik={formik}
        options={generateOptions({
          array: storefrontArr,
          labelField: "name",
          valueField: "_id",
        })}
        onChange={(option) => {
          formik.setFieldValue(
            `content.${index}.component.storefront_id`,
            option.value
          );
          formik.setFieldValue(
            `content.${index}.component.storefront_name`,
            option.label
          );
        }}
      />
      {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
    </>
  );
};

export default StorefrontBanner;
