import React from "react";
import FormikInputGroup from "../formik/FormikInputGroup";
import { FieldArray, FormikProvider } from "formik";
import FormikDirectFileUpload from "../formik/FormikDirectFileUpload";
import DangerButton from "../infrastructure/Buttons/DangerButton";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchStoreFront,
  fetchStoreFronts,
  getStoreFront,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";

const StorefrontGrid = ({ edit = false, index, formik }) => {
  const dispatch = useDispatch();
  const { storeFront } = useSelector(getStoreFront);
  useEffect(() => {
    dispatch(fetchStoreFronts());
  }, []);

  const storefrontArr = useMemo(() =>
    storeFront.docs ? storeFront.docs : [][storeFront.docs]
  );

  return (
    <>
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.desktop_column_gap`}
        label="Desktop column gap"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.mobile_column_gap`}
        label="Mobile column gap"
        formik={formik}
      />
      {/* <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      /> */}

      <FormikProvider value={formik}>
        <FieldArray
          name={`content.${index}.component.photos`}
          render={(arrayHelpers) => {
            return (
              <>
                {formik?.values?.content?.[index]?.component?.photos?.map(
                  (photo, i) => (
                    <div className="grid grid-cols-3 gap-3" key={i}>
                      <FormikDirectFileUpload
                        name={`content.${index}.component.photos.${i}.desktop_photo`}
                        formik={formik}
                        label={`Desktop Photo ${i + 1}`}
                        location={`eyesdeal/website/StorefrontGrid/images/`}
                        randomize={true}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.photos.${i}.desktop_height`}
                        label={`Desktop Photo height ${i + 1}`}
                        formik={formik}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.photos.${i}.desktop_width`}
                        label={`Desktop photo width ${i + 1}`}
                        formik={formik}
                      />
                      <FormikDirectFileUpload
                        name={`content.${index}.component.photos.${i}.mobile_photo`}
                        formik={formik}
                        label={`Mobile Photo .${i + 1}`}
                        location={`eyesdeal/website/StorefrontGrid/images/`}
                        randomize={true}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.photos.${i}.mobile_height`}
                        label={`Mobile photo height .${i + 1}`}
                        formik={formik}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.photos.${i}.mobile_width`}
                        label={`Mobile photo width .${i + 1}`}
                        formik={formik}
                      />
                      <FormikSelectGroup
                        name={`content.${index}.component.photos.${i}.storefront_id`}
                        label={`select storefront ${i + 1}`}
                        formik={formik}
                        options={generateOptions({
                          array: storefrontArr,
                          labelField: "name",
                          valueField: "_id",
                        })}
                        onChange={(option) => {
                          formik.setFieldValue(
                            `content.${index}.component.photos.${i}.storefront_id`,
                            option.value
                          );
                          formik.setFieldValue(
                            `content.${index}.component.photos.${i}.storefront_name`,
                            option.label
                          );
                        }}
                      />

                      <div className="flex items-center">
                        <DangerButton
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  )
                )}
                <div className="my-2 flex gap-4">
                  <SecondaryButton onClick={() => arrayHelpers.push("")}>
                    Add more slide
                  </SecondaryButton>
                  {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
                </div>
              </>
            );
          }}
        />
      </FormikProvider>
    </>
  );
};

export default StorefrontGrid;
