import React from "react";
import {
  Briefcase,
  Edit,
  FileMinus,
  Home,
  Mail,
  Monitor,
  TrendingUp,
  User,
  UserPlus,
} from "react-feather";
import SidebarGroup from "../../components/infrastructure/SidebarGroup";
import SidebarSingle from "../../components/infrastructure/SidebarSingle";
import SidebarTopText from "../../components/infrastructure/SidebarTopText";

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
      <SidebarSingle
        allowedRoles={[
          "admin",
          "ecommerce_manager",
          "Purchase Admin",
          "Sales Admin",
          "Purchase Executive",
          "Sales Executive",
        ]}
        activePath="/"
        name="Dashboard"
        Icon={<Monitor />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarGroup
        links={[
          {
            label: "Add Emails",
            link: "/emails/addEmails",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Emails",
            link: "/emails/viewEmails",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
        ]}
        heading="Emails"
        Icon={<Mail />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "Create Page",
            link: "/page/createPage",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "Edit Page",
            link: "/page/editPage",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Page",
            link: "/page/viewPage",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          // {
          //   label: "View Emails",
          //   link: "/emails/viewEmails",
          //   allowedRoles: ["admin"],
          // },
        ]}
        heading="Pages"
        Icon={<FileMinus />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "Create Store Front",
            link: "/storeFront/createStoreFront",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Store Front",
            link: "/storeFront/viewStoreFront",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
        ]}
        heading="Store Front"
        Icon={<FileMinus />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "Lens Category",
            link: "/lens/category",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "Lens Package",
            link: "/lens/package",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Lens Package",
            link: "/lens/view/package",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
        ]}
        heading="Lens"
        Icon={<FileMinus />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "Create Coupon",
            link: "/coupon/createCoupon",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Coupon",
            link: "/coupon/viewCoupon",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "Create General Coupon",
            link: "/coupon/createGeneralCoupon",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View General Coupon",
            link: "/coupon/viewGeneralCoupon",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "Gift Card",
            link: "/coupon/GiftCard",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          {
            label: "View Gift Card",
            link: "/coupon/viewGiftCard",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          // {
          //   label: "View Emails",
          //   link: "/emails/viewEmails",
          //   allowedRoles: ["admin"],
          // },
        ]}
        heading="Coupons"
        Icon={<FileMinus />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "View Products",
            link: "/products/ViewProducts",
            allowedRoles: ["admin", "ecommerce_manager"],
          },
          // {
          //   label: "View Emails",
          //   link: "/emails/viewEmails",
          //   allowedRoles: ["admin"],
          // },
        ]}
        heading="Products"
        Icon={<Briefcase />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />
      <SidebarSingle
        allowedRoles={[
          "admin",
          "ecommerce_manager",
          "Purchase Admin",
          "Sales Admin",
          "Purchase Executive",
          "Sales Executive",
        ]}
        activePath="/mediaLibrary"
        name="Media Library"
        Icon={<Briefcase />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarSingle
        allowedRoles={[
          "admin",
          "ecommerce_manager",
          "Purchase Admin",
          "Sales Admin",
          "Purchase Executive",
          "Sales Executive",
        ]}
        activePath="/generateLink"
        name="Generate Link"
        Icon={<Briefcase />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarSingle
        allowedRoles={["admin", "ecommerce_manager"]}
        activePath="/cartAnalysis"
        name="Cart Analysis"
        Icon={<TrendingUp />}
        sidebarOpen={sidebarOpen}
      />
    </ul>
  );
};

export default SidebarLinks;
