import React from "react";
import FormikInputGroup from "../formik/FormikInputGroup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikDirectFileUpload from "../formik/FormikDirectFileUpload";
import DangerButton from "../infrastructure/Buttons/DangerButton";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikEditorInput from "../formik/FormikEditorInput";

const TwoColumnContainer = ({ edit = false, index, formik }) => {
  // console.log("inTwoColumnContainer")
  return (
    <>
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />
      <FormikProvider value={formik}>
        <FieldArray
          name={`content.${index}.component.column`}
          render={(arrayHelpers) => {
            return (
              <>
                {formik?.values?.content?.[index]?.component?.column?.map(
                  (column, i) => (
                    <div className="grid grid-cols-2 gap-3" key={i}>
                      <FormikDirectFileUpload
                        name={`content.${index}.component.column.${i}.photo`}
                        formik={formik}
                        label={`Photo ${i + 1}`}
                        location={`eyesdeal/website/TwoColumn/images/`}
                        randomize={true}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.column.${i}.title`}
                        formik={formik}
                        label={`title ${i + 1}`}
                      />
                      <div className="col-span-2">
                        <FormikEditorInput
                          name={`content.${index}.component.column.${i}.description`}
                          formik={formik}
                          label={`description ${i + 1}`}
                        />
                      </div>
                      <div className="flex items-center">
                        <DangerButton
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  )
                )}
                <div className="my-2 flex gap-4">
                  <SecondaryButton onClick={() => arrayHelpers.push("")}>
                    Add More column
                  </SecondaryButton>
                  {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
                </div>
              </>
            );
          }}
        />
      </FormikProvider>
    </>
  );
};

export default TwoColumnContainer;
