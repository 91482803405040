import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  createLensCategory,
  deleteLensCategory,
  fetchLensCategories,
  getLensCategory,
} from "../../app/reducers/LensCategory/lensCategorySlice";
import { ClipLoader } from "react-spinners";
import {
  fetchAssets,
  getMediaLibrary,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

export const LensCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { lensCategory, loading } = useSelector(getLensCategory);
  const { baseUrl } = useSelector(getMediaLibrary);

  useEffect(() => {
    dispatch(fetchLensCategories({ sort: { websiteOrder: 1 } }));
    dispatch(fetchAssets({ currentFolder: "" }));
  }, []);

  const lensCategoryData = useMemo(
    () => (lensCategory?.docs ? lensCategory.docs : []),
    [lensCategory]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      heading: "",
      sub_heading: "",
      image: "",
      websiteOrder: "",
    },
    validationSchema: yup.object({
      heading: yup.string().required(),
      sub_heading: yup.string().required(),
      websiteOrder: yup.string().required(),
      image: yup.string().optional(),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log("submit");
      console.log(formik.errors);
      await dispatch(createLensCategory(values));
      await dispatch(fetchLensCategories({ sort: { websiteOrder: 1 } }));
      resetForm();
    },
  });

  const cols = [
    {
      Header: "SRNO",
      Cell: ({ row, data }) => {
        return row.index + 1;
      },
    },
    { Header: "Website Order", accessor: "websiteOrder" },
    {
      Header: "Heading",
      Cell: ({ row }) => {
        return (
          <div
            className="underline text-cyan-600 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/lens/view/package?category=${row.original?.heading}`);
            }}
          >
            {row.original?.heading}
          </div>
        );
      },
    },
    {
      Header: "Image",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.image && (
              <img
                src={baseUrl + row.original.image}
                alt="Logo"
                height={100}
                width={100}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Sub Heading",
      accessor: "sub_heading",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-2 cursor-pointer">
            <Edit2
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/lens/edit/category/${row.original?._id}`);
              }}
            />
            <Trash
              color="red"
              onClick={async (e) => {
                e.stopPropagation();
                setIsLoading(true);
                await dispatch(deleteLensCategory({ id: row.original?._id }));
                await dispatch(
                  fetchLensCategories({ sort: { websiteOrder: 1 } })
                );
                setIsLoading(false);
              }}
            />
          </div>
        );
      },
    },
  ];

  const columnsMemo = useMemo(() => cols, [cols]);

  return (
    <PageWithCard heading="Create Lens Category">
      {loading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 mb-3"
        >
          <FormikInputGroup
            formik={formik}
            name="heading"
            label="Heading"
            required
          />
          <FormikInputGroup
            formik={formik}
            name="sub_heading"
            label="Sub Heading"
            required
          />
          <FormikInputGroup
            formik={formik}
            name="websiteOrder"
            label="Website Order"
            required
          />
          <FormikDirectFileUpload
            name={`image`}
            formik={formik}
            label={`Image`}
            location={`eyesdeal/website/lens/category/`}
            randomize={true}
          />
          <div>
            <PrimaryButton type="submit">submit</PrimaryButton>
          </div>
        </form>
      )}
      {isLoading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch
          data={lensCategoryData}
          columns={columnsMemo}
          heading="View Lens Category"
        />
      )}
    </PageWithCard>
  );
};
