import React from "react";
import { ProductPhotoSlider } from "./ProductPhotoSlider";
import MediaLibraryAssets from "../MediaLibrary/MediaLibraryAssets";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";

export const ProductPhotoModel = ({
  productPhotoData,
  productPhotoModal,
  setProductPhotoModal,
}) => {
  return (
    <>
      <MediaLibraryAssets />
      <ModalBasic
        title={`Product Photo `}
        modalOpen={productPhotoModal}
        setModalOpen={setProductPhotoModal}
      >
        <ProductPhotoSlider data={productPhotoData} />
      </ModalBasic>
    </>
  );
};
