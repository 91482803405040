import React, { useMemo } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePage,
  fetchPages,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

const ViewPage = () => {
  const { pages, loading } = useSelector(getPages);
  const { baseUrl } = useSelector(getMediaLibrary);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPages());
  }, []);

  const data = useMemo(() => (pages?.docs ? pages.docs : []), [pages]);

  const cols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Seo",
      Cell: ({ row }) => {
        const {
          seoTitle: title,
          seoDescription: description,
          seoImage: image,
        } = row?.original;
        return (
          <div className="w-[400px]">
            <ShowDropdown allowedRoles={["admin"]} heading="Seo Data">
              {title || description || image ? (
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <div style={{ position: "relative" }}>
                      <img
                        src={`${baseUrl}${image}`}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 justify-left items-center">
                    <p className="text text-base font-semibold">Title:</p>
                    <p>{title}</p>
                  </div>
                  <div className="flex flex-row gap-1 justify-left items-center">
                    <p className="text text-base font-semibold">Description:</p>
                    <p className="whitespace-pre-wrap">{description}</p>
                  </div>
                </div>
              ) : (
                <div>No Seo Data Found</div>
              )}
            </ShowDropdown>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={() => {
                navigate(`/page/editPage/${row.original._id}`);
              }}
            >
              Edit
            </PrimaryButton>
            <Trash
              onClick={async () => {
                await dispatch(deletePage({ id: row.original._id }));
                dispatch(fetchPages());
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="View page">
      <TableWithHeadingAndGlobalSearch
        loading={loading}
        data={data}
        // searchFunction={debouncedSearch}
        columns={cols}
      />
    </PageWithCard>
  );
};

export default ViewPage;
