import React from 'react';
import _ from 'lodash';

const ContactPrescriptionTableView = ({ contactsData }) => {
  let left = contactsData && contactsData.left;
  let right = contactsData && contactsData.right;
  if (contactsData) {
    return (
      <>
        <h1>Contacts Power</h1>
        <table className="border-collapse border border-slate-400 table-auto w-full divide-y divide-slate-200">
          {/* Table header */}
          <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
            <tr>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left"></div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RESPH</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RECYL</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RAXIS</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RVISION</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">ADD</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold">||</div>
              </th>

              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LESPH</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LECYL</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LAXIS</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LEVision</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold">Add</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="max-w-[20px] border text-center">D</td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.distance.sph"
                  className="w-full form-input"
                  value={right && right.distance && right.distance.sph}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.distance.cyl"
                  className="w-full form-input"
                  value={right && right.distance && right.distance.cyl}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.distance.axis"
                  className="w-full form-input"
                  value={right && right.distance && right.distance.axis}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.distance.vs"
                  className="w-full form-input"
                  value={right && right.distance && right.distance.vs}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.distance.add"
                  className="w-full form-input"
                  value={right && right.distance && right.distance.add}
                />
              </td>
              <td></td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.distance.sph"
                  className="w-full form-input"
                  value={left && left.distance && left.distance.sph}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.distance.cyl"
                  className="w-full form-input"
                  value={left && left.distance && left.distance.cyl}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.distance.axis"
                  className="w-full form-input"
                  value={left && left.distance && left.distance.axis}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.distance.vs"
                  className="w-full form-input"
                  value={left & left.distance && left.distance.vs}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.distance.add"
                  className="w-full form-input"
                  value={left && left.distance && left.distance.add}
                />
              </td>
            </tr>
            <tr>
              <td className="max-w-[20px] border text-center">N</td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.near.sph"
                  className="w-full form-input"
                  value={right && right.near && right.near.sph}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.near.cyl"
                  className="w-full form-input"
                  value={right && right.near && right.near.cyl}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.near.axis"
                  className="w-full form-input"
                  value={right && right.near && right.near.axis}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="right.near.vs"
                  className="w-full form-input"
                  value={right && right.near && right.near.vs}
                />
              </td>
              <td className="max-w-[70px] border p-2"></td>
              <td></td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.near.sph"
                  className="w-full form-input"
                  value={left && left.near && left.near.sph}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.near.cyl"
                  className="w-full form-input"
                  value={left && left.near && left.near.cyl}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.near.axis"
                  className="w-full form-input"
                  value={left && left.near && left.near.axis}
                />
              </td>
              <td className="max-w-[70px] border p-2">
                <input
                  type={'text'}
                  name="left.near.vs"
                  className="w-full form-input"
                  value={left && left.near && left.near.vs}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full gap-2 flex mt-2 border p-2">
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">K(R)</label>
            <input
              type={'text'}
              name="right.k"
              className="w-full form-input"
              value={right && right.k}
            />
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">dia(R)</label>
            <input
              type={'text'}
              name="right.dia"
              className="w-full form-input"
              value={right && right.dia}
            />
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Bc(R)</label>
            <input
              type={'text'}
              name="right.bc"
              className="w-full form-input"
              value={right && right.bc}
            />
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">K(L)</label>
            <input
              type={'text'}
              name="left.k"
              className="w-full form-input"
              value={left && left.k}
            />
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">dia(L)</label>
            <input
              type={'text'}
              name="left.dia"
              className="w-full form-input"
              value={left && left.dia}
            />
          </div>

          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Bc(L)</label>
            <input
              type={'text'}
              name="left.bc"
              className="w-full form-input"
              value={left && left.bc}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default ContactPrescriptionTableView;
