import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { editPage, fetchPage } from "../../app/reducers/Page/pageSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";

const EditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPage());
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values) => {
      dispatch(editPage(values));
    },
  });
  return (
    <PageWithCard heading="Edit Pages">
      <form action="" className="flex flex-col gap-4">
        <FormikAsyncSelect
          name="name"
          label="Select Page Name"
          formik={formik}
          getOptions={async (search) => {
            const data = {
              search,
            };
            const string = QueryString.stringify(data);
            const pageResp = await authAxiosInstance.get(`pages?${string}`);
            return pageResp.data.data.docs.map((page) => ({
              label: page.name,
              value: page._id,
            }));
          }}
          onChange={(option) => {
            navigate(`/page/editPage/${option.value}`);
          }}
        />
      </form>
    </PageWithCard>
  );
};

export default EditPage;
