import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProduct,
  fetchProducts,
  getProducts,
} from "../../app/reducers/Products/productSlice";
import { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import {
  fetchBrand,
  fetchBrands,
  getBrands,
} from "../../app/reducers/Brand/brandSlice";
import { useMemo } from "react";
import {
  deleteAttribute,
  fetchAttributes,
  getAttributes,
  setEditId,
} from "../../app/reducers/Attributes/attributesSlice";
import { Edit2, Eye, Trash } from "react-feather";
import ViewProductsModal from "./ViewProductModel";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { ProductPhotoModel } from "./ProductPhotoModel";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import QuickEditProductModal from "./QuickEditProductModal";
import { ClipLoader } from "react-spinners";

const ViewProducts = () => {
  const dispatch = useDispatch();
  const { products, loading } = useSelector(getProducts);
  const { brands } = useSelector(getBrands);
  const { attributes, attributesLoading } = useSelector(getAttributes);
  const [page, setPage] = useState(1);
  const { baseUrl } = useSelector(getMediaLibrary);
  const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productPhotoModal, setProductPhotoModal] = useState(false);
  const [productPhotoData, setProductPhotoData] = useState(null);

  // const [model, setModel] = useState(products?.docs[0]?.__t);
  const idFields = [
    "unit",
    "frameType",
    "frameShape",
    "frameStyle",
    "material",
    "color",
    "prescriptionType",
    "collection",
    "feature",
    "brand",
  ];
  const generateValueFromAttributes = (attributes) => {
    return attributes.map((attribute) => ({
      label: attribute.name,
      value: attribute._id,
    }));
  };

  useEffect(() => {
    dispatch(fetchProducts({ activeInWebsite: true, page }));
    idFields.forEach((field) => {
      dispatch(fetchAttributes({ model: field }));
    });
  }, [page]);

  const data = useMemo(() => (products.docs ? products.docs : []), [products]);

  const formik = useFormik({
    initialValues: {
      __t: "",
      brand: "",
      frameType: "",
      frameShape: "",
      gender: "",
      frameMaterial: "",
      frameColor: "",
      frameSize: "",
      prescriptionType: "",
      frameCollection: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      // const searchValues = {
      //     __t: values.__t,
      //     brand: values.brand,
      //     frameType: values.frameType,
      //     gender: values.gender,
      //     frameMaterial: values.frameMaterial,
      //     frameColor: values.frameColor,
      //     frameSize: values.frameSize,
      //     prescriptionType: values.prescriptionType,
      //     frameCollection: values.frameCollection
      // }
      // dispatch(fetchProducts({ ...searchValues, activeInWebsite: true, }))
      const { __t, brand, ...rest } = values;

      const data = Object.keys(rest).reduce((acc, key) => {
        if (rest[key]) {
          acc[key] = rest[key];
        }
        return acc;
      }, {});
      if (brand) {
        data["brand._id"] = brand;
      }
      dispatch(fetchProducts({ __t: __t, ...data, activeInWebsite: true }));
    },
  });

  // const columnsMemo = useMemo(() => {
  //     if (attributes[model][0]) {
  //         const keys = Object.keys(attributes[model][0]).filter((key) => !["_id", "createdat, updatedat"].includes(key.toLowerCase()))
  //         console.log("keys", keys)
  //         const cols = [];
  //         cols.push({
  //             Header: "SRNO",
  //             Cell: (prop) => {
  //                 const { data, row } = prop;
  //                 return row.index + 1;
  //             },
  //         });
  //         keys.forEach((key) => {
  //             cols.push({
  //                 Header: key,
  //                 accessor: key,
  //             });
  //         });
  //         cols.push({
  //             Header: "Action",
  //             Cell: (prop) => {
  //                 const { data, row } = prop;
  //                 return (
  //                     <div
  //                         className="flex gap-2"
  //                         onClick={(e) => {
  //                             e.stopPropagation();
  //                         }}
  //                     >
  //                         <Edit2
  //                             size={18}
  //                             color="blue"
  //                             onClick={() => {
  //                                 dispatch(setEditId({ model, id: row.original._id }));
  //                             }}
  //                         />
  //                         <Trash
  //                             size={18}
  //                             color="red"
  //                             onClick={() => {
  //                                 dispatch(
  //                                     deleteAttribute({
  //                                         id: row.original._id,
  //                                         model: model,
  //                                     })
  //                                 );
  //                             }}
  //                         />
  //                     </div>
  //                 );
  //             },
  //         })
  //         console.log(cols)
  //         return cols
  //     }
  //     else {
  //         return []
  //     }
  // }, [attributes])

  // const data = useMemo(() => attributes[model], [attributes]);
  const [quickEditOpen, setQuickEditOpen] = useState(false);
  const [id, setID] = useState("");

  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      dispatch(fetchProducts({ activeInWebsite: true, search: search }));
    }, 300),
    []
  );

  const handleViewMorePhoto = (e, photoData) => {
    e.stopPropagation();
    setProductPhotoData(photoData);
    setProductPhotoModal(true);
  };

  const cols = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + products.pagingCounter;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;

        return (
          <>
            {row.original?.photos?.length > 0 ? (
              <div style={{ position: "relative", width: "152px" }}>
                <img
                  src={`${baseUrl}${row.original?.photos?.[0]}`}
                  style={{ width: "150px", height: "150px" }}
                />
                {row.original?.photos.length > 0 && (
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleViewMorePhoto(e, row?.original)}
                  >
                    View More
                  </div>
                )}
              </div>
            ) : (
              <div>No Image</div>
            )}
          </>
        );
      },
    },
    {
      Header: "Bar Code",
      Cell: ({ row }) => {
        const barcode = row?.original?.oldBarcode
          ? row?.original?.oldBarcode
          : row?.original?.newBarcode;
        return barcode;
      },
    },
    {
      Header: "SKU",
      accessor: "sku",
    },
    {
      Header: "Display Name",
      Cell: ({ row }) => {
        return (
          <div className="w-[10rem] overflow-hidden whitespace-normal">
            {row?.original.displayName}
          </div>
        );
      },
    },
    {
      Header: "Total Quantity",
      accessor: "inventory.totalQuantity",
    },
    {
      Header: "Sell Price",
      accessor: "sellPrice",
    },
    {
      Header: "MRP",
      accessor: "MRP",
    },
    {
      Header: "Action",
      Cell: (props) => {
        const { data, row } = props;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              console.log("e", e);
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={(e) => {
                e.stopPropagation();
                setIsViewProductModalOpen(true);
                setSelectedProduct(row?.original);
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Quick Edit",
      Cell: ({ row }) => {
        return (
          <div>
            <PrimaryButton
              onClick={async (e) => {
                e.stopPropagation();
                const data = await dispatch(
                  fetchProduct({ _id: row?.original?._id })
                );
                console.log(data);
                setQuickEditOpen(true);
              }}
            >
              Edit
            </PrimaryButton>
          </div>
        );
      },
    },
  ];
  return (
    <PageWithCard>
      <ProductPhotoModel
        productPhotoData={productPhotoData}
        productPhotoModal={productPhotoModal}
        setProductPhotoModal={setProductPhotoModal}
      />
      <ViewProductsModal
        selectedProduct={selectedProduct}
        isViewProductModalOpen={isViewProductModalOpen}
        setIsViewProductModalOpen={setIsViewProductModalOpen}
      />
      <QuickEditProductModal
        quickEditOpen={quickEditOpen}
        setQuickEditOpen={setQuickEditOpen}
      />
      <form className="grid grid-cols-3 gap-6" onSubmit={formik.handleSubmit}>
        <FormikSelectGroup
          formik={formik}
          name="__t"
          label="Product"
          options={generateOptions({
            array: [
              { label: "Eye Glasses", value: "eyeGlasses" },
              { label: "Accessories", value: "accessories" },
              { label: "Sun Glasses", value: "sunGlasses" },
              { label: "Spectacle Lens", value: "spectacleLens" },
              { label: "Contact Lens", value: "contactLens" },
              { label: "Reading Glasses", value: "readingGlasses" },
              { label: "Contact Solutions", value: "contactSolutions" },
            ],
            valueField: "value",
            labelField: "label",
          })}
        />
        {/* <FormikSelectGroup
                    formik={formik}
                    name="brand"
                    label="Brand"
                    options={generateOptions({
                        array: brandData,
                        valueField: "_id",
                        labelField: "name",
                    })} s
                /> */}
        <FormikSelectGroup
          formik={formik}
          name="brand"
          label="Brand"
          options={generateValueFromAttributes(attributes["brand"])}
        />
        <FormikSelectGroup
          formik={formik}
          name="frameType"
          label="Frame Type"
          options={generateValueFromAttributes(attributes["frameType"])}
        />

        <FormikSelectGroup
          formik={formik}
          name="gender"
          label="Gender"
          options={generateOptions({
            array: [
              { label: "male", value: "male" },
              { label: "female", value: "female" },
              { label: "unisex", value: "unisex" },
              { label: "kids", value: "kids" },
            ],
            valueField: "value",
            labelField: "label",
          })}
        />
        <FormikSelectGroup
          formik={formik}
          name="frameMaterial"
          label="Frame Material"
          options={generateValueFromAttributes(attributes["material"])}
        />
        <FormikSelectGroup
          formik={formik}
          name="frameColor"
          label="Frame Color"
          options={generateValueFromAttributes(attributes["color"])}
        />
        <FormikSelectGroup
          formik={formik}
          name="frameSize"
          label="Frame Size"
          options={generateOptions({
            array: [
              { label: "Small", value: "small" },
              { label: "Medium", value: "medium" },
              { label: "Large", value: "large" },
              { label: "Extra Large", value: "extra_large" },
            ],
            valueField: "value",
            labelField: "label",
          })}
        />
        <FormikSelectGroup
          formik={formik}
          name="prescriptionType"
          label="Prescription Type"
          options={generateValueFromAttributes(attributes["prescriptionType"])}
        />
        <FormikSelectGroup
          formik={formik}
          name="frameCollection"
          label="Frame Collection"
          options={generateValueFromAttributes(attributes["collection"])}
        />
        <div>
          <PrimaryButton type="submit"> submit</PrimaryButton>
        </div>
      </form>
      <div className="flex flex-col md:flex-row md:-mr-px">
        {/* <SecondarySideBar
                    model={model}
                    setModel={setModel}
                    list={Object.keys(attributes)}
                /> */}
        <div className="w-full p-6 overflow-auto">
          <TableWithHeadingAndGlobalSearch
            data={data}
            columns={cols}
            loading={loading}
            searchFunction={(value) => {
              debouncedSearch(value);
            }}
          />

          <PaginationClassic paginationDetails={products} setPage={setPage} />
        </div>
      </div>
    </PageWithCard>
  );
};

export default ViewProducts;
