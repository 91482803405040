import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "websiteOrder";

export const createWebsiteOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchWebsiteOrders = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const updateWebsiteOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteWebsiteOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchWebsiteOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
const websiteOrdersSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createWebsiteOrder, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchWebsiteOrders, SLICE_NAME),
    ...editData.generateExtraReducers(updateWebsiteOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteWebsiteOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchWebsiteOrder, SLICE_NAME),
  },
});

export const getWebsiteOrders = (state) => state.websiteOrder;
export default websiteOrdersSlice.reducer;
