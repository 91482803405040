import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editPage,
  fetchPage,
  fetchPages,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import { useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import {
  fetchLensPackage,
  getLensPackage,
  updateLensPackage,
} from "../../app/reducers/LensPackage/lensPackageSlice";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { X } from "react-feather";
import { generateOptions } from "../../utils/Utils";
import {
  fetchLensCategories,
  getLensCategory,
} from "../../app/reducers/LensCategory/lensCategorySlice";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { GENDER, PRODUCT_TYPE } from "../../utils/dropdownOptions";
import { getFrameType } from "../../app/reducers/FrameType/frameTypeSlice";

const EditPackage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lensCategory } = useSelector(getLensCategory);
  useEffect(() => {
    dispatch(fetchLensCategories());
  }, []);

  useEffect(() => {
    dispatch(fetchLensPackage({ _id: id }));
  }, [id]);

  const { editDataLoading, elementEditData } = useSelector(getLensPackage);
  const { frameType, loading: frameTypeLoading } = useSelector(getFrameType);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: elementEditData,
    onSubmit: async (values, { resetForm }) => {
      const data = await dispatch(updateLensPackage(values));
      dispatch(fetchLensPackage({ _id: id, populate: true }));
    },
  });
  const lensCategoryData = useMemo(
    () => (lensCategory?.docs ? lensCategory?.docs : []),
    [lensCategory]
  );
  return (
    <PageWithCard heading="Edit Lens Package">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <div>
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikInputGroup
              formik={formik}
              name="heading"
              label="Heading"
              required
            />
            <FormikInputGroup
              formik={formik}
              name="sub_heading"
              label="Sub Heading"
              required
            />
            <FormikDirectFileUpload
              name={`image`}
              formik={formik}
              label={`Image`}
              location={`eyesdeal/website/lens/package/`}
              randomize={true}
            />
            <FormikAsyncSelect
              name={`product`}
              formik={formik}
              label="Product"
              onChange={(selectedOption) => {
                console.log(selectedOption, "check")
                formik.setFieldValue('product', selectedOption.value)
                formik.setFieldValue('tax', selectedOption.tax)
                formik.setFieldValue('sku', selectedOption.label.split('/')[1])
              }}
              getOptions={async (value) => {
                console.log("getOptions", value);
                const query = {
                  search: value,
                  __t: "spectacleLens",
                };
                const string = QueryString.stringify(query);
                const products = await authAxiosInstance.get(
                  `products/product?${string}`
                );

                const options = products.data.data.docs?.map((ele) => {
                  const barcode = ele?.oldBarcode ?? ele?.newBarcode;
                  return {
                    label: `${barcode}/${ele.sku}/${ele.MRP}`,
                    value: `${ele._id}`,
                    tax: ele.tax
                  };
                });
                return options;
              }}
            />
            {
              formik?.values?.product && (
                <FormikInputGroup
                  formik={formik}
                  readOnly
                  required
                  name={'sku'}
                  label="Sku"
                />
              )
            }
            {
              formik?.values?.product && (
                <FormikInputGroup
                  formik={formik}
                  readOnly
                  required
                  name={'tax'}
                  label="Tax (%)"
                />
              )
            }
            {formik.values?.product && (
              <FormikInputGroup
                formik={formik}
                name="price"
                type="number"
                label="Price"
                required
              />
            )}
            <FormikSelectGroup
              formik={formik}
              name="category"
              label="Category"
              options={generateOptions({
                array: lensCategoryData,
                valueField: "_id",
                labelField: "heading",
              })}
            />
            <FormikMultiSelect
              formik={formik}
              name="gender"
              label="Gender"
              options={GENDER}
            />
            <FormikMultiSelect
              formik={formik}
              name="productType"
              label="Product type"
              options={PRODUCT_TYPE}
            />
            <FormikMultiSelect
              formik={formik}
              name="frameType"
              label="Frame type"
              options={generateOptions({
                array: frameType ? frameType : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            <FormikInputGroup
              formik={formik}
              label="Category Order"
              name={'categoryOrder'}
              type="number"
            />
            <FormikProvider value={formik}>
              <h1 className="font-semibold">Features</h1>
              <FieldArray
                name="features"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col gap-4 border rounded-lg p-5">
                      {formik?.values?.features?.map((ele, i) => (
                        <div key={i} className="flex w-full gap-3">
                          <div>
                            <X
                              onClick={() => {
                                arrayHelpers.remove(i);
                              }}
                            />
                          </div>
                          <div className="w-[80%]">
                            <FormikInputGroup
                              fullWidth={true}
                              name={`features.${i}`}
                              formik={formik}
                            />
                          </div>
                        </div>
                      ))}
                      <div className="flex gap-4">
                        <PrimaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("");
                          }}
                        >
                          Add More
                        </PrimaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
            <div>
              <PrimaryButton type="submit">submit</PrimaryButton>
            </div>
          </form>
        </div>
      )}
    </PageWithCard>
  );
};

export default EditPackage;
