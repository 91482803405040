import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import Tab from '../../components/infrastructure/Tabs/Tab';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import ContactPrescriptionTableView from '../Users/ContactPrescriptionTableView';
import SpecPrescriptionTableView from '../Users/SpecPrescriptionTableView';
import moment from 'moment';

const ViewPrescriptions = ({
  viewPrescriptionsModalOpen,
  setViewPrescriptionsModalOpen,
  prescriptions,
  startPage,
}) => {
  const specsPrescriptionsOriginal = _.isArray(prescriptions)
    ? prescriptions.filter(ele => ele.__t === 'specs')
    : [];
  const contactPrescriptionsOriginal = _.isArray(prescriptions)
    ? prescriptions?.filter(ele => ele.__t === 'contacts')
    : [];

  const specsPrescriptions = specsPrescriptionsOriginal.reverse();
  const contactPrescriptions = contactPrescriptionsOriginal.reverse();

  const [prescriptionData, setPrescriptionData] = useState();
  const [contactPrescriptionData, setContactPrescriptionData] = useState();
  const [page, setPage] = useState(1);
  const [contactPage, setContactPage] = useState(1);

  //Pagination Specs
  let totalPages = specsPrescriptions && specsPrescriptions.length;
  const paginationSpecs = {
    pagingCounter: 1,
    totalDocs: specsPrescriptions && specsPrescriptions.length,
    docs: specsPrescriptions && specsPrescriptions,
    prevPage: page == 1 ? null : page - 1,
    nextPage: page == totalPages ? null : page + 1,
    page: page,
    hasNextPage: page == totalPages ? false : true,
    hasPrevPage: page == 1 ? false : true,
  };

  //Pagination Contact
  let totalPageContacts = contactPrescriptions && contactPrescriptions.length;
  const paginationContacts = {
    pagingCounter: 1,
    totalDocs: contactPrescriptions && contactPrescriptions.length,
    docs: contactPrescriptions && contactPrescriptions,
    prevPage: contactPage == 1 ? null : contactPage - 1,
    nextPage: contactPage == totalPageContacts ? null : contactPage + 1,
    page: contactPage,
    hasNextPage: contactPage == totalPageContacts ? false : true,
    hasPrevPage: contactPage == 1 ? false : true,
  };

  useEffect(() => {
    if (specsPrescriptions && specsPrescriptions.length > 0 && viewPrescriptionsModalOpen) {
      setPrescriptionData(specsPrescriptions[page - 1]);
    } else if (specsPrescriptions && specsPrescriptions.length > 0 && !viewPrescriptionsModalOpen) {
      setPage(1);
    }
  }, [page, viewPrescriptionsModalOpen]);

  useEffect(() => {
    if (contactPrescriptions && contactPrescriptions.length > 0 && viewPrescriptionsModalOpen) {
      setContactPrescriptionData(contactPrescriptions[contactPage - 1], () => {});
    } else if (specsPrescriptions && specsPrescriptions.length > 0 && !viewPrescriptionsModalOpen) {
      setContactPage(1);
    }
  }, [contactPage, viewPrescriptionsModalOpen]);

  return (
    <ModalBasic
      title="View Prescriptions"
      modalOpen={viewPrescriptionsModalOpen}
      setModalOpen={setViewPrescriptionsModalOpen}
      max_width="max-w-5xl"
    >
      <div className="p-4" style={{ overflow: 'hidden' }}>
        <Tabs
          headers={['Specs', 'Contact']}
          onTabChange={index => {
            console.log(index);
          }}
        >
          <Tab>
            {specsPrescriptions && specsPrescriptions.length > 0 ? (
              <div className="p-4">
                <form>
                  <div className="flex gap-4 ">
                    <div className="flex-grow">
                      Date:
                      <input
                        type={'text'}
                        name="createdAt"
                        className="w-full form-input"
                        value={
                          prescriptionData?.createdAt
                            ? moment(prescriptionData.createdAt).format('DD/MM/YYYY')
                            : 'No Date Found'
                        }
                      />
                    </div>
                    <div className="flex-grow">
                      {prescriptionData?.prescribedBy == 'employee'
                        ? 'Employee Name'
                        : 'Doctor Name'}
                      <input
                        type={'text'}
                        name="doctorName"
                        className="w-full form-input"
                        value={prescriptionData?.doctorName}
                      />
                    </div>
                    <div className="flex-grow">
                      Type:
                      <input
                        type={'text'}
                        className="w-full form-input"
                        value={prescriptionData?.__t}
                        readOnly
                      />
                    </div>
                    <div className="flex-grow">
                      Prescribe By:
                      <input
                        type={'text'}
                        className="w-full form-input"
                        value={prescriptionData?.prescribedBy}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <SpecPrescriptionTableView specsData={prescriptionData} />
                  </div>
                  <PaginationClassic paginationDetails={paginationSpecs} setPage={setPage} />
                </form>
              </div>
            ) : (
              'No Data Found'
            )}
          </Tab>
          <Tab>
            {contactPrescriptions && contactPrescriptions.length > 0 ? (
              <div className="p-4">
                <form>
                  <div className="flex gap-4 ">
                    <div className="flex-grow">
                      Date:
                      <input
                        type={'text'}
                        name="createdAt"
                        className="w-full form-input"
                        value={
                          contactPrescriptionData?.createdAt
                            ? moment(contactPrescriptionData.createdAt).format('DD/MM/YYYY')
                            : 'No Date'
                        }
                      />
                    </div>
                    <div className="flex-grow">
                      {contactPrescriptionData?.prescribedBy == 'employee'
                        ? 'Employee Name'
                        : 'Doctor Name'}
                      <input
                        type={'text'}
                        name="doctorName"
                        className="w-full form-input"
                        value={contactPrescriptionData?.doctorName}
                      />
                    </div>
                    <div className="flex-grow">
                      Type:
                      <input
                        type={'text'}
                        className="w-full form-input"
                        value={contactPrescriptionData?.__t}
                        readOnly
                      />
                    </div>
                    <div className="flex-grow">
                      Prescribe By:
                      <input
                        type={'text'}
                        className="w-full form-input"
                        value={contactPrescriptionData?.prescribedBy}
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <ContactPrescriptionTableView contactsData={contactPrescriptionData} />
                  </div>
                  <PaginationClassic
                    paginationDetails={paginationContacts}
                    setPage={setContactPage}
                  />
                </form>
              </div>
            ) : (
              'No Data Found'
            )}
          </Tab>
        </Tabs>
      </div>
    </ModalBasic>
  );
};

export default ViewPrescriptions;
