import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { fetchInventories, getInventory } from '../../app/reducers/Inventory/inventorySlice'
import { ClipLoader } from 'react-spinners'
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice'
import { getWebsiteOrders } from '../../app/reducers/websiteOrders/websiteOrderSlice'

const TableRow = ({ productId, storeId }) => {
    const dispatch = useDispatch()
    const { inventory, loading } = useSelector(getInventory)
    const  baseUrl  = useSelector(getMediaLibrary);
    const { websiteOrder } = useSelector(getWebsiteOrders)
    const date=new Date(websiteOrder?.docs?.[0]?.createdAt)
    
    useEffect(() => {
        dispatch(fetchInventories({ populate: true, "product._id": productId, "store._id": storeId }))
    }, [])
    console.log("🚀 ~ file: TableRow.jsx:12 ~ TableRow ~ baseUrl:", baseUrl)
    console.log("🚀 ~ file: TableRow.jsx:12 ~ TableRow ~ photo:", inventory?.docs?.[0]?.product?.photos?.[0])
    console.log("🚀 ~ file: TableRaw.jsx:9 ~ TableRaw ~ inventories:", inventory)
    return (
        <>{
            loading ? (
                <ClipLoader />
            ) : (
                <React.Fragment key={productId}>
                    <tr>
                        <td className="px-2 py-3">
                            {inventory?.docs?.[0]?.product?.newBarcode}
                        </td>
                        <td className="px-2 py-3">
                            {date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear()}
                        </td>

                        <td
                            className="px-2 py-3"
                        >
                            <img
                                src={`${"https://s3.ap-south-1.amazonaws.com/eyesdeal.blinklinksolutions.com/"}${inventory?.docs?.[0]?.product?.photos?.[0]}`}
                                style={{ width: "150px", height: "150px" }}
                                alt='No Image'
                            />
                        </td>
                        <td
                            className="px-2 py-3"
                        >
                            {inventory?.docs?.[0]?.product?.sku}
                        </td>
                        <td
                            className="px-2 py-3"
                        >
                            {inventory?.docs?.[0]?.product?.brand?.name}
                        </td>
                        <td
                            className="px-2 py-3"
                        >
                            {inventory?.docs?.[0]?.product?.MRP}
                        </td>
                        <td
                            className="px-2 py-3"
                        >
                            {inventory?.docs?.[0]?.quantity}
                        </td>
                    </tr>

                </React.Fragment>
            )
        }

        </>

    )
}

export default TableRow