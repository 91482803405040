import React from "react";
import ModalBasic from "../infrastructure/Modals/ModalBasic";
import { useEffect } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormSelector from "../forms/FormSelector";
import { FormikProvider, useFormik } from "formik";
import { async } from "regenerator-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editComponent,
  getComponents,
} from "../../app/reducers/Components/componentSlice";
import { ClipLoader } from "react-spinners";
import { fetchPage } from "../../app/reducers/Page/pageSlice";

const ComponentFormModal = ({
  setIsViewComponentModalOpen,
  isViewComponentModalOpen,
  selectedComponent,
  pageId,
}) => {
  console.log("selectedComponent", selectedComponent);
  // const [products, setProducts] = useState()
  const [componentValue, setComponentValue] = useState();
  const { loading } = useSelector(getComponents);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      systemId: selectedComponent?.component?._id,
      content: [
        {
          name: selectedComponent?.name,
          component: selectedComponent?.component,
        },
      ],
    },
    onSubmit: async (values) => {
      console.log(values);
      await dispatch(
        editComponent({
          name: values.content[0].name,
          component: values.content[0].component,
        })
      );
      dispatch(fetchPage({ _id: id, populate: true }));
      setIsViewComponentModalOpen(false);
    },
  });

  return (
    <ModalBasic
      title={selectedComponent?.name}
      modalOpen={isViewComponentModalOpen}
      setModalOpen={setIsViewComponentModalOpen}
      ignoreClick
      max_width="max-w-4xl"
    >
      <div className="p-4 h-[500px] ">
        {loading ? (
          <ClipLoader />
        ) : (
          <form onSubmit={formik.handleSubmit} className="pb-4">
            <FormSelector
              formik={formik}
              index={0}
              component={selectedComponent?.name}
              edit
            />
          </form>
        )}
      </div>
    </ModalBasic>
  );
};

export default ComponentFormModal;
