export const initialState = {
  attributes: {
    brand: [],
    collection: [],
    feature: [],
    color: [],
    frameStyle: [],
    frameType: [],
    unit: [],
    frameShape: [],
    material: [],
    readingPower: [],
    prescriptionType: [],
    subCategory: [],
    tax: [],
    warranty: [],
    lensTechnology: [],
    disposability: [],
  },
  attributesLoading: {
    brand: false,
    collection: false,
    feature: false,
    color: false,
    frameStyle: false,
    frameType: false,
    unit: false,
    frameShape: false,
    material: false,
    readingPower: false,
    prescriptionType: false,
    subCategory: false,
    tax: false,
    warranty: false,
    lensTechnology: false,
    disposability: false,
  },
  editAttributeLoading: {
    brand: false,
    collection: false,
    feature: false,
    color: false,
    frameStyle: false,
    frameType: false,
    unit: false,
    frameShape: false,
    material: false,
    readingPower: false,
    prescriptionType: false,
    subCategory: false,
    tax: false,
    warranty: false,
    lensTechnology: false,
    disposability: false,
  },
  attributesEditId: {
    brand: null,
    collection: null,
    feature: null,
    color: null,
    frameStyle: null,
    frameType: null,
    unit: null,
    frameShape: null,
    material: null,
    readingPower: null,
    prescriptionType: null,
    subCategory: null,
    tax: null,
    warranty: null,
    lensTechnology: null,
    disposability: null,
  },
  attributesEditData: {
    brand: null,
    collection: null,
    feature: null,
    color: null,
    frameStyle: null,
    frameType: null,
    unit: null,
    frameShape: null,
    material: null,
    readingPower: null,
    prescriptionType: null,
    subCategory: null,
    tax: null,
    warranty: null,
    lensTechnology: null,
    disposability: null,
  },
};
