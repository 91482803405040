import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAuth } from "../../app/reducers/Auth/authSlice";

const ShowDropdown = ({ heading, children, allowedRoles }) => {
  const [show, setShow] = useState(false);
  const { user } = useSelector(getAuth);
  return (
    <>
      {allowedRoles.includes(user.role) && (
        <>
          <p
            onClick={() => {
              setShow(!show);
            }}
            className="underline cursor-pointer"
          >
            {heading}
          </p>
          <>{show && <>{children}</>}</>
        </>
      )}
    </>
  );
};

export default ShowDropdown;
