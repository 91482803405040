import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICENAME = "pages";

export const createPage = createData.generateThunk(SLICENAME, SLICENAME);
export const fetchPages = fetchData.generateThunk(SLICENAME, SLICENAME);
export const fetchPage = fetchEditData.generateThunk(SLICENAME, SLICENAME);
export const editPage = editData.generateThunk(SLICENAME, SLICENAME);
export const deletePage = deleteData.generateThunk(SLICENAME, SLICENAME);
const pageSlice = createSlice({
  name: SLICENAME,
  initialState: generateStateSingle(SLICENAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createPage, SLICENAME),
    ...fetchData.generateExtraReducers(fetchPages, SLICENAME),
    ...fetchEditData.generateExtraReducers(fetchPage, SLICENAME),
    ...editData.generateExtraReducers(editPage, SLICENAME),
    ...deleteData.generateExtraReducers(deletePage, SLICENAME),
  },
});

export const getPages = (state) => state.pages;
export default pageSlice.reducer;
