import React, { useMemo } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useSelector } from 'react-redux';
import { fetchStoreFront, getStoreFront } from '../../app/reducers/StoreFront/storeFrontSlice';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { fetchProducts } from '../../app/reducers/Products/productSlice';
import { useDispatch } from 'react-redux';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';

const StoreFrontModal = ({ storeFrontModal, setStoreFrontModal, formik }) => {
  const { storeFront } = useSelector(getStoreFront);
  const dispatch = useDispatch();
  const storefrontArr = useMemo(() => (storeFront.docs ? storeFront.docs : [][storeFront.docs]));
  return (
    <ModalBasic
      title={`Select Store Front `}
      modalOpen={storeFrontModal}
      setModalOpen={setStoreFrontModal}
    >
      <form className="p-5">
        <FormikSelectGroup
          name={`storeFront`}
          label="select storefront"
          formik={formik}
          options={generateOptions({
            array: storefrontArr,
            labelField: 'name',
            valueField: '_id',
          })}
        />
        <div className="mt-4">
          <PrimaryButton
            onClick={async () => {
              const data = await dispatch(fetchStoreFront({ _id: formik.values.storeFront }));
              console.log(data);
              formik.setFieldValue('product', data.payload.data.docs[0].product);
              setStoreFrontModal(false);
            }}
          >
            Submit
          </PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default StoreFrontModal;
