import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, getOrders, resetOrders } from '../../app/reducers/Orders/orderSlice';
import moment from 'moment';
import numeral from 'numeral';
import { BILL_NUMBER_PADDING } from '../../utils/dropdownOptions';
import { fetchSales, getSales } from '../../app/reducers/Sale/saleSlice';
import { ChevronRight } from 'react-feather';
import ExpandingSaleRow from './ExpandingSaleRow';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import { ClipLoader } from 'react-spinners';

const ViewOrders = ({ viewOrdersModalOpen, setViewOrdersModelOpen, selectedUser }) => {
  const { sales, loading } = useSelector(getSales);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(page);
    console.log(selectedUser._id);
    if (selectedUser?._id) {
      dispatch(
        fetchSales({
          populate: true,
          customerId: selectedUser._id,
          limit: 50,
          page,
        })
      );
    }
    return () => {
      dispatch(resetOrders());
    };
  }, [selectedUser._id, page]);

  return (
    <ModalBasic
      modalOpen={viewOrdersModalOpen}
      setModalOpen={setViewOrdersModelOpen}
      title="View Orders"
      max_width="max-w-5xl"
    >
      {loading ? (
        <ClipLoader />
      ) : (
        <div className="p-4">
          <Tabs headers={['Orders', 'Order Notes']}>
            <Tab>
              <div className="grid grid-cols-3 p-4">
                <>
                  <div className="border border-black p-2">Date</div>
                  <div className="border border-black p-2">BillNo</div>
                  <div className="border border-black p-2">Amount</div>
                </>
                {sales?.docs?.map(ele => (
                  <ExpandingSaleRow key={ele._id} sale={ele} />
                ))}
              </div>
              <PaginationClassic paginationDetails={sales} setPage={setPage} />
            </Tab>
            <Tab>
              <div className="p-4">{selectedUser.note}</div>
            </Tab>
          </Tabs>
        </div>
      )}
    </ModalBasic>
  );
};

export default ViewOrders;
