import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/Auth/authSlice';
import emailSlice from './reducers/Emails/emailSlice';
import pageSlice from './reducers/Page/pageSlice';
import productSlice from './reducers/Products/productSlice';
import brandSlice from './reducers/Brand/brandSlice';
import attributesSlice from './reducers/Attributes/attributesSlice';
import componentSlice from './reducers/Components/componentSlice';
import mediaLibrarySlice from './reducers/MediaLibrary/mediaLibrarySlice';
import storeFrontSlice from './reducers/StoreFront/storeFrontSlice';
import lensPackageSlice from './reducers/LensPackage/lensPackageSlice';
import lensCategorySlice from './reducers/LensCategory/lensCategorySlice.js';
import frameTypeSlice from './reducers/FrameType/frameTypeSlice.js';
import websiteOrderSlice from './reducers/websiteOrders/websiteOrderSlice.js';
import storeSlice from './reducers/Stores/storesSlice.js';
import inventorySlice from './reducers/Inventory/inventorySlice.js';
import websiteSlice from './reducers/website/websiteSlice.js';
import userSlice from './reducers/Users/userSlice.js';
import saleSlice from './reducers/Sale/saleSlice.js';
import couponSlice from './reducers/Coupon/couponSlice.js';
import orderSlice from './reducers/Orders/orderSlice';
import marketingReferenceSlice from './reducers/Users/marketingReferenceSlice';
import prescriptionSlice from './reducers/Users/prescriptionSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    emails: emailSlice,
    pages: pageSlice,
    products: productSlice,
    prescriptions: prescriptionSlice,
    brands: brandSlice,
    marketingReference: marketingReferenceSlice,
    attributes: attributesSlice,
    components: componentSlice,
    mediaLibrary: mediaLibrarySlice,
    storeFront: storeFrontSlice,
    lensPackage: lensPackageSlice,
    lensCategory: lensCategorySlice,
    frameType: frameTypeSlice,
    websiteOrder: websiteOrderSlice,
    stores: storeSlice,
    inventories: inventorySlice,
    website: websiteSlice,
    users: userSlice,
    sales: saleSlice,
    coupons: couponSlice,
    orders: orderSlice,
  },
});
