import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import QueryString from 'qs';
import { fetchCoupon, getCoupons, updateCoupon } from '../../app/reducers/Coupon/couponSlice';

export const AddMoreProduct = ({ modalOpen, setModalOpen, couponId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { elementEditData } = useSelector(getCoupons);
  const existingProduct = elementEditData?.product?.map(ele => ele?._id);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product: [''],
    },
    onSubmit: async (values, { resetForm }) => {
      const productsIdArr = values.product;
      const payload = {
        _id: couponId,
        product: [...existingProduct, ...productsIdArr],
      };
      console.log('payload', payload);
      try {
        setIsLoading(true);
        await dispatch(updateCoupon(payload));
        await dispatch(fetchCoupon({ _id: couponId, populate: true }));
        resetForm();
        setModalOpen(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Failed to update Store Front');
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={`${elementEditData?.code} - Add More Product`}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <FieldArray
            name="product"
            render={arrayHelpers => {
              return (
                <div className="flex flex-col gap-4 border rounded-lg p-5">
                  {formik?.values?.product?.map((ele, i) => (
                    <div key={i} className="grid   w-full border rounded-lg">
                      <FormikAsyncSelect
                        name={`product.${i}`}
                        formik={formik}
                        label="Product"
                        getOptions={async value => {
                          console.log('getOptions', value);
                          const query = {
                            search: value,
                            activeInWebsite: true,
                          };
                          const string = QueryString.stringify(query);
                          const products = await authAxiosInstance.get(
                            `products/product?${string}`
                          );

                          const options = products.data.data.docs?.map(ele => {
                            const barcode = ele?.oldBarcode ?? ele?.newBarcode;
                            return {
                              label: `${barcode}/${ele.sku}`,
                              value: ele._id,
                            };
                          });
                          return options;
                        }}
                      />
                      <div>
                        <DangerButton className="mt-7" onClick={() => arrayHelpers.remove(i)}>
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  ))}
                  <div className="flex gap-4">
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push('');
                      }}
                    >
                      Add Product
                    </PrimaryButton>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </div>
                </div>
              );
            }}
          />
        </FormikProvider>
      </form>
    </ModalBasic>
  );
};
