import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import {
  fetchWebsiteOrders,
  getWebsiteOrders,
} from '../../app/reducers/websiteOrders/websiteOrderSlice';
import { fetchStores, getStores } from '../../app/reducers/Stores/storesSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { toast } from 'react-toastify';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import ProductRow from './ProductRow';
import { generateOptions, percentOf } from '../../utils/Utils';
import { fetchUsers, getUsers } from '../../app/reducers/Users/userSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { PAYMENT_METHODS } from '../../utils/dropdownOptions';
import { X } from 'react-feather';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import ViewPrescriptions from './ViewPrescriptions';
import _ from 'lodash';
import ViewOrders from './ViewOrders';
import { useNavigate } from 'react-router-dom';

const TableHeader = ({ headers }) => {
  return (
    <thead className="w-full text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers.map((header, i) => (
          <th key={i} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words">
            <div className="font-semibold text-left break-words" style={header.style}>
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Sale = () => {
  const { websiteOrderId, storeId } = useParams();
  console.log('🚀 ~ file: Sale.jsx:7 ~ Sale ~ params:', websiteOrderId, storeId);
  const dispatch = useDispatch();
  const { users, usersLoading } = useSelector(getUsers);
  const [selectedUser, setSelectedUser] = useState({});
  const [viewPrescriptionsModalOpen, setViewPrescriptionsModalOpen] = useState(false);
  const [viewOrdersModalOpen, setViewOrdersModalOpen] = useState(false);

  const { websiteOrder } = useSelector(getWebsiteOrders);
  const { stores, loading: storeLoading } = useSelector(getStores);
  useEffect(() => {
    dispatch(fetchWebsiteOrders({ _id: websiteOrderId }));
    dispatch(fetchStores({ _id: storeId }));
    dispatch(
      fetchUsers({
        role: { $in: ['sales', 'store_manager', 'individual_store'] },
        stores: storeId,
        isActive: true,
      })
    );
  }, []);
  const [loading, setLoading] = useState(false);
  const productArr = websiteOrder.docs[0].productArr;
  const navigate = useNavigate();

  useEffect(async () => {
    const string = QueryString.stringify({
      _id: websiteOrder?.docs?.[0]?.user,
      populate: true,
    });
    const resp = await authAxiosInstance.get(`user/list?${string}`);
    const user = resp.data.data.docs[0];
    setSelectedUser(user);
  }, []);

  const parsedProductArr = productArr.reduce((acc, ele) => {
    const { _id, ...rest } = ele;
    acc.push(...Object.values(rest));

    return acc;
  }, []);
  console.log('parsedProductArr==>', parsedProductArr);

  const calculateTotalAmount = productArr => {
    return productArr.reduce((acc, ele) => {
      acc += ele.price ?? ele.sellPrice;
      return acc;
    }, 0);
  };
  const calculateTotalTax = productArr => {
    return productArr.reduce((acc, ele) => {
      acc += parseFloat(percentOf(ele.sellPrice ?? ele.price, ele.tax ?? 0));
      return acc;
    }, 0);
  };
  const calculateTotalQuantity = productArr => {
    return productArr.reduce((acc, ele) => {
      acc += ele.quantity ?? 1;
      return acc;
    }, 0);
  };
  const calculateTotalDiscount = productArr => {
    return productArr.reduce((acc, ele) => {
      acc += ele.MRP && ele.sellPrice ? ele.MRP - ele.sellPrice : 0;
      return acc;
    }, 0);
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      store: storeId,
      customerId: websiteOrder?.docs?.[0]?.user,
      customerName: websiteOrder?.docs?.[0]?.customerName,
      customerPhone: websiteOrder?.docs?.[0]?.phone,
      salesRep: '',
      products: productArr,
      productStock: [],
      totalAmount: calculateTotalAmount(parsedProductArr),
      totalTax: calculateTotalTax(parsedProductArr),
      totalQuantity: calculateTotalQuantity(parsedProductArr),
      totalDiscount: calculateTotalDiscount(parsedProductArr),

      flatDiscount:
        Object?.values(
          websiteOrder?.docs?.[0]?.discountAndCharges
            ? websiteOrder?.docs?.[0]?.discountAndCharges
            : {}
        )?.reduce((acc, ele) => {
          if (ele < 0) {
            return acc + ele * -1;
          }
          return acc;
        }, 0) || 0,
      netDiscount: productArr?.reduce(
        (acc, ele) =>
          acc +
          Object?.values(ele)?.reduce(
            (acc, val) => acc + (val?.MRP && val.sellPrice ? val.MRP - val.sellPrice : 0),
            0
          ),
        0
      ),
      deliveryCharges: 0,
      otherCharges:
        Object.values(websiteOrder?.docs?.[0]?.discountAndCharges || {})?.reduce((acc, ele) => {
          if (ele > 0) {
            return acc + ele;
          }
        }, 0) || 0,
      netAmount: null,
      receivedAmount: [],
      incentiveAmount: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log(values, 'check values');
        const stockAvailableObj = values.productStock.reduce((acc, ele) => {
          const key = Object.keys(ele);
          if (!acc[key]) {
            acc[key] = ele[key];
          }
          return acc;
        }, {});
        console.log('stockAvailableObj==>', stockAvailableObj);

        const checkOutOfStock = values.products.reduce((acc, ele) => {
          const productId = ele.product._id;
          stockAvailableObj[productId]--;
          if (stockAvailableObj[productId] < 0) acc = true;

          return acc;
        }, false);
        if (checkOutOfStock) {
          return alert('Product+ out of stock');
        }
        if (values.products.length <= 0) {
          toast.error('Cannot create empty sale');
          throw 'Cannot create empty sale';
        }
        // setLoading(true);
        // const saleResp = authAxiosInstance.post("/sales", {
        //   ...values,
        // });
        const powerAtTime = {};
        const reversedPrescriptions = _.reverse(selectedUser?.prescriptions);
        const userPrescriptions = reversedPrescriptions ? reversedPrescriptions : [];

        powerAtTime.specs = userPrescriptions.filter(ele => ele.__t === 'specs')[0]?._id;
        powerAtTime.contacts = userPrescriptions.filter(ele => ele.__t === 'contacts')[0]?._id;
        delete values.productStock;
        const resp = await authAxiosInstance.post('sales', {
          ...values,
          powerAtTime,
        });
        navigate(`erp.eyesdeal.com`);
      } catch (error) {
        console.log('Error=>', error);
        toast.error('error in submitting');
      }

      //   try {
      //     if (values?.receivedAmount.length > 0) {
      //       let checkAmount = values.receivedAmount.find((item) => item.amount === '')
      //       if (checkAmount) {
      //         return alert('Please Enter The Proper Amount')
      //       }
      //     }
      //     const totalReceivedAmount = values.receivedAmount.reduce(
      //       (acc, ele) => acc + ele.amount,
      //       0
      //     );
      //     if (totalReceivedAmount > values.netAmount) {
      //       toast.error("Total received amount greater than bill amount");
      //       throw "Invalid received amount";
      //     }
      //     if (values.products.length <= 0) {
      //       toast.error("Cannot create empty Bill");
      //       throw "Cannot create empty bill";
      //     }
      //     setLoading(true);
      //     const powerAtTime = {};
      //     const reversedPrescriptions = _.reverse(selectedUser?.prescriptions);
      //     const userPrescriptions = reversedPrescriptions
      //       ? reversedPrescriptions
      //       : [];
      //     powerAtTime.specs = userPrescriptions.filter(
      //       (ele) => ele.__t === "specs"
      //     )[0]?._id;
      //     powerAtTime.contacts = userPrescriptions.filter(
      //       (ele) => ele.__t === "contacts"
      //     )[0]?._id;
      //     const resp = await authAxiosInstance.post("sales", {
      //       ...values,
      //       powerAtTime,
      //     });
      //     navigate(`/bill/${resp.data.data.sale._id}`);
      //     dispatch(addPendingSms(resp.data.data.sale));
      //     setSubmitting(true);
      //     toast.success("sale generated");
      //   } catch (error) {
      //     console.log(error);
      //     if (error?.response?.data?.error?.message) {
      //       toast.error(error?.response?.data?.error?.message);
      //     }
      //     toast.error("error creating sale");
      //   } finally {
      //     setLoading(false);
      //   }
    },
  });

  // console.log(formik.values);
  return (
    <div>
      <ViewPrescriptions
        prescriptions={selectedUser.prescriptions}
        viewPrescriptionsModalOpen={viewPrescriptionsModalOpen}
        setViewPrescriptionsModalOpen={setViewPrescriptionsModalOpen}
        startPage={1}
      />
      <ViewOrders
        viewOrdersModalOpen={viewOrdersModalOpen}
        selectedUser={selectedUser}
        setViewOrdersModelOpen={setViewOrdersModalOpen}
      />
      {storeLoading || stores.length < 0 ? (
        <ClipLoader />
      ) : (
        <form className="grid md:grid-cols-4 p-10 w-full" onSubmit={formik.handleSubmit}>
          <div className="col-span-3 flex flex-col gap-4 overflow-auto border border-black p-4">
            <FormikAsyncSelect
              name="customerId"
              label="Customer"
              formik={formik}
              getOptions={async value => {
                try {
                  const query = {
                    search: value,
                    populate: true,
                  };
                  const string = QueryString.stringify(query);
                  const users = await authAxiosInstance.get(`user/list?${string}`);
                  const options = users.data.data.docs.map(ele => ({
                    label: `${ele.name} / ${ele.phone}`,
                    value: ele._id,
                  }));
                  return options;
                } catch (error) {
                  console.log(error);
                  toast.error('failed to fetch customer');
                }
              }}
              onChange={async selectedOption => {
                const string = QueryString.stringify({
                  _id: selectedOption.value,
                  populate: true,
                });
                const resp = await authAxiosInstance.get(`user/list?${string}`);

                const user = resp.data.data.docs[0];
                console.log('🚀 ~ file: Sale.jsx:150 ~ onChange={ ~ user:', user);
                formik.setFieldValue('customerId', user._id);
                formik.setFieldValue('customerName', user.name);
                formik.setFieldValue('customerPhone', user.phone);
                console.log('settingUser', user);
                setSelectedUser(user);
              }}
              isDisabled={true}
            />
            <div className=" gap-4 w-full grid grid-rows-1 md:grid-cols-3">
              <FormikInputGroup
                name="customerName"
                formik={formik}
                label="Customer Name"
                readOnly
                fullWidth
              />
              <FormikInputGroup
                name="customerPhone"
                formik={formik}
                label="Customer Phone"
                readOnly
                fullWidth
              />
              {usersLoading ? (
                <ClipLoader />
              ) : (
                <FormikSelectGroup
                  formik={formik}
                  name="salesRep"
                  label="Sales Rep"
                  options={generateOptions({
                    array: users?.docs,
                    labelField: 'name',
                    valueField: '_id',
                  })}
                  readOnly
                />
              )}
            </div>
            <div>
              {!_.isEmpty(selectedUser) && (
                <div className="flex gap-4">
                  <SecondaryButton
                    onClick={e => {
                      e.stopPropagation();
                      setViewPrescriptionsModalOpen(true);
                    }}
                  >
                    View Prescriptions
                  </SecondaryButton>
                  <SecondaryButton
                    onClick={e => {
                      e.stopPropagation();
                      setViewOrdersModalOpen(true);
                    }}
                  >
                    View Orders
                  </SecondaryButton>
                  <SecondaryButton
                    onClick={() => {
                      navigate(`/editUser/${websiteOrderId}/${storeId}/${selectedUser._id}`);
                    }}
                  >
                    Add Power
                  </SecondaryButton>
                </div>
              )}
            </div>
            <table className="table-auto w-full">
              <TableHeader
                headers={[
                  { name: 'barcode', style: { minWidth: '80px' } },
                  {
                    name: 'sku',
                    style: { minWidth: '160px' },
                  },
                  { name: 'photos', style: { minWidth: '80px' } },
                  { name: 'stock', style: { minWidth: '20px' } },
                  { name: 'mrp', style: { minWidth: '80px' } },
                  { name: 'srp', style: { minWidth: '80px' } },
                  { name: 'taxRate', style: { minWidth: '80px' } },

                  {
                    name: 'Tax Amount',
                    style: { minWidth: '20px' },
                  },
                  { name: 'Discount', style: { minWidth: '20px' } },
                  {
                    name: 'Total Amount',
                    style: { minWidth: '50px' },
                  },
                ]}
              />
              <tbody className="text-sm">
                {formik?.values?.products?.map((ele, i) => (
                  <Fragment key={i}>
                    <ProductRow
                      i={i}
                      formik={formik}
                      productId={ele?.product?._id}
                      storeId={storeId}
                    />
                    {/* -----if lens exist---- */}
                    {ele.lens_package && (
                      <tr>
                        <td className="px-2 py-3">{'...........'}</td>
                        <td className="px-2 py-3">{ele?.lens_package?.sku}</td>
                        <td className="px-2 py-3">
                          <img
                            src={`${'https://s3.ap-south-1.amazonaws.com/eyesdeal.blinklinksolutions.com/'}${
                              ele?.lens_package?.image
                            }`}
                            style={{ width: '150px' }}
                            alt="No Image"
                          />
                        </td>
                        <td className="px-2 py-3">{'NA'}</td>

                        <td className="px-2 py-3">{ele?.lens_package?.price}</td>
                        <td className="px-2 py-3">{ele?.lens_package?.price}</td>
                        <td className="px-2 py-3">{ele?.lens_package?.tax || 0} %(inc.)</td>
                        <td className="px-2 py-3">
                          {percentOf(ele?.lens_package?.price, ele?.lens_package?.tax || 0)}
                        </td>
                        <td className="px-2 py-3">{0} %</td>
                        <td className="px-2 py-3">{Number(ele?.lens_package?.price)}</td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
          {stores.length && (
            <div className="col-span-1 pl-8 grid grid-cols-1 gap-2 border  border-black p-4">
              {storeLoading ? (
                <ClipLoader />
              ) : (
                <FormikSelectGroup
                  formik={formik}
                  name="store"
                  label="Store"
                  options={
                    stores?.map(el => {
                      return {
                        label: el.name,
                        value: el._id,
                      };
                    }) ?? []
                  }
                  isDisabled
                />
              )}
              <div className="flex gap-2 flex-row items-center">
                <label>Total Quantity</label>
                <FormikInputGroup type="number" readOnly formik={formik} name="totalQuantity" />
              </div>
              <div className="flex gap-2 flex-row items-center">
                <label>Total Amount</label>
                <FormikInputGroup type="number" readOnly formik={formik} name="totalAmount" />
              </div>
              <div className="flex gap-2 flex-row items-center">
                <label>Total Tax</label>
                <FormikInputGroup type="number" readOnly min={0} formik={formik} name="totalTax" />
              </div>
              <div className="flex gap-2 flex-row items-center">
                <label>Flat Discount</label>
                <FormikInputGroup
                  type="number"
                  formik={formik}
                  name="flatDiscount"
                  onChange={value => {
                    formik.setFieldValue('flatDiscount', Number(value.target.value));
                    formik.setFieldValue(
                      'netDiscount',
                      Number(value.target.value) + Number(formik.values.totalDiscount)
                    );
                  }}
                />
              </div>
              <div className="flex gap-2 flex-row items-center">
                <label>Other Charges</label>

                <FormikInputGroup type="number" formik={formik} name="otherCharges" />
              </div>
              <div className="flex gap-2 flex-row items-center">
                <label>Net Discount</label>
                <FormikInputGroup type="number" readOnly formik={formik} name="netDiscount" />
              </div>

              <div className="flex gap-2 flex-row items-center">
                <label>Net Amount</label>
                <FormikInputGroup
                  readOnly
                  type="number"
                  formik={formik}
                  name="netAmount"
                  min={0}
                  value={
                    formik.values.totalAmount +
                    formik.values.deliveryCharges +
                    formik.values?.otherCharges -
                    formik.values.flatDiscount
                  }
                />
              </div>
              <FormikTextareaGroup name="note" label="Notes" formik={formik} />
            </div>
          )}

          <div className="col-span-4 flex flex-col border border-black p-4">
            <div className="flex flex-row-reverse">
              <span>
                Due Amount :{' '}
                {formik.values.netAmount -
                  formik.values.receivedAmount.reduce((acc, cr) => acc + cr.amount, 0)}
              </span>
            </div>
            <FormikProvider value={formik}>
              <FieldArray
                name="receivedAmount"
                render={arrayHelpers => {
                  return (
                    <>
                      <div className="flex gap-4 items-center mb-4">
                        <label>Received Amount</label>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({
                              method: 'cash',
                              amount: 0,
                              date: new Date(),
                              reference: '',
                            });
                          }}
                        >
                          Add
                        </SecondaryButton>
                      </div>
                      <table className="table-auto w-full">
                        <tbody className="text-sm divide-y divide-slate-200">
                          {formik?.values?.receivedAmount?.map((ele, i) => (
                            <tr key={i}>
                              <td className="px-2 py-3 whitespace-nowrap first:pl-5 last:pr-5">
                                <div className="flex">
                                  <X
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                    }}
                                  />
                                  <FormikSelectGroup
                                    formik={formik}
                                    name={`receivedAmount.${i}.method`}
                                    options={generateOptions({
                                      array: PAYMENT_METHODS,
                                      labelField: 'label',
                                      valueField: 'value',
                                    })}
                                  />
                                </div>
                              </td>
                              <td>
                                <FormikInputGroup
                                  name={`receivedAmount.${i}.amount`}
                                  formik={formik}
                                  type="number"
                                />
                              </td>
                              <td>
                                <FormikInputDateGroup
                                  name={`receivedAmount.${i}.date`}
                                  formik={formik}
                                />
                              </td>
                              <td>
                                <FormikInputGroup
                                  formik={formik}
                                  name={`receivedAmount.${i}.reference`}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  );
                }}
              />
            </FormikProvider>
          </div>
          <div className="mt-4">
            <PrimaryButton disabled={formik.isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default Sale;
