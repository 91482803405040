import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "brands";

export const createBrand = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchBrands = fetchData.generateThunk(SLICE_NAME, "master/brand");
export const fetchBrand = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editBrand = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteBrand = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const brandSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createBrand, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchBrands, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchBrand, SLICE_NAME),
    ...editData.generateExtraReducers(editBrand, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteBrand, SLICE_NAME),
  },
});

export const getBrands = (state) => state.brands;
export default brandSlice.reducer;
