import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { authAxiosInstance } from '../../../utils/axiosConfig';
const initialState = {
  prescriptions: [],
  addPrescriptionModalOpen: '',
  editPrescriptionId: '',
  editPrescriptionData: {},
  loading: false,
};
export const deletePrescriptionOnServer = createAsyncThunk(
  'prescriptions/deletePrescriptionOnServer',
  async data => {
    try {
      const confirm = window.confirm(
        'are you sure you want to delete this prescription from server'
      );
      if (confirm) {
        const resp = await authAxiosInstance.delete(`prescriptions/${data}`);
        toast.success('prescription deleted');
        return resp.data;
      }
    } catch (error) {
      toast.error('failed to delete prescription');
      console.error(error);
    }
  }
);
const prescriptionSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    addPrescription: (state, action) => {
      state.prescriptions.push({
        ...action.payload,
        pres_id: action.payload._id ? action.payload._id : nanoid(),
      });
    },
    openPrescriptionModal: (state, action) => {
      console.log(action.payload);
      state.addPrescriptionModalOpen = action.payload;
    },
    removePrescription: (state, action) => {
      const prescriptions = state.prescriptions.filter(
        prescription => prescription.pres_id !== action.payload
      );
      state.prescriptions = prescriptions;
    },
    setEditPrescriptionId: (state, action) => {
      const prescription = state.prescriptions.find(ele => ele.pres_id === action.payload);

      state.addPrescriptionModalOpen = prescription.__t;
      state.editPrescriptionData = prescription;
      state.editPrescriptionId = action.payload;
    },
    editPrescription: (state, action) => {
      const prescriptionIndex = state.prescriptions.findIndex(
        ele => ele.pres_id === action.payload.pres_id
      );
      if (prescriptionIndex !== -1) {
        state.prescriptions[prescriptionIndex] = action.payload;
      }
    },
    removeEditPrescriptionData: (state, action) => {
      state.editPrescriptionData = {};
    },
    resetPrescriptions: (state, action) => {
      state.prescriptions = [];
    },
  },
  extraReducers: {
    [deletePrescriptionOnServer.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePrescriptionOnServer.fulfilled]: state => {
      state.loading = false;
    },
    [deletePrescriptionOnServer.rejected]: state => {
      state.loading = false;
    },
  },
});

export const getPresctiptions = state => state.prescriptions;
export const {
  addPrescription,
  removePrescription,
  openPrescriptionModal,
  setEditPrescriptionId,
  removeEditPrescriptionData,
  editPrescription,
  resetPrescriptions,
} = prescriptionSlice.actions;
export default prescriptionSlice.reducer;
