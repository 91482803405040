import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { ProductPhotoSlider } from "../Products/ProductPhotoSlider";

export const PhotoProductModal = ({
  photoModalOpen,
  setPhotoModalOpen,
  data,
}) => {
  return (
    <ModalBasic
      title={`Photo Product Modal`}
      modalOpen={photoModalOpen}
      setModalOpen={setPhotoModalOpen}
    >
      <ProductPhotoSlider data={data} />
    </ModalBasic>
  );
};
