import React from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useDispatch } from "react-redux";
import { createStoreFront } from "../../app/reducers/StoreFront/storeFrontSlice";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";

export const CreateStoreFront = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      banner: "",
      product: [],
      seoTitle: "",
      seoDescription: "",
      seoImage: "",
    },
    onSubmit: (values) => {
      console.log("values==>", values);
      dispatch(createStoreFront(values));
    },
  });
  return (
    <PageWithCard heading="Create Store Front">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <div className="w-full mb-8 flex flex-col gap-4">
          <ShowDropdown allowedRoles={["admin"]} heading="Add Seo Details">
            <FormikInputGroup label="Title" name="seoTitle" formik={formik} />
            <FormikTextareaGroup
              label="Description"
              name="seoDescription"
              formik={formik}
            />
            <FormikDirectFileUpload
              name={`seoImage`}
              formik={formik}
              label={`Image `}
              location={`eyesdeal/website/image/storefront/seo/`}
              randomize={true}
            />
          </ShowDropdown>
        </div>
        <FormikInputGroup formik={formik} name="name" label="Name" required />
        <p className="text-sm mt-[-14px] text-gray-500 mx-1">
          Name can not be edit and update
        </p>

        <FormikDirectFileUpload
          name={`banner`}
          formik={formik}
          label={`Image Banner`}
          location={`eyesdeal/website/banner/storeFront/`}
          randomize={true}
        />

        <FormikProvider value={formik}>
          <FieldArray
            name="product"
            render={(arrayHelpers) => {
              return (
                <div className="flex flex-col gap-4 border rounded-lg p-5">
                  {formik.values.product.map((ele, i) => (
                    <div
                      key={i}
                      className="grid grid-cols-2 gap-4 w-full border p-8  rounded-lg"
                    >
                      <FormikAsyncSelect
                        name={`product.${i}`}
                        formik={formik}
                        label="Product"
                        getOptions={async (value) => {
                          console.log("getOptions", value);
                          const query = {
                            search: value,
                            activeInWebsite: true,
                          };
                          const string = QueryString.stringify(query);
                          const products = await authAxiosInstance.get(
                            `products/product?${string}`
                          );

                          const options = products.data.data.docs?.map(
                            (ele) => {
                              const barcode =
                                ele?.oldBarcode ?? ele?.newBarcode;
                              return {
                                label: `${barcode}/${ele.sku}`,
                                value: ele._id,
                              };
                            }
                          );
                          return options;
                        }}
                      />
                      <div>
                        <DangerButton
                          className="mt-7"
                          onClick={() => arrayHelpers.remove(i)}
                        >
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  ))}
                  <div className="flex gap-4">
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push("");
                      }}
                    >
                      Add Product
                    </PrimaryButton>
                  </div>
                </div>
              );
            }}
          />
        </FormikProvider>

        <div>
          <PrimaryButton type="submit">submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};
