import React from 'react'
import FormikInputGroup from '../formik/FormikInputGroup'
import PrimaryButton from '../infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../infrastructure/Buttons/SecondaryButton';
import DangerButton from '../infrastructure/Buttons/DangerButton';
import FormikDirectFileUpload from '../formik/FormikDirectFileUpload';
import { FieldArray, FormikProvider } from 'formik';

const TestimonialContainer = ({ edit = false, index, formik }) => {
    return (
        <>
            <FormikInputGroup
                name={`content.${index}.component.heading`}
                label="Heading"
                formik={formik}
            />
            <FormikInputGroup
                name={`content.${index}.component.sub_heading`}
                label="Sub Heading"
                formik={formik}
            />
            <FormikDirectFileUpload
                name={`content.${index}.component.decoration`}
                formik={formik}
                label={`decoration`}
                location={`eyesdeal/website/testimonial/decoration/`}
                randomize={true}
            />
            <FormikProvider value={formik}>
                <FieldArray
                    name={`content.${index}.component.testimonials`}
                    render={(arrayHelpers) => {
                        return (
                            <>
                                {formik?.values?.content?.[index]?.component?.testimonials?.map(
                                    (column, i) => (
                                        <div className="grid grid-cols-3 gap-3" key={i}>
                                            <FormikDirectFileUpload
                                                name={`content.${index}.component.testimonials.${i}.photo`}
                                                formik={formik}
                                                label={`Photo ${i + 1}`}
                                                location={`eyesdeal/website/testimonial/images/`}
                                                randomize={true}
                                            />
                                            <FormikInputGroup
                                                name={`content.${index}.component.testimonials.${i}.name`}
                                                formik={formik}
                                                label={`title ${i + 1}`}
                                            />
                                            <FormikInputGroup
                                                name={`content.${index}.component.testimonials.${i}.testimonial`}
                                                formik={formik}
                                                label={`testimonial ${i + 1}`}
                                            />
                                            <FormikInputGroup
                                                name={`content.${index}.component.testimonials.${i}.designation`}
                                                formik={formik}
                                                label={`designation ${i + 1}`}
                                            />
                                            <div className="flex items-center">
                                                <DangerButton
                                                    onClick={() => {
                                                        arrayHelpers.remove(i);
                                                    }}
                                                >
                                                    Remove
                                                </DangerButton>
                                            </div>
                                        </div>
                                    )
                                )}
                                <div className="my-2 flex gap-4">
                                    <SecondaryButton onClick={() => arrayHelpers.push("")}>
                                        Add More testimonial
                                    </SecondaryButton>
                                    {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
                                </div>
                            </>
                        );
                    }}
                />
            </FormikProvider>
        </>
    )
}

export default TestimonialContainer