import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';

const initialState = {
  references: [],
  loading: false,
  error: null,
  editReferenceLoading: false,
  editReferenceData: {},
  editReferenceId: '',
};

export const fetchMarketingReferences = createAsyncThunk(
  'marketingReference/fetchReferences',
  async (_, { rejectWithValue }) => {
    try {
      const res = await authAxiosInstance.get('user/marketingReferences');
      return res.data;
    } catch (error) {
      console.error(error);
      rejectWithValue({ msg: 'cannot fetch references', error: error });
    }
  }
);
export const addReference = createAsyncThunk(
  'marketingReference/addReference',
  async (data, { rejectWithValue, dispatch }) => {
    console.log(data);
    try {
      const resp = await authAxiosInstance.post('user/marketingReferences', data);
      dispatch(fetchMarketingReferences());
      return resp;
    } catch (error) {
      console.error(error);
      rejectWithValue(error);
      toast.error('didnot work');
      rejectWithValue('something went wrong while creating reference');
    }
  }
);
export const deleteReference = createAsyncThunk(
  'marketingReference/deleteReference',
  async (data, { rejectWithValue, dispatch }) => {
    console.log(data);
    const confirm = window.confirm('are you sure you want to delete this reference');
    if (confirm) {
      try {
        const del = await authAxiosInstance.delete(`user/marketingReferences/${data}`);
        dispatch(fetchMarketingReferences());
      } catch (error) {
        console.error(error);
        toast.error('didnot work');
        return rejectWithValue('something went wrong while deleting reference');
      }
    }
    try {
      const resp = await authAxiosInstance.post('user/marketingReference', data);
      dispatch(fetchMarketingReferences());
      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);
export const getEditReferenceData = createAsyncThunk(
  'marketingReference/getEditReferenceData',
  async data => {
    try {
      const response = await authAxiosInstance.get(`user/marketingReferences/${data}`);
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error('cannot fetch given reference');
    }
  }
);
export const editReference = createAsyncThunk(
  'marketingReference/editReference',
  async (data, { dispatch }) => {
    try {
      const response = await authAxiosInstance.patch(`user/marketingReferences`, data);
      toast.success(`reference updated`);
      dispatch(fetchMarketingReferences());
      dispatch(setReferenceId(''));
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error('cannot edit given reference');
    }
  }
);

const marketingReferenceSlice = createSlice({
  name: 'marketingReference',
  initialState,
  reducers: {
    setReferenceId: (state, action) => {
      state.editReferenceId = action.payload;
    },
  },
  extraReducers: {
    [fetchMarketingReferences.pending]: state => {
      state.loading = true;
    },
    [fetchMarketingReferences.fulfilled]: (state, action) => {
      state.references = action.payload.data;
      state.loading = false;
    },
    [fetchMarketingReferences.failed]: (state, action) => {
      state.error = action.payload.msg;
      state.loading = false;
    },
    [addReference.pending]: state => {
      state.loading = true;
    },
    [addReference.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addReference.failed]: (state, action) => {
      state.loading = false;
    },
    [getEditReferenceData.pending]: state => {
      state.editReferenceLoading = true;
    },
    [getEditReferenceData.fulfilled]: (state, action) => {
      state.editReferenceData = action.payload.data;
      state.editReferenceLoading = false;
    },
    [getEditReferenceData.failed]: (state, action) => {
      state.editReferenceLoading = false;
    },
    [editReference.pending]: state => {
      state.editReferenceLoading = true;
    },
    [editReference.fulfilled]: (state, action) => {
      state.editReferenceLoading = false;
    },
    [editReference.failed]: (state, action) => {
      state.editReferenceLoading = false;
    },
  },
});

export const getMarketingRefrerences = state => state.marketingReference;
export const { setReferenceId } = marketingReferenceSlice.actions;
export default marketingReferenceSlice.reducer;
