import React, { Fragment } from "react";
import FormikDirectFileUpload from "../formik/FormikDirectFileUpload";
import FormikInputGroup from "../formik/FormikInputGroup";
import { FieldArray, FormikProvider } from "formik";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import DangerButton from "../infrastructure/Buttons/DangerButton";

const ImageBanner = ({ edit = false, index, formik }) => {
  return (
    <div className="flex flex-col gap-6 mt-4">
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />

      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <FormikInputGroup
          name={`content.${index}.component.paddingTopDesktop`}
          label="Padding Desktop Top"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingBottomDesktop`}
          label="Padding Desktop Bottom"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingLeftDesktop`}
          label="Padding Desktop Left"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingRightDesktop`}
          label="Padding Desktop Right"
          formik={formik}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <FormikInputGroup
          name={`content.${index}.component.paddingTopMobile`}
          label="Padding Mobile Top"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingBottomMobile`}
          label="Padding Mobile Bottom"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingLeftMobile`}
          label="Padding Mobile Left"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.paddingRightMobile`}
          label="Padding Mobile Right"
          formik={formik}
        />
      </div>

      <FormikProvider value={formik}>
        <FieldArray
          name={`content.${index}.component.photos`}
          render={(arrayHelpers) => {
            return (
              <>
                {formik?.values?.content?.[index]?.component?.photos?.map(
                  (photo, i) => (
                    <div className="grid grid-cols-3 gap-3" key={i}>
                      <FormikDirectFileUpload
                        name={`content.${index}.component.photos.${i}.laptopImage`}
                        formik={formik}
                        label={`Photo Laptop ${i + 1}`}
                        location={`eyesdeal/website/banner/images/`}
                        randomize={true}
                      />
                      <FormikDirectFileUpload
                        name={`content.${index}.component.photos.${i}.mobileImage`}
                        formik={formik}
                        label={`Photo Mobile ${i + 1}`}
                        location={`eyesdeal/website/banner/images/`}
                        randomize={true}
                      />
                      <FormikInputGroup
                        name={`content.${index}.component.photos.${i}.link`}
                        formik={formik}
                        label={`Link ${i + 1}`}
                      />
                      <div className="flex items-center">
                        <DangerButton
                          onClick={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          Remove
                        </DangerButton>
                      </div>
                    </div>
                  )
                )}
                <div className="my-2 flex gap-4">
                  <SecondaryButton onClick={() => arrayHelpers.push({})}>
                    Add More Photos
                  </SecondaryButton>
                  {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
                </div>
              </>
            );
          }}
        />
      </FormikProvider>
    </div>
  );
};

export default ImageBanner;
