import React from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { DISTANCEVISIONSPECS, generateRx, NEARVISIONSPECS } from '../../utils/dropdownOptions';
import _ from 'lodash';

const RxRow = ({ options, name, formik, ...props }) => {
  return (
    <FormikSelectGroup
      formik={formik}
      options={options}
      name={name}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};

const SpecsPrescriptionTable = ({ formik }) => {
  return (
    <>
      <h1>Specs Power</h1>
      <table className="border-collapse border border-slate-400 table-auto w-full divide-y divide-slate-200">
        {/* Table header */}
        <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
          <tr>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left"></div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RESPH</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RECYL</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RAXIS</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">RVISION</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">ADD</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold">||</div>
            </th>

            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LESPH</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LECYL</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LAXIS</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold text-left">LEVision</div>
            </th>
            <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
              <div className="font-semibold">Add</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="max-w-[20px] border text-center">D</td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -24, end: 24, step: 0.25 })}
                name="right.distance.sph"
                onChange={selectedOption => {
                  const addValue = _.at(formik.values, 'right.distance.add')[0];
                  formik.setFieldValue('right.distance.sph', selectedOption.value);
                  if (parseFloat(addValue)) {
                    const nearVal = parseFloat(addValue) + parseFloat(selectedOption.value);
                    console.log(nearVal > 20);
                    console.log(nearVal < -20);
                    if (nearVal > 24 || nearVal < -24) {
                      alert('max value reached');
                    } else {
                      const nearValString =
                        nearVal > 0
                          ? '+' + nearVal.toFixed(2).toString()
                          : nearVal.toFixed(2).toString();
                      formik.setFieldValue('right.near.sph', nearValString);
                    }
                  } else {
                    formik.setFieldValue('right.near.sph', selectedOption.value);
                  }
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -8, end: 8, step: 0.25 })}
                name="right.distance.cyl"
                onChange={selectedOption => {
                  formik.setFieldValue('right.distance.cyl', selectedOption.value);
                  formik.setFieldValue('right.near.cyl', selectedOption.value);
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="right.distance.axis"
                onChange={selectedOption => {
                  formik.setFieldValue('right.distance.axis', selectedOption.value.toString());
                  formik.setFieldValue('right.near.axis', selectedOption.value.toString());
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={DISTANCEVISIONSPECS} name="right.distance.vs" />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: 0.0, end: 3, step: 0.25 })}
                name="right.distance.add"
                onChange={selectedOption => {
                  const distValue = _.at(formik.values, 'right.distance.sph')[0];
                  if (distValue) {
                    const nearVal = parseFloat(distValue) + parseFloat(selectedOption.value);
                    if (nearVal > 24 || nearVal < -24) {
                      alert('max value reached');
                    } else {
                      const nearValString =
                        nearVal > 0
                          ? '+' + nearVal.toFixed(2).toString()
                          : nearVal.toFixed(2).toString();
                      formik.setFieldValue('right.distance.add', selectedOption.value);
                      formik.setFieldValue('right.near.sph', nearValString);
                    }
                  } else {
                    alert('please select distance sph to compute addition');
                  }
                }}
              />
            </td>
            <td></td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -24, end: 24, step: 0.25 })}
                name="left.distance.sph"
                onChange={selectedOption => {
                  const addValue = _.at(formik.values, 'left.distance.add')[0];
                  formik.setFieldValue('left.distance.sph', selectedOption.value);
                  if (parseFloat(addValue)) {
                    const nearVal = parseFloat(addValue) + parseFloat(selectedOption.value);
                    console.log(nearVal > 20);
                    console.log(nearVal < -20);
                    if (nearVal > 24 || nearVal < -24) {
                      alert('max value reached');
                    } else {
                      const nearValString =
                        nearVal > 0
                          ? '+' + nearVal.toFixed(2).toString()
                          : nearVal.toFixed(2).toString();
                      formik.setFieldValue('left.near.sph', nearValString);
                    }
                  } else {
                    formik.setFieldValue('left.near.sph', selectedOption.value);
                  }
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -8, end: 8, step: 0.25 })}
                name="left.distance.cyl"
                onChange={selectedOption => {
                  formik.setFieldValue('left.distance.cyl', selectedOption.value);
                  formik.setFieldValue('left.near.cyl', selectedOption.value);
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="left.distance.axis"
                onChange={selectedOption => {
                  formik.setFieldValue('left.distance.axis', selectedOption.value.toString());
                  formik.setFieldValue('left.near.axis', selectedOption.value.toString());
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={DISTANCEVISIONSPECS} name="left.distance.vs" />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: 0.0, end: 3, step: 0.25 })}
                name="left.distance.add"
                onChange={selectedOption => {
                  const distValue = _.at(formik.values, 'left.distance.sph')[0];
                  if (distValue) {
                    const nearVal = parseFloat(distValue) + parseFloat(selectedOption.value);
                    if (nearVal > 24 || nearVal < -24) {
                      alert('max value reached');
                    } else {
                      const nearValString =
                        nearVal > 0
                          ? '+' + nearVal.toFixed(2).toString()
                          : nearVal.toFixed(2).toString();
                      formik.setFieldValue('left.distance.add', selectedOption.value);
                      formik.setFieldValue('left.near.sph', nearValString);
                    }
                  } else {
                    alert('please select distance sph to compute addition');
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="max-w-[70px] border text-center">N</td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -24, end: 24, step: 0.25 })}
                name="right.near.sph"
                onChange={selectedOption => {
                  const addValue = _.at(formik.values, 'right.distance.add')[0];
                  const distanceValue = _.at(formik.values, 'right.distance.sph');
                  if (distanceValue) {
                    if (addValue) {
                      const newDistanceValue =
                        parseFloat(selectedOption.value) - parseFloat(addValue);

                      if (newDistanceValue > 24 || newDistanceValue < -24) {
                        alert('max value reached');
                      } else {
                        const distanceValString =
                          newDistanceValue > 0
                            ? '+' + newDistanceValue.toFixed(2).toString()
                            : newDistanceValue.toFixed(2).toString();
                        formik.setFieldValue('right.distance.sph', distanceValString);
                        formik.setFieldValue('right.near.sph', selectedOption.value);
                      }
                    } else {
                      const newAddValue =
                        parseFloat(selectedOption.value) - parseFloat(distanceValue);

                      if (newAddValue > 3) {
                        alert('max value reached');
                      } else {
                        const addValueString = '+' + newAddValue.toFixed(2).toString();

                        formik.setFieldValue('right.distance.add', addValueString);
                        formik.setFieldValue('right.near.sph', selectedOption.value);
                      }
                    }
                  } else {
                    alert('distance value is required to compute addition');
                  }
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -8, end: 8, step: 0.25 })}
                name="right.near.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="right.near.axis"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={NEARVISIONSPECS} name="right.near.vs" />
            </td>
            <td className="border"></td>
            <td></td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -24, end: 24, step: 0.25 })}
                name="left.near.sph"
                onChange={selectedOption => {
                  const addValue = _.at(formik.values, 'left.distance.add')[0];
                  const distanceValue = _.at(formik.values, 'left.distance.sph');
                  if (distanceValue) {
                    if (addValue) {
                      const newDistanceValue =
                        parseFloat(selectedOption.value) - parseFloat(addValue);

                      if (newDistanceValue > 24 || newDistanceValue < -24) {
                        alert('max value reached');
                      } else {
                        const distanceValString =
                          newDistanceValue > 0
                            ? '+' + newDistanceValue.toFixed(2).toString()
                            : newDistanceValue.toFixed(2).toString();
                        formik.setFieldValue('left.distance.sph', distanceValString);
                        formik.setFieldValue('left.near.sph', selectedOption.value);
                      }
                    } else {
                      const newAddValue =
                        parseFloat(selectedOption.value) - parseFloat(distanceValue);

                      if (newAddValue > 3) {
                        alert('max value reached');
                      } else {
                        const addValueString = '+' + newAddValue.toFixed(2).toString();

                        formik.setFieldValue('left.distance.add', addValueString);
                        formik.setFieldValue('left.near.sph', selectedOption.value);
                      }
                    }
                  } else {
                    alert('distance value is required to compute addition');
                  }
                }}
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({ start: -8, end: 8, step: 0.25 })}
                name="left.near.cyl"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow
                formik={formik}
                options={generateRx({
                  start: 0,
                  end: 180,
                  step: 5,
                  showDecimal: false,
                })}
                name="left.near.axis"
              />
            </td>
            <td className="max-w-[70px] border p-2">
              <RxRow formik={formik} options={NEARVISIONSPECS} name="left.near.vs" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full gap-2 flex mt-2 border p-2">
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Psm(R)</label>
          <FormikInputGroup formik={formik} name="right.psm" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Pd(R)</label>

          <FormikInputGroup formik={formik} name="right.pd" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Fh(R)</label>

          <FormikInputGroup formik={formik} name="right.fh" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">IPD</label>

          <FormikInputGroup formik={formik} name="ipd" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Psm(L)</label>

          <FormikInputGroup formik={formik} name="left.psm" />
        </div>

        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Pd(L)</label>

          <FormikInputGroup formik={formik} name="left.pd" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Fh(L)</label>

          <FormikInputGroup formik={formik} name="left.fh" />
        </div>
      </div>
      <div className="w-full gap-2 flex mt-2 border p-2">
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Asize</label>
          <FormikInputGroup formik={formik} name="aSize" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Bsize</label>

          <FormikInputGroup formik={formik} name="bSize" />
        </div>

        <div className="grow flex gap-1 items-center">
          <label className="text-xs">DBL</label>

          <FormikInputGroup formik={formik} name="dbl" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Fth</label>

          <FormikInputGroup formik={formik} name="fth" />
        </div>

        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Pdesign</label>

          <FormikInputGroup formik={formik} name="pDesign" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">Ftype</label>

          <FormikInputGroup formik={formik} name="ft" />
        </div>
        <div className="grow flex gap-1 items-center">
          <label className="text-xs">DE</label>

          <FormikInputGroup formik={formik} name="de" />
        </div>
      </div>
    </>
  );
};

export default SpecsPrescriptionTable;
