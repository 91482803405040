import React from "react";
import FormikSelectGroup from "../formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import FormikAsyncSelect from "../formik/FormikAsyncSelect";
import { FieldArray, FormikProvider } from "formik";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikInputGroup from "../formik/FormikInputGroup";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import FormikDirectFileUpload from "../formik/FormikDirectFileUpload";
import DangerButton from "../infrastructure/Buttons/DangerButton";

const ImageSlider = ({ index, formik, edit = false }) => {
  console.log("product", formik);
  return (
    <div>
      <FormikInputGroup
        name={`content.${index}.component.name`}
        label="Name"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />
      <div className="flex gap-4">
        <FormikInputGroup
          name={`content.${index}.component.desktopImagePerSlide`}
          label="Desktop Image Per Slide"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.mobileImagePerSlide`}
          label="Mobile Image Per Slider"
          formik={formik}
        />
      </div>
      <div className="flex gap-4">
        <FormikInputGroup
          name={`content.${index}.component.horizontalPaddingDesktop`}
          label="Horizontal Padding Desktop"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.horizontalPaddingMobile`}
          label="Horizontal Padding Mobile"
          formik={formik}
        />
      </div>
      <div className="flex  gap-4">
        <FormikInputGroup
          name={`content.${index}.component.verticalPaddingDesktop`}
          label="Vertical Padding Desktop"
          formik={formik}
        />
        <FormikInputGroup
          name={`content.${index}.component.verticalPaddingMobile`}
          label="Vertical Padding Mobile"
          formik={formik}
        />
      </div>
      <FormikProvider value={formik}>
        <FieldArray
          name={`content.${index}.component.photos`}
          render={(arrayHelpers) => (
            <>
              {formik?.values?.content?.[index]?.component?.photos?.map(
                (photo, i) => (
                  <div className="grid grid-cols-3 gap-3" key={i}>
                    <FormikDirectFileUpload
                      name={`content.${index}.component.photos.${i}.desktopImage`}
                      formik={formik}
                      label={`desktopImage ${i + 1}`}
                      location={`eyesdeal/website/imageSlider/images/`}
                      randomize={true}
                    />
                    <FormikDirectFileUpload
                      name={`content.${index}.component.photos.${i}.mobileImage`}
                      formik={formik}
                      label={`mobileImage ${i + 1}`}
                      location={`eyesdeal/website/imageSlider/images/`}
                      randomize={true}
                    />
                    <FormikDirectFileUpload
                      name={`content.${index}.component.photos.${i}.desktopInternalPageImage`}
                      formik={formik}
                      label={`Desktop Internal Page Image ${i + 1}`}
                      location={`eyesdeal/website/imageSlider/images/`}
                      randomize={true}
                    />
                    <FormikDirectFileUpload
                      name={`content.${index}.component.photos.${i}.mobileInternalPageImage`}
                      formik={formik}
                      label={`Mobile Internal Page Image ${i + 1}`}
                      location={`eyesdeal/website/imageSlider/images/`}
                      randomize={true}
                    />
                    <FormikInputGroup
                      name={`content.${index}.component.photos.${i}.link`}
                      formik={formik}
                      label={`Link ${i + 1}`}
                    />
                    <div className="flex items-center">
                      <DangerButton
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      >
                        Remove
                      </DangerButton>
                    </div>
                  </div>
                )
              )}
              <div className="my-2 flex gap-4">
                <SecondaryButton onClick={() => arrayHelpers.push()}>
                  Add More Photos
                </SecondaryButton>
                {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
              </div>
            </>
          )}
        />
      </FormikProvider>
    </div>
  );
};

export default ImageSlider;
