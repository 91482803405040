import React from "react";
import FormikSelectGroup from "../formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import FormikAsyncSelect from "../formik/FormikAsyncSelect";
import { FieldArray, FormikProvider } from "formik";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikInputGroup from "../formik/FormikInputGroup";

const ProductSlider = ({ index, formik, edit = false }) => {
  console.log("product", formik);
  return (
    <div>
      <FormikInputGroup
        name={`content.${index}.component.heading`}
        label="Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.desktopImagePerSlide`}
        label="Desktop Image Per Slide"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.mobileImagePerSlide`}
        label="Mobile Image Per Slider"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.link`}
        label="Related Product link"
        formik={formik}
      />
      <FormikProvider value={formik}>
        <FieldArray
          name={`content.${index}.component.products`}
          render={(arrayHelpers) => (
            <>
              {formik?.values?.content?.[index]?.component?.products?.map(
                (element, i) => (
                  <div>
                    <FormikAsyncSelect
                      key={i}
                      formik={formik}
                      label="product"
                      // name={`content.${index}.products.${i}.product`}
                      name={`content.${index}.component.products.${i}`}
                      getOptions={async (value) => {
                        console.log("getOptions", value);
                        const query = {
                          search: value,
                          activeInWebsite: true,
                        };
                        const string = QueryString.stringify(query);
                        const products = await authAxiosInstance.get(
                          `products/product?${string}`
                        );

                        const options = products.data.data.docs?.map((ele) => {
                          const barcode = ele?.oldBarcode ?? ele?.newBarcode;
                          return {
                            label: `${barcode}/${ele.sku}`,
                            value: ele._id,
                          };
                        });
                        return options;
                      }}
                    />
                  </div>
                )
              )}
              <div className="my-2 flex gap-2">
                <PrimaryButton
                  onClick={() => {
                    arrayHelpers.push("");
                  }}
                >
                  {" "}
                  Add product
                </PrimaryButton>
                {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
              </div>
            </>
          )}
        />
      </FormikProvider>
    </div>
  );
};

export default ProductSlider;
