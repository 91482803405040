export const SPECSFIELDS = {
  __t: 'specs',
  doctorName: '',
  relation: '',
  gender: '',
  prescribedBy: '',
  ipd: '',
  aSize: '',
  bSize: '',
  dbl: '',
  fth: '',
  pDesign: '',
  ft: '',
  de: '',
  pres_id: 'new',
  right: {
    psm: '',
    pd: '',
    fh: '',
    distance: {
      add: '',
      sph: '',
      cyl: '',
      axis: '',
      vs: '',
    },
    near: {
      sph: '',
      cyl: '',
      axis: '',
      vs: '',
    },
  },
  left: {
    psm: '',
    pd: '',
    fh: '',
    distance: {
      add: '',
      sph: '',
      cyl: '',
      axis: '',
      vs: '',
    },
    near: {
      sph: '',
      cyl: '',
      axis: '',
      vs: '',
    },
  },
};
