import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editPage,
  fetchPage,
  fetchPages,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import { useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  fetchLensCategory,
  getLensCategory,
  resetEditData,
  updateLensCategory,
} from "../../app/reducers/LensCategory/lensCategorySlice";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const EditCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchLensCategory({ _id: id, populate: true }));
    return () => {
      dispatch(resetEditData());
    };
  }, [id]);

  const { editDataLoading, elementEditData } = useSelector(getLensCategory);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: elementEditData,
    onSubmit: async (values, { resetForm }) => {
      const data = await dispatch(updateLensCategory(values));
      dispatch(fetchLensCategory({ _id: id, populate: true }));
    },
  });

  return (
    <PageWithCard heading="Edit Lens Category">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-4 mb-3"
          >
            <FormikInputGroup
              formik={formik}
              name="heading"
              label="Heading"
              required
            />
            <FormikInputGroup
              formik={formik}
              name="sub_heading"
              label="Sub Heading"
              required
            />
            <FormikInputGroup
              formik={formik}
              name="websiteOrder"
              label="Website Order"
              required
            />
            {formik.values?.image && (
              <FormikDirectFileUpload
                name={`image`}
                formik={formik}
                label={`Image`}
                location={`eyesdeal/website/lens/category/`}
                randomize={true}
              />
            )}
            <div>
              <PrimaryButton type="submit">submit</PrimaryButton>
            </div>
          </form>
        </div>
      )}
    </PageWithCard>
  );
};

export default EditCategory;
