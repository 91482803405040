import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "stores";

export const createStore = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchStores = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const updateStore = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteStore = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchStore = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
const storeSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createStore , SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchStores, SLICE_NAME),
    ...editData.generateExtraReducers(updateStore, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteStore, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStore, SLICE_NAME),
  },
});

export const getStores = (state) => state.stores;
export default storeSlice.reducer;
