import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import qs from 'qs';
import { addPrescription } from './prescriptionSlice';
// import { addPrescription } from "./prescriptionSlice";
// import { resetData } from "../../generators/generateReducers";

const initialState = {
  loading: false,
  editUserLoading: false,
  error: null,
  users: {
    docs: [],
    paginationData: {
      page: '1',
      limit: '100',
    },
  },
  editUserId: '',
  editUserData: null,
};

export const addCustomer = createAsyncThunk('users/addUser', async data => {
  try {
    const { showPopup = true, ...ele } = data;
    const resp = await authAxiosInstance.post('user/register', ele);
    if (showPopup) {
      toast.success('user created');
    }
    return resp.data;
  } catch (error) {
    console.error(error);
    toast.error('failed to create user');
  }
});
export const fetchUsers = createAsyncThunk('users/get-details', async (data, { getState }) => {
  try {
    console.log(data);
    const state = getState();
    const pagination = state.users.users.paginationData;

    const string = qs.stringify(data);
    console.log({ ...data, pagination });
    const res = await authAxiosInstance.get(`user/list?${string}`);

    console.log(res);
    return res.data;
  } catch (error) {
    toast.error('failed to fetch users');
  }
});
export const deleteUser = createAsyncThunk('users/deleteUser', async (data, { dispatch }) => {
  try {
    const confirm = window.confirm(
      'are you sure you want to delete this user, deleting user will delete all prescriptions associated with it'
    );
    if (confirm) {
      const resp = await authAxiosInstance.delete(`user/remove/${data.id}`);
      toast.success('user deleted');
      dispatch(fetchUsers(data.fetchParameters));
    }
  } catch (error) {
    console.error(error);
    toast.error('error deleting user');
  }
});
export const getEditUserData = createAsyncThunk(
  'users/getEditUserData',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const string = qs.stringify({ populate: true, _id: data });
      const resp = await authAxiosInstance(`user/list?${string}`);
      const prescriptions = resp.data.data.docs[0].prescriptions;
      prescriptions.forEach(prescription => {
        dispatch(addPrescription({ ...prescription, pres_id: prescription._id }));
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
export const editUser = createAsyncThunk('users/editUser', async data => {
  try {
    const resp = await authAxiosInstance.patch('user/update', data);
    if (resp.data.success) {
      toast.success('user edited');
      return resp.data;
    } else {
      console.log(resp);
      toast.error('failed to edit user');
    }
  } catch (error) {
    console.error(error);
    toast.error('failed to edit user');
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setEditUserId: (state, action) => {
      state.editUserId = action.payload;
    },
    resetUsers: initialState,
  },
  extraReducers: {
    [addCustomer.pending]: state => {
      state.loading = true;
    },
    [addCustomer.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addCustomer.rejected]: state => {
      state.loading = false;
    },
    [fetchUsers.pending]: state => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      const { docs, ...paginationData } = action.payload.data;
      state.users.docs = docs;
      state.users.paginationData = paginationData;
      state.loading = false;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.loading = false;
    },
    [getEditUserData.pending]: (state, action) => {
      state.editUserLoading = true;
    },
    [getEditUserData.fulfilled]: (state, action) => {
      state.editUserData = action.payload.data.docs[0];
      state.editUserLoading = false;
    },
    [getEditUserData.rejected]: (state, action) => {
      state.editUserLoading = false;
    },
    [editUser.pending]: (state, action) => {
      state.editUserLoading = true;
    },
    [editUser.fulfilled]: (state, action) => {
      console.log(action.payload, 'actions');
      state.editUserData = null;
      state.editUserLoading = false;
      state.editUserId = '';
    },
    [editUser.rejected]: (state, action) => {
      state.editUserLoading = false;
    },
  },
});

export const getUsers = state => state.users;
export const { setEditUserId, resetUsers } = userSlice.actions;
export default userSlice.reducer;
