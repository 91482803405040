import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getAuth } from "../app/reducers/Auth/authSlice";

const RoleAccess = ({ roles = [] }) => {
  const { user } = useSelector(getAuth);

  return !roles.length || roles.includes(user?.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RoleAccess;
