import React, { useState } from 'react';

const Tabs = ({ headers = [], children, onTabChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  return (
    <>
      <div className="relative mb-8 mt-4">
        <div className="absolute bottom-0 w-full h-px bg-slate-200" aria-hidden="true"></div>
        <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
          {headers.map((ele, i) => (
            <React.Fragment key={i}>
              {activeTabIndex === i ? (
                <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                  <a
                    className="block pb-3 text-indigo-500 whitespace-nowrap border-b-2 border-indigo-500 cursor-pointer"
                    onClick={e => {
                      e.stopPropagation();
                      if (onTabChange) {
                        onTabChange(i);
                      }
                      setActiveTabIndex(i);
                    }}
                  >
                    {ele}
                  </a>
                </li>
              ) : (
                <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                  <a
                    className="block pb-3 text-slate-500 hover:text-slate-600 whitespace-nowrap cursor-pointer"
                    onClick={e => {
                      e.stopPropagation();
                      if (onTabChange) {
                        onTabChange(i);
                      }
                      setActiveTabIndex(i);
                    }}
                  >
                    {ele}
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
      {/* <div className="overflow-auto">
        {[].concat(children).filter((ele) => ele.type.name !== "Tab")}
        {
          [].concat(children).filter((ele) => ele.type.name === "Tab")[
            activeTabIndex
          ]
        }
      </div> */}
      <div className="overflow-auto">{[].concat(children)[activeTabIndex]}</div>
    </>
  );
};

export default Tabs;
