import React from "react";
import ModalBasic from "../infrastructure/Modals/ModalBasic";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikSelectGroup from "../formik/FormikSelectGroup";
import FormSelector from "../forms/FormSelector";
import { X } from "react-feather";
import { COMPONENTS_LIST } from "../../utils/dropdownOptions";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  editPage,
  fetchPage,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import { createComponent } from "../../app/reducers/Components/componentSlice";

const AddComponentModal = ({
  setAddNewComponentOpen,
  addNewComponentOpen,
  pageId,
}) => {
  const { editDataLoading, elementEditData } = useSelector(getPages);
  const existingComponent = elementEditData?.content
    ? elementEditData.content
    : [];
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: [],
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("values :", values);
      const pageDetails = {
        _id: pageId,
        content: [...existingComponent],
      };
      try {
        for (let i = 0; i < values.content.length; i++) {
          try {
            const component = values.content[i];
            const componentResp = await dispatch(createComponent(component));
            const componentId = componentResp.payload.data._id;
            pageDetails.content.push({
              name: component.name,
              component: componentId,
            });
          } catch (error) {
            // setErrors([...errors, JSON.stringify(error)]);
            console.log("addComponentModal onSubmit Error: ", error);
          }
        }
        dispatch(editPage(pageDetails))
          .then(() => {
            dispatch(fetchPage({ _id: pageId, populate: true }));
          })
          .catch((err) => {
            console.log("onSubmit .thenCatch Error: ", err);
          });
        setAddNewComponentOpen(false);
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={addNewComponentOpen}
      setModalOpen={setAddNewComponentOpen}
      ignoreClick
      max_width="max-w-4xl"
    >
      <div className="p-4 h-[500px]">
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <FieldArray
              name="content"
              render={(arrayHelpers) => (
                <>
                  <div>
                    {formik.values?.content.map((ele, index) => (
                      <div key={index}>
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                        <FormikSelectGroup
                          formik={formik}
                          label="Select Component"
                          name={`content.${index}.name`}
                          options={COMPONENTS_LIST}
                        />
                        {
                          <FormSelector
                            formik={formik}
                            component={formik.values.content[index].name}
                            index={index}
                          />
                        }
                      </div>
                    ))}
                    <div>
                      <PrimaryButton
                        className={"mt-4"}
                        onClick={() => {
                          arrayHelpers.push({
                            name: "",
                            component: {},
                          });
                        }}
                      >
                        Select Component
                      </PrimaryButton>
                      <PrimaryButton type="submit" className={"mx-2"}>
                        Submit
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
            />
          </FormikProvider>
        </form>
      </div>
    </ModalBasic>
  );
};

export default AddComponentModal;
