import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  fetchWebsiteOrder,
  fetchWebsiteOrders,
  getWebsiteOrders,
} from '../../app/reducers/websiteOrders/websiteOrderSlice';
import { fetchStores, getStores } from '../../app/reducers/Stores/storesSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import TableRaw from './TableRow';
import TableRow from './TableRow';

const TableHeader = ({ headers }) => {
  return (
    <thead className="w-full text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers.map((header, i) => (
          <th key={i} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words">
            <div className="font-semibold text-left break-words" style={header.style}>
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const cols = [
  { name: 'Barcode' },
  { name: 'Date' },
  { name: 'Photo' },
  { name: 'Sku' },
  { name: 'Brand' },
  { name: 'MRP' },
  { name: 'Stock' },
];
const ConvertToSale = () => {
  const { id } = useParams();
  // alert(id)
  const navigate = useNavigate();
  const [showInventory, setShowInventory] = useState(false);
  const dispatch = useDispatch();
  const { stores, loading } = useSelector(getStores);
  const { websiteOrder } = useSelector(getWebsiteOrders);
  const formik = useFormik({
    initialValues: {
      store: '',
    },
  });
  useEffect(() => {
    dispatch(fetchStores());
    dispatch(fetchWebsiteOrders({ _id: id }));
  }, []);
  console.log(websiteOrder);

  return (
    <PageWithCard heading="Convert To Sale">
      {loading ? (
        <ClipLoader />
      ) : (
        <div>
          <form className="flex flex-col gap-6">
            <FormikSelectGroup
              formik={formik}
              label="Store Name"
              name={'store'}
              options={
                stores.length > 0
                  ? stores?.map(store => {
                      return {
                        label: store?.name,
                        value: store?._id,
                      };
                    })
                  : []
              }
              required
            />
            {formik.values.store && (
              <div className="flex gap-6">
                <div onClick={() => [navigate(`/sale/${id}/${formik.values.store}`)]}>
                  <PrimaryButton>Sale</PrimaryButton>
                </div>
                <div
                  onClick={() => {
                    setShowInventory(true);
                  }}
                >
                  <PrimaryButton>View Inventory</PrimaryButton>
                </div>
              </div>
            )}
          </form>
          {showInventory && (
            <div>
              <table className="w-full mt-6">
                <TableHeader headers={cols} />
                <tbody>
                  {websiteOrder?.docs[0].productArr.map((el, i) => {
                    return (
                      <TableRow
                        key={i}
                        storeId={formik?.values?.store}
                        productId={el?.product?._id}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </PageWithCard>
  );
};

export default ConvertToSale;
