import React, { useEffect, useState } from "react";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { createPage, getPages } from "../../app/reducers/Page/pageSlice";
import { X } from "react-feather";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { COMPONENTS_LIST } from "../../utils/dropdownOptions";
import FormSelector from "../../components/forms/FormSelector";
import {
  createComponent,
  getComponents,
} from "../../app/reducers/Components/componentSlice";
import { ClipLoader } from "react-spinners";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const CreatePage = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const { loading: pageLoading } = useSelector(getPages);
  const { loading: componentLoading } = useSelector(getComponents);
  const formik = useFormik({
    initialValues: {
      name: "",
      content: [],
      seoTitle: "",
      seoDescription: "",
      seoImage: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const pageDetails = {
        ...values,
      };
      try {
        for (let i = 0; i < values.content.length; i++) {
          try {
            const component = values.content[i];
            const componentResp = await dispatch(createComponent(component));
            const componentId = componentResp.payload.data._id;
            pageDetails.content.push({
              name: component.name,
              component: componentId,
            });
          } catch (error) {
            setErrors([...errors, JSON.stringify(error)]);
          }
        }
        dispatch(createPage(pageDetails));
      } catch (error) {
        console.log(error);
      } finally {
        resetForm();
      }
    },
  });
  return (
    <PageWithCard heading="Create Page">
      {pageLoading || componentLoading || formik.isSubmitting ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="w-full mb-8 flex flex-col gap-4">
            <ShowDropdown allowedRoles={["admin"]} heading="Add Seo Details">
              <FormikInputGroup label="Title" name="seoTitle" formik={formik} />
              <FormikTextareaGroup
                label="Description"
                name="seoDescription"
                formik={formik}
              />
              <FormikDirectFileUpload
                name={`seoImage`}
                formik={formik}
                label={`Image `}
                location={`eyesdeal/website/image/seo/`}
                randomize={true}
              />
            </ShowDropdown>
          </div>
          <FormikInputGroup
            label="Page Name"
            name="name"
            formik={formik}
            required
          />

          <FormikProvider value={formik}>
            <FieldArray
              name="content"
              render={(arrayHelpers) => (
                <>
                  <div>
                    {formik.values.content.map((ele, index) => (
                      <div key={index}>
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                        <FormikSelectGroup
                          formik={formik}
                          label="Select Component"
                          name={`content.${index}.name`}
                          options={COMPONENTS_LIST}
                        />
                        {
                          <FormSelector
                            formik={formik}
                            component={formik.values.content[index].name}
                            index={index}
                          />
                        }
                      </div>
                    ))}
                    <div>
                      <PrimaryButton
                        onClick={() => {
                          arrayHelpers.push({
                            name: "",
                            component: {},
                          });
                        }}
                      >
                        {" "}
                        Add
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
            />
          </FormikProvider>

          <div>
            <PrimaryButton type="submit">submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default CreatePage;
