import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance } from "../../../utils/axiosConfig";
import { editData } from "../../generators/generateThunks";
import { toast } from "react-toastify";

const initialState = { loading: false };

export const createComponent = createAsyncThunk(
  "components/createComponent",
  async ({ name, component }, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.post(
        `/components/${name}`,
        component
      );
      toast.success(`${name} Component Created`);
      return resp.data;
    } catch (error) {
      console.log(error);
      toast.error(`failed to create ${name}`);
      return rejectWithValue(error);
    }
  }
);

export const editComponent = createAsyncThunk(
  "components/editComponent",
  async ({ name, component }, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch(
        `/components/${name}`,
        component
      );
      toast.success("Component Updated");
      return resp.data;
    } catch (error) {
      console.log(error);
      toast.error("failed to edit component");
    }
  }
);
export const deleteComponent = createAsyncThunk(
  "components/deleteComponent",
  async ({ name, component }, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.delete(
        `/components/${name}/${component}`
      );
      toast.success("Component deleted");
      return resp.data;
    } catch (error) {
      console.log(error);
      toast.error("failed to delete component");
    }
  }
);
const componentSlice = createSlice({
  name: "components",
  initialState,
  extraReducers: {
    [createComponent.pending]: (state) => {
      state.loading = true;
    },
    [createComponent.fulfilled]: (state) => {
      state.loading = false;
    },
    [createComponent.rejected]: (state) => {
      state.loading = false;
    },
    [editComponent.pending]: (state) => {
      state.loading = true;
    },
    [editComponent.fulfilled]: (state) => {
      state.loading = false;
    },
    [editComponent.rejected]: (state) => {
      state.loading = false;
    },
    [deleteComponent.pending]: (state) => {
      state.loading = true;
    },
    [deleteComponent.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteComponent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default componentSlice.reducer;
export const getComponents = (state) => state.components;
