import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Edit, Plus, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  fetchMarketingReferences,
  getMarketingRefrerences,
} from '../../app/reducers/Users/marketingReferenceSlice';
import {
  deletePrescriptionOnServer,
  getPresctiptions,
  openPrescriptionModal,
  removePrescription,
  resetPrescriptions,
  setEditPrescriptionId,
} from '../../app/reducers/Users/prescriptionSlice';
import { editUser, getEditUserData, getUsers } from '../../app/reducers/Users/userSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { GENDER } from '../../utils/dropdownOptions';
import { generateOptions } from '../../utils/Utils';
import SpecsPrescriptionModal from './SpecsPrescriptionModal';
import ContactsPrescriptionModal from './ContactsPrescriptionModal';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const EditUserForm = () => {
  const { editUserData, editUserLoading, editUserId } = useSelector(getUsers);
  const { loading: marketingReferenceLoading, references } = useSelector(getMarketingRefrerences);
  const { prescriptions, loading } = useSelector(getPresctiptions);
  const navigate = useNavigate();
  const { websiteOrderId, storeId } = useParams();
  const dispatch = useDispatch();
  const { ...userValues } = editUserData || {};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...userValues },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      phone: Yup.string().min(12).max(12).required(),

      marketingReference: Yup.string().required(),
      gender: Yup.string().required(),
    }),
    onSubmit: values => {
      dispatch(editUser({ ...values, prescriptions })).then(async resp => {
        console.log(resp, 'resp edit user');
        if (resp?.payload?.data?.role === 'customer' || resp?.payload?.data?.role === 'admin') {
          await dispatch(resetPrescriptions());

          if (resp?.payload?.data?._id) {
            setTimeout(() => {
              navigate(`/sale/${websiteOrderId}/${storeId}`);
            }, 200);
          }
        }
      });
    },
  });

  return (
    <>
      <SpecsPrescriptionModal initialFormik={formik} />
      <ContactsPrescriptionModal initialFormik={formik} />
      {editUserLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="flex gap-4 flex-wrap">
            <div className="grow">
              <FormikInputGroup formik={formik} label="Name" required name="name" />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="Phone" required name="phone" readOnly />
            </div>
            <div className="grow flex justify-center items-center">
              {marketingReferenceLoading ? (
                <ClipLoader />
              ) : (
                <FormikSelectGroup
                  formik={formik}
                  name="marketingReference"
                  options={generateOptions({
                    array: references,
                    labelField: 'name',
                    valueField: 'name',
                  })}
                  required
                  label="Customer Reference"
                />
              )}
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="grow flex justify-center items-center">
              <FormikSelectGroup
                formik={formik}
                name="gender"
                options={GENDER}
                required
                label="Gender"
              />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="Email" name="email" />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="Role" name="role" readOnly />
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="grow">
              <FormikInputGroup formik={formik} label="Country" name="country" />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="State" name="state" />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="City" name="city" />
            </div>
            <div className="grow">
              <FormikInputGroup formik={formik} label="Pincode" name="pincode" />
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="grow">
              <FormikInputDateGroup
                formik={formik}
                label="Birth Date"
                name="birthDate"
                showYearDropdown
                showMonthDropdown
              />
            </div>
            <div className="grow">
              <FormikInputDateGroup
                formik={formik}
                label="Anniversary Date"
                name="anniversaryDate"
                showYearDropdown
                showMonthDropdown
              />
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="grow">
              <FormikTextareaGroup formik={formik} label="Address" name="address" />
            </div>
          </div>
          <div className="flex">
            <div className="flex p-4 items-center justify-between w-full">
              <p className="text-base font-semibold">Add Power</p>
              <div className="flex gap-4 justify-center items-center">
                <PrimaryButton
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    dispatch(openPrescriptionModal('specs'));
                  }}
                >
                  <Plus size={16} />
                  <span className="ml-2">Add Specs Power</span>
                </PrimaryButton>
                <PrimaryButton
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    dispatch(openPrescriptionModal('contacts'));
                  }}
                >
                  <Plus size={16} />
                  <span className="ml-2">Add Contacts Power</span>
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="mt-2 w-full overflow-x-auto ">
            {loading ? (
              <ClipLoader />
            ) : (
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Date</th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Power Type</th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      Prescribed by
                    </th>

                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-slate-200">
                  {prescriptions.map(prescription => (
                    <tr key={prescription.pres_id}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center border border-1">
                        {moment(prescription.createdAt).format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center border border-1">
                        {prescription.__t}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center border border-1">
                        {prescription.doctorName} ({prescription.prescribedBy})
                      </td>

                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center border border-1">
                        <div className="flex items-center justify-center gap-2">
                          <Edit
                            color="blue"
                            size={18}
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(setEditPrescriptionId(prescription.pres_id));
                            }}
                          />
                          <Trash
                            color="red"
                            size={18}
                            onClick={() => {
                              if (prescription._id) {
                                dispatch(deletePrescriptionOnServer(prescription._id)).then(res => {
                                  dispatch(resetPrescriptions());
                                  dispatch(getEditUserData(editUserId));
                                });
                              } else {
                                dispatch(removePrescription(prescription.pres_id));
                              }
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <PrimaryButton className="mt-6" type="submit">
            Submit
          </PrimaryButton>
        </form>
      )}
    </>
  );
};

export default EditUserForm;
