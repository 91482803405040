import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  createLensPackage,
  getLensPackage,
} from "../../app/reducers/LensPackage/lensPackageSlice";
import { ClipLoader } from "react-spinners";
import { X } from "react-feather";
import * as Yup from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import {
  fetchLensCategories,
  getLensCategory,
} from "../../app/reducers/LensCategory/lensCategorySlice";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { GENDER, PRODUCT_TYPE } from "../../utils/dropdownOptions";
import {
  fetchFrameTypes,
  getFrameType,
} from "../../app/reducers/FrameType/frameTypeSlice";

export const LensPackage = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector(getLensPackage);
  const { lensCategory, loading: lensCategoryLoading } =
    useSelector(getLensCategory);

  const { frameType, loading: frameTypeLoading } = useSelector(getFrameType);

  useEffect(() => {
    dispatch(fetchLensCategories());
    dispatch(fetchFrameTypes());
  }, []);
  const lensCategoryData = useMemo(
    () => (lensCategory?.docs ? lensCategory.docs : []),
    [lensCategory]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      heading: "",
      sub_heading: "",
      product: "",
      sku: '',
      categoryOrder: 0,
      category: "",
      image: "",
      price: "",
      productType: [""],
      features: [""],
      gender: [""],
      frameType: [""],
      tax: null
    },
    validationSchema: Yup.object({
      heading: Yup.string().required(),
      sub_heading: Yup.string().required(),
      product: Yup.string().required(),
      sku: Yup.string().required(),
      category: Yup.string().required(),
      image: Yup.string(),
      price: Yup.number().required(),
      productType: Yup.array(),
      features: Yup.array(),
      gender: Yup.array(),
      frameType: Yup.array(),
      tax: Yup.number().required()
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        createLensPackage({ ...values, product: values.product.split("/")[0] })
      );
      resetForm();
    },
  });
  return (
    <PageWithCard heading="Create Lens Package">
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup
            formik={formik}
            name="heading"
            label="Heading"
            required
          />
          <FormikInputGroup
            formik={formik}
            name="sub_heading"
            label="Sub Heading"
            required
          />
          <FormikDirectFileUpload
            name={`image`}
            formik={formik}
            label={`Image`}
            location={`eyesdeal/website/lens/package/`}
            randomize={true}
          />
          <FormikAsyncSelect
            name={`product`}
            formik={formik}
            label="Product"
            onChange={(selectedOption) => {
              formik.setFieldValue("product", selectedOption.value);
              formik.setFieldValue("tax", selectedOption.tax);
              formik.setFieldValue("price", selectedOption.label.split("/")[2]);
              formik.setFieldValue("sku", selectedOption.label.split("/")[1]);
            }}
            getOptions={async (value) => {
              console.log("getOptions", value);
              const query = {
                search: value,
                __t: "spectacleLens",
              };
              const string = QueryString.stringify(query);
              const products = await authAxiosInstance.get(
                `products/product?${string}`
              );
              console.log("Products", products)
              const options = products.data.data.docs?.map((ele) => {
                const barcode = ele?.oldBarcode ?? ele?.newBarcode;
                return {
                  label: `${barcode}/${ele.sku}/${ele.MRP}`,
                  value: `${ele._id}`,
                  tax: ele.tax
                };
              });
              return options;
            }}
          />
          {
            formik.values.product && (
              <FormikInputGroup
                formik={formik}
                name={'sku'}
                label="Sku"
                readOnly
                required
              />

            )
          }
          {formik.values.tax && <FormikInputGroup
            formik={formik}
            label="Tax (%)"
            name={"tax"}
            type="number"           
          />}
          {formik.values.product && (
            <FormikInputGroup
              formik={formik}
              name="price"
              type="number"
              label="Price"
              required
            />
          )}
          <FormikSelectGroup
            formik={formik}
            name="category"
            label="Category"
            options={generateOptions({
              array: lensCategoryData,
              valueField: "_id",
              labelField: "heading",
            })}
          />
          <FormikMultiSelect
            formik={formik}
            name="gender"
            label="Gender"
            options={GENDER}
          />
          <FormikMultiSelect
            formik={formik}
            name="productType"
            label="Product type"
            options={PRODUCT_TYPE}
          />
          <FormikMultiSelect
            formik={formik}
            name="frameType"
            label="Frame type"
            options={generateOptions({
              array: frameType ? frameType : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
          <FormikInputGroup
            formik={formik}
            label="Category Order"
            name={'categoryOrder'}
            type="number"
          />

          <FormikProvider value={formik}>
            <h1 className="font-semibold">Features</h1>
            <FieldArray
              name="features"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-4 border rounded-lg p-5">
                    {formik?.values?.features?.map((ele, i) => (
                      <div key={i} className="flex w-full gap-3">
                        <div>
                          <X
                            onClick={() => {
                              arrayHelpers.remove(i);
                            }}
                          />
                        </div>
                        <div className="w-[80%]">
                          <FormikInputGroup
                            fullWidth={true}
                            name={`features.${i}`}
                            formik={formik}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="flex gap-4">
                      <PrimaryButton
                        type="button"
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                      >
                        Add More
                      </PrimaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};
