import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "lensCategory";

export const createLensCategory = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchLensCategories = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const updateLensCategory = editData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteLensCategory = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchLensCategory = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
const lensCategory = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    resetEditData: (state) => {
      state.elementEditId = null;
      state.editDataLoading = false;
      state.elementEditData = {};
    },
  },
  extraReducers: {
    ...createData.generateExtraReducers(createLensCategory, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchLensCategories, SLICE_NAME),
    ...editData.generateExtraReducers(updateLensCategory, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteLensCategory, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchLensCategory, SLICE_NAME),
  },
});

export const getLensCategory = (state) => state.lensCategory;
export const { resetEditData } = lensCategory.actions;
export default lensCategory.reducer;
