import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import QueryString from "qs";
import { toast } from "react-toastify";
import { authAxiosInstance } from "../../../utils/axiosConfig";
import { initialState } from "./attributesInitialState";

export const fetchAttributes = createAsyncThunk(
  "attributes/fetchAttributes",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.get(`master/${data.model}`);
      return resp.data;
    } catch (error) {
      console.error(error);

      toast.error("failed to fetch attributes");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
export const fetchAttribute = createAsyncThunk(
  "attributes/fetchAttribute",
  async (data, { rejectWithValue }) => {
    try {
      const string = QueryString.stringify({ _id: data._id });
      const resp = await authAxiosInstance.get(
        `master/${data.model}?${string}`
      );
      return resp.data;
    } catch (error) {
      console.error(error);

      toast.error("failed to fetch attribute");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
export const editAttribute = createAsyncThunk(
  "attributes/editAttribute",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      console.log(data);
      const resp = await authAxiosInstance.patch(`master/${data.model}`, data);
      dispatch(fetchAttributes({ model: data.model }));
      return resp.data;
    } catch (error) {
      console.error(error);

      toast.error("failed to edit attribute");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
export const createAttribute = createAsyncThunk(
  "attributes/createAttribute",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.post(`master/${data.model}`, data);
      return resp.data;
    } catch (error) {
      console.error(error);

      toast.error("failed to create attribute");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
export const deleteAttribute = createAsyncThunk(
  "attributes/deleteAttribute",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const confirm = window.confirm(
        "are you sure you want to delete this attribute"
      );
      if (confirm) {
        const resp = await authAxiosInstance.delete(
          `master/${data.model}/${data.id}`,
          data
        );
        dispatch(fetchAttribute({ model: data.model }));
        return resp.data;
      }
    } catch (error) {
      console.error(error);

      toast.error("failed to create attribute");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);

const attributesSlice = createSlice({
  name: "attributes",
  initialState: initialState,
  reducers: {
    setEditId: (state, action) => {
      state.attributesEditId[action.payload.model] = action.payload.id;
    },
  },
  extraReducers: {
    [fetchAttributes.pending]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = true;
    },
    [fetchAttributes.fulfilled]: (state, action) => {
      const { model } = action.meta.arg;

      state.attributes[model] = action.payload.data;
      state.attributesLoading[model] = false;
    },
    [fetchAttributes.rejected]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = false;
    },
    [fetchAttribute.pending]: (state, action) => {
      const { model } = action.meta.arg;
      state.editAttributeLoading[model] = true;
    },
    [fetchAttribute.fulfilled]: (state, action) => {
      const { model } = action.meta.arg;

      state.attributesEditData[model] = action.payload.data[0];
      state.editAttributeLoading[model] = false;
    },
    [fetchAttribute.rejected]: (state, action) => {
      const { model } = action.meta.arg;
      state.editAttributeLoading[model] = false;
    },
    [editAttribute.pending]: (state, action) => {
      const { model } = action.meta.arg;
      state.editAttributeLoading[model] = true;
    },
    [editAttribute.fulfilled]: (state, action) => {
      const { model } = action.meta.arg;

      state.attributesEditData[model] = null;
      state.attributesEditId[model] = null;
      state.editAttributeLoading[model] = false;
    },
    [editAttribute.rejected]: (state, action) => {
      const { model } = action.meta.arg;
      state.editAttributeLoading[model] = false;
    },
    [createAttribute.pending]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = true;
    },
    [createAttribute.fulfilled]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = false;
    },
    [createAttribute.rejected]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = false;
    },
    [deleteAttribute.pending]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = true;
    },
    [deleteAttribute.fulfilled]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = false;
    },
    [deleteAttribute.rejected]: (state, action) => {
      const { model } = action.meta.arg;
      state.attributesLoading[model] = false;
    },
  },
});

export const { setEditId } = attributesSlice.actions;
export default attributesSlice.reducer;
export const getAttributes = (state) => state.attributes;
