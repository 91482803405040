import React, { Fragment, useEffect, useState } from "react";
import PageWithCard from "../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWebsiteOrders,
  getWebsiteOrders,
  updateWebsiteOrder,
} from "../app/reducers/websiteOrders/websiteOrderSlice";
import moment from "moment";
import { ChevronDown, ChevronRight } from "react-feather";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router";
import { getMediaLibrary } from "../app/reducers/MediaLibrary/mediaLibrarySlice";
import Tabs from "../components/infrastructure/Tabs/Tabs";
import Tab from "../components/infrastructure/Tabs/Tab";
import { ClipLoader } from "react-spinners";

const TableHeader = ({ headers }) => {
  return (
    <thead className="w-full text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers.map((header, i) => (
          <th
            key={i}
            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words"
          >
            <div
              className="font-semibold text-left break-words"
              style={header.style}
            >
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const cols = [
  { name: "SNO" },
  { name: "Customer" },
  { name: "Phone" },
  { name: "Email" },
  { name: "Address" },
  { name: "Final Amount" },
  { name: "Type" },
  { name: "Date" },
  { name: "Status" },
  { name: "Convert To Sale" },
  { name: "prescription Image" },
  { name: "" },
];
const BASE_URL =
  "https://s3.ap-south-1.amazonaws.com/eyesdeal.blinklinksolutions.com/";
const Dashboard = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { websiteOrder, loading } = useSelector(getWebsiteOrders);
  const { baseUrl } = useSelector(getMediaLibrary);
  useEffect(() => {
    dispatch(
      fetchWebsiteOrders({
        populate: true,
        orderType: { $nin: ["canceled", "processed"] },
      })
    );
  }, []);

  const onTabChanged = (i) => {
    if (i == 0) {
      dispatch(
        fetchWebsiteOrders({
          populate: true,
          orderType: { $nin: ["canceled", "processed"] },
        })
      );
    } else if (i == 1) {
      dispatch(fetchWebsiteOrders({ populate: true, orderType: "canceled" }));
    } else if (i == 2) {
      dispatch(fetchWebsiteOrders({ populate: true, orderType: "processed" }));
    }
  };
  console.log(loading, "jkl");
  return (
    <PageWithCard heading="Website Orders">
      <div className="p-4">
        <Tabs
          headers={["Sale", "Cancel", "Processed"]}
          onTabChange={onTabChanged}
        >
          <Tab>
            <table className="w-full">
              <TableHeader headers={cols} />
              <tbody className="text-sm  divide-slate-200">
                {loading && <ClipLoader />}
                {!loading &&
                  websiteOrder?.docs?.map((ele, i) => (
                    <React.Fragment key={ele._id}>
                      <tr>
                        <td className="px-2 py-3">{i + 1}</td>
                        <td className="px-2 py-3">{ele.customerName}</td>
                        <td className="px-2 py-3">{ele.phone}</td>
                        <td className="px-2 py-3">{ele.user?.email}</td>
                        <td className="px-2 py-3 ">
                          {ele.address} , {ele.pincode}
                        </td>
                        <td className="px-2 py-3">{ele.finalAmount}</td>
                        <td className="px-2 py-3">{ele.type}</td>
                        <td className="px-2 py-3">
                          {moment(ele.createdAt).format("DD//MM/YYYY HH:mm")}
                        </td>
                        <td className="px-2 py-3">{ele.status}</td>

                        <td>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(`/convertToSale/${ele?._id}`);
                            }}
                          >
                            Sale
                          </div>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              let uptData = {
                                orderType: "canceled",
                                _id: ele._id,
                              };
                              await dispatch(updateWebsiteOrder(uptData));
                              await dispatch(
                                fetchWebsiteOrders({
                                  populate: true,
                                  orderType: {
                                    $nin: ["canceled", "processed"],
                                  },
                                })
                              );
                            }}
                          >
                            Cancel
                          </div>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              let uptData = {
                                orderType: "processed",
                                _id: ele._id,
                              };
                              await dispatch(updateWebsiteOrder(uptData));
                              await dispatch(
                                fetchWebsiteOrders({
                                  populate: true,
                                  orderType: {
                                    $nin: ["canceled", "processed"],
                                  },
                                })
                              );
                            }}
                          >
                            Process
                          </div>
                        </td>
                        {ele.prescriptionImage && (
                          <td className=" py-3">
                            <a href={`${BASE_URL}${ele.prescriptionImage}`}>
                              Download Image
                            </a>
                          </td>
                        )}
                        <td className="cursor-pointer">
                          {expandedRows.includes(ele._id) ? (
                            <ChevronDown
                              onClick={(e) => {
                                const data = expandedRows.filter(
                                  (item) => item !== ele._id
                                );
                                setExpandedRows(data);
                              }}
                            />
                          ) : (
                            <ChevronRight
                              onClick={(e) => {
                                setExpandedRows([...expandedRows, ele._id]);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {expandedRows.includes(ele._id) && (
                        <tr>
                          <td className="mt-4" colSpan={100}>
                            <div className=" pl-8 w-full">
                              <table className="w-full">
                                <thead className="text-left">
                                  <tr>
                                    <th>Product Sku</th>
                                    <th>Order Id</th>
                                    <th>Product Price</th>
                                    <th>Lens Sku</th>
                                    <th>Lens Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ele.productArr.map((order) => (
                                    <Fragment key={ele._id}>
                                      {
                                        <tr key={order._id}>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <a
                                              href={`https://eyesdeal.com/products/${order.product?.sku}`}
                                              target="noref"
                                            >
                                              {order.product?.sku}
                                            </a>
                                          </td>
                                          <td>{order._id}</td>
                                          <td>
                                            {"Rs. " + order.product?.sellPrice}
                                          </td>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {order.lens_package
                                              ? order.lens_package?.sku
                                              : "No lens package"}
                                          </td>

                                          <td>
                                            {order.lens_package
                                              ? "Rs. " +
                                                order.lens_package?.price
                                              : "No lens package"}
                                          </td>
                                        </tr>
                                      }
                                    </Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </Tab>
          <Tab>
            <table className="w-full">
              <TableHeader headers={cols} />
              <tbody className="text-sm  divide-slate-200">
                {loading && <ClipLoader />}
                {!loading &&
                  websiteOrder?.docs?.map((ele, i) => (
                    <React.Fragment key={ele._id}>
                      <tr>
                        <td className="px-2 py-3">{i + 1}</td>
                        <td className="px-2 py-3">{ele.customerName}</td>
                        <td className="px-2 py-3">{ele.phone}</td>
                        <td className="px-2 py-3">{ele.user?.email}</td>
                        <td className="px-2 py-3 ">
                          {ele.address} , {ele.pincode}
                        </td>
                        <td className="px-2 py-3">{ele.finalAmount}</td>
                        <td className="px-2 py-3">{ele.type}</td>
                        <td className="px-2 py-3">
                          {moment(ele.createdAt).format("DD//MM/YYYY HH:mm")}
                        </td>
                        <td className="px-2 py-3">{ele.status}</td>

                        <td>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              let uptData = {
                                orderType: "ordered",
                                _id: ele._id,
                              };
                              await dispatch(updateWebsiteOrder(uptData));
                              await dispatch(
                                fetchWebsiteOrders({
                                  populate: true,
                                  orderType: "canceled",
                                })
                              );
                            }}
                          >
                            Return to Sale
                          </div>
                        </td>
                        {ele.prescriptionImage && (
                          <td className=" py-3">
                            <a href={`${BASE_URL}${ele.prescriptionImage}`}>
                              Download Image
                            </a>
                          </td>
                        )}
                        <td className="cursor-pointer">
                          {expandedRows.includes(ele._id) ? (
                            <ChevronDown
                              onClick={(e) => {
                                const data = expandedRows.filter(
                                  (item) => item !== ele._id
                                );
                                setExpandedRows(data);
                              }}
                            />
                          ) : (
                            <ChevronRight
                              onClick={(e) => {
                                setExpandedRows([...expandedRows, ele._id]);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {expandedRows.includes(ele._id) && (
                        <tr>
                          <td className="mt-4" colSpan={100}>
                            <div className=" pl-8 w-full">
                              <table className="w-full">
                                <thead className="text-left">
                                  <tr>
                                    <th>Product Sku</th>
                                    <th>Order Id</th>
                                    <th>Product Price</th>
                                    <th>Lens Sku</th>
                                    <th>Lens Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ele.productArr.map((order) => (
                                    <Fragment key={ele._id}>
                                      {
                                        <tr key={order._id}>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <a
                                              href={`https://eyesdeal.com/products/${order.product?.sku}`}
                                              target="noref"
                                            >
                                              {order.product?.sku}
                                            </a>
                                          </td>
                                          <td>{order._id}</td>
                                          <td>
                                            {"Rs. " + order.product?.sellPrice}
                                          </td>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {order.lens_package
                                              ? order.lens_package?.sku
                                              : "No lens package"}
                                          </td>

                                          <td>
                                            {order.lens_package
                                              ? "Rs. " +
                                                order.lens_package?.price
                                              : "No lens package"}
                                          </td>
                                        </tr>
                                      }
                                    </Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </Tab>
          <Tab>
            <table className="w-full">
              <TableHeader headers={cols} />
              <tbody className="text-sm  divide-slate-200">
                {loading && <ClipLoader />}
                {!loading &&
                  websiteOrder?.docs?.map((ele, i) => (
                    <React.Fragment key={ele._id}>
                      <tr>
                        <td className="px-2 py-3">{i + 1}</td>
                        <td className="px-2 py-3">{ele.customerName}</td>
                        <td className="px-2 py-3">{ele.phone}</td>
                        <td className="px-2 py-3">{ele.user?.email}</td>
                        <td className="px-2 py-3 ">
                          {ele.address} , {ele.pincode}
                        </td>
                        <td className="px-2 py-3">{ele.finalAmount}</td>
                        <td className="px-2 py-3">{ele.type}</td>
                        <td className="px-2 py-3">
                          {moment(ele.createdAt).format("DD//MM/YYYY HH:mm")}
                        </td>
                        <td className="px-2 py-3">{ele.status}</td>

                        <td>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={async (e) => {
                              e.stopPropagation();
                              let uptData = {
                                orderType: "ordered",
                                _id: ele._id,
                              };
                              await dispatch(updateWebsiteOrder(uptData));
                              await dispatch(
                                fetchWebsiteOrders({
                                  populate: true,
                                  orderType: "canceled",
                                })
                              );
                            }}
                          >
                            Return to Sale
                          </div>
                        </td>
                        {ele.prescriptionImage && (
                          <td className=" py-3">
                            <a href={`${BASE_URL}${ele.prescriptionImage}`}>
                              Download Image
                            </a>
                          </td>
                        )}
                        <td className="cursor-pointer">
                          {expandedRows.includes(ele._id) ? (
                            <ChevronDown
                              onClick={(e) => {
                                const data = expandedRows.filter(
                                  (item) => item !== ele._id
                                );
                                setExpandedRows(data);
                              }}
                            />
                          ) : (
                            <ChevronRight
                              onClick={(e) => {
                                setExpandedRows([...expandedRows, ele._id]);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {expandedRows.includes(ele._id) && (
                        <tr>
                          <td className="mt-4" colSpan={100}>
                            <div className=" pl-8 w-full">
                              <table className="w-full">
                                <thead className="text-left">
                                  <tr>
                                    <th>Product Sku</th>
                                    <th>Order Id</th>
                                    <th>Product Price</th>
                                    <th>Lens Sku</th>
                                    <th>Lens Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ele.productArr.map((order) => (
                                    <Fragment key={ele._id}>
                                      {
                                        <tr key={order._id}>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <a
                                              href={`https://eyesdeal.com/products/${order.product?.sku}`}
                                              target="noref"
                                            >
                                              {order.product?.sku}
                                            </a>
                                          </td>
                                          <td>{order._id}</td>
                                          <td>
                                            {"Rs. " + order.product?.sellPrice}
                                          </td>
                                          <td
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {order.lens_package
                                              ? order.lens_package?.sku
                                              : "No lens package"}
                                          </td>

                                          <td>
                                            {order.lens_package
                                              ? "Rs. " +
                                                order.lens_package?.price
                                              : "No lens package"}
                                          </td>
                                        </tr>
                                      }
                                    </Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </PageWithCard>
  );
};

export default Dashboard;
