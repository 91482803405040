import React, { useMemo } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Edit, Edit2, Trash } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { deleteLensPackage, fetchLensPackages, getLensPackage } from "../../app/reducers/LensPackage/lensPackageSlice";
import { fetchAssets, getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { useState } from "react";

export const ViewLensPackage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lensPackage, loading } = useSelector(getLensPackage);
    const { baseUrl } = useSelector(getMediaLibrary);
    const location = useLocation().search
    const searchParams = new URLSearchParams(location)
    const [isLoading, setIsLoading] = useState(false)
    
    let category = searchParams.get('category')

    useEffect(() => {
        dispatch(fetchAssets({ currentFolder: '' }));
    },[])

    useEffect(() => {
        let data={}
        if(category) {
            data['category.heading'] = category
        }
        dispatch(fetchLensPackages({ populate: true,...data }));
    }, [category]);

    const data = useMemo(
        () => (lensPackage?.docs ? lensPackage?.docs : []),
        [lensPackage]
    );

    const cols = [
        {
            Header: "SRNO",
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: "Heading",
            accessor: "heading",
        },
        {
            Header: "Image",
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original.image && 
                            <img src={baseUrl+row.original.image} alt="Logo" height={100} width={100} />
                        }
                    </>
                );
            },
        },
        {
            Header: "Sub Heading",
            accessor: "sub_heading",
        },
        {
            Header: "Category Order",
            accessor: "categoryOrder",
        },
        {
            Header: "Price",
            accessor: "price",
        },
        {
            Header: "Category",
            Cell: ({ row }) => {
                return (
                    <div className="underline text-cyan-600 cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/lens/edit/category/${row.original?.category?._id}`)
                    }} >{row.original?.category?.heading}</div>
                );
            },
        },
        {
            Header: "Action",
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <Edit2 onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/lens/edit/package/${row.original?._id}`)
                        }} />
                        <Trash color="red" onClick={async(e) => {
                            e.stopPropagation();
                            setIsLoading(true);
                            await dispatch(deleteLensPackage({ id: row.original?._id }))
                            await dispatch(fetchLensPackages())
                            setIsLoading(false);
                        }} />
                    </div>
                );
            },
        },
    ];
    const columnsMemo = useMemo(() => cols, [cols]);
console.log(lensPackage,"svfdksdjh")
    return (
        <PageWithCard heading="View Lens Package">
            <div className="mb-5">
                {category &&
                    <h2 className="font-semibold text-2xl">Category:- <span className="font-bold text-2xl">{category}</span></h2>
                }
            </div>
            {loading || isLoading ? (
                <ClipLoader />
            ) : (
                <TableWithHeadingAndSearch
                    data={data}
                    columns={columnsMemo}
                    heading="View Lens Packages"
                />
            )}
        </PageWithCard>
    );
};
