import { useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { Trash } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import MediaLibraryAssets from '../MediaLibrary/MediaLibraryAssets';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { toast } from 'react-toastify';
import { deleteCoupon, fetchCoupons, getCoupons } from '../../app/reducers/Coupon/couponSlice';
import moment from 'moment';
import { CouponEditForm } from './CouponEditForm';

export const ViewCoupons = () => {
  const { coupon, loading } = useSelector(getCoupons);
  const { baseUrl } = useSelector(getMediaLibrary);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [singleCouponData, setSingleCouponData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCoupons({ __t: 'product' }));
  }, []);

  const data = useMemo(() => (coupon?.docs ? coupon?.docs : []), [coupon]);

  const handleEditCoupon = (e, storeFrontData) => {
    e.stopPropagation();
    setSingleCouponData(storeFrontData);
    setEditModalOpen(true);
  };

  const cols = [
    {
      Header: 'Photo',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.photo && (
              <img src={baseUrl + row.original.photo} height={70} width={100} />
            )}
          </div>
        );
      },
    },
    {
      Header: 'Coupon Code',
      accessor: 'code',
    },
    {
      Header: 'Valid Till',
      Cell: ({ row }) => {
        return <div>{moment(row.original.expiryDate).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      Header: 'Max Use',
      accessor: 'maxUse',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton onClick={e => handleEditCoupon(e, row?.original)} type="button">
              Edit Coupon
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setIsLoading(true);
                navigate(`/coupon/viewCoupon/${row.original._id}`);
                setIsLoading(false);
              }}
            >
              Edit Coupon Product
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                navigate(`/coupon/editCouponBulkUpload/${row.original._id}`);
              }}
            >
              Edit Coupon Upload
            </PrimaryButton>
            <Trash
              onClick={async () => {
                setIsLoading(true);
                await dispatch(deleteCoupon({ id: row.original._id }));
                dispatch(fetchCoupons());
                setIsLoading(false);
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="View Coupon">
      <MediaLibraryAssets />
      <CouponEditForm
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        data={singleCouponData}
      />
      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch data={data} columns={cols} heading="View Coupon" />
      )}
    </PageWithCard>
  );
};
