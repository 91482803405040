import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';

const CartAnalysis = () => {
  const [page, setPage] = useState(1);
  const [cartAnalysisData, setCartAnalysisData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { baseUrl } = useSelector(getMediaLibrary);

  useEffect(() => {
    getCartAnalysisData();
  }, [page]);

  const formik = useFormik({
    initialValues: {
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        let filterData = {
          createdAt: {
            $gt: values?.from,
            $lt: values?.to,
          },
          page,
        };
        const string = QueryString.stringify(filterData);
        const cartAnalysisResp = await authAxiosInstance.get(`/cart/cartAnalysis?${string}`);
        if (cartAnalysisData) {
          const cartData = cartAnalysisResp?.data?.data;

          let { docs, ...rest } = cartData;

          const filteredDataByStatus = docs?.filter(el => el.status !== 'ordered');

          if (filteredDataByStatus.length > 0) {
            setCartAnalysisData({ ...rest, docs: filteredDataByStatus });
          } else {
            setCartAnalysisData({});
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error finding Data');
      } finally {
        setIsLoading(false);
      }
    },
  });
  const getCartAnalysisData = async () => {
    try {
      setIsLoading(true);
      let filterData = {
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        page,
      };
      const string = QueryString.stringify(filterData);
      const cartAnalysisResp = await authAxiosInstance.get(`/cart/cartAnalysis?${string}`);
      if (cartAnalysisData) {
        const cartData = cartAnalysisResp?.data?.data;
        let { docs, ...rest } = cartData;

        const filteredDataByStatus = docs?.filter(el => el.status !== 'ordered');

        if (filteredDataByStatus.length > 0) {
          setCartAnalysisData({ ...rest, docs: filteredDataByStatus });
        } else {
          setCartAnalysisData({});
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error('Error finding Data');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'SRNo.',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Data',
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Order Type',
      accessor: 'order_type',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'User Details',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-2 w-[370px]">
            {row?.original?.user ? (
              <div className="flex flex-col gap-1 justify-left w-full p-1">
                <div className="flex flex-row gap-2 ">
                  <p className="text text-base font-semibold">Customer</p>
                  <p className="text text-blue-600">{row?.original?.user?.name}</p>
                </div>
                <div className="flex flex-row gap-2 ">
                  <p className="text text-base font-semibold">Phone</p>
                  <p className="text text-blue-600">+{row?.original?.user?.phone}</p>
                </div>
                <div className="flex flex-row gap-2 justify-left w-full">
                  <p className="text text-base font-semibold">Address</p>
                  <p className="text text-blue-600 whitespace-pre-wrap">
                    {row?.original?.user?.address}
                  </p>
                </div>
              </div>
            ) : (
              <p className="text text-blue-600">No User Found</p>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Cart Details',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-2">
            {row?.original?.cart_item?.map((el, i) => (
              <div key={el._id} className="flex flex-row gap-6 justify-left border p-2 ">
                <div className="flex flex-col gap-2 justify-center items-center">
                  <p className="text text-base font-semibold">{`${i + 1}.Photo`}</p>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={`${baseUrl}${el?.product?.photos[0]}`}
                      style={{ width: '50px', height: '50px' }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 justify-center items-center">
                  <p className="text text-base font-semibold">{`${i + 1}.Product Sku`}</p>
                  <p className="text text-blue-600">{el?.product?.sku}</p>
                </div>
                <div className="flex flex-col gap-2 justify-center items-center">
                  <p className="text text-base font-semibold">{`${i + 1}.Product Cost Price`}</p>
                  <p className="text text-blue-600">{el?.product?.sellPrice}</p>
                </div>
                {el?.lens_package && (
                  <>
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <p className="text text-base font-semibold">{`${i + 1}.Lens Price`}</p>
                      <p className="text text-blue-600">{el?.lens_package?.price}</p>
                    </div>
                    <div className="flex flex-col gap-2 justify-center items-center ">
                      <p className="text text-base font-semibold">{`${i + 1}.Lens Sku`}</p>
                      <p className="text text-blue-600">{el?.lens_package?.sku}</p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const columnMemoizationData = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(() => cartAnalysisData?.docs ?? [], [cartAnalysisData]);

  return (
    <PageWithCard heading="Cart Analysis">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
      {isLoading ? (
        <ClipLoader />
      ) : (
        <div className="mt-3">
          <TableWithHeadingAndSearch
            heading="cart Analysis Data"
            columns={columnMemoizationData}
            data={dataMemoization}
          />
        </div>
      )}
      <PaginationClassic paginationDetails={cartAnalysisData} setPage={setPage} />
    </PageWithCard>
  );
};

export default CartAnalysis;
