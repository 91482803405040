import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchFilters, getWebsite } from '../../app/reducers/website/websiteSlice'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { ClipLoader } from 'react-spinners'
import { useFormik } from 'formik'
import FormikMultiSelect from '../../components/formik/FormikMultiSelect'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import QueryString from 'qs'
import { toast } from 'react-toastify'

function generateInitialValues(arr) {
    let result = {}

    Object.entries(arr).forEach((el) => {
        if (el[1].docs?.length > 0) {
            result[el[0]] = [];
        }
    });
    console.log(result)
    return result
}
const LinkQuery = () => {
    const { type } = useParams()
    const dispatch = useDispatch()
    const { website, loading } = useSelector(getWebsite)

    useEffect(() => {
        dispatch(fetchFilters({ populate: true, ...(type !== "all" ? { __t: type } : {}) }));

    }, [])
    console.log("🚀 ~ file: LinkQuery.jsx:11 ~ LinkQuery ~ website:", website)
    const formik = useFormik({
        initialValues: { ...generateInitialValues(website) },
        onSubmit:(values)=>{
            let query=QueryString.stringify(values)
            
            navigator.clipboard.writeText(`https://eyesdeal.com/products${type !== "all" ? `/type/${type}` : ""}?${query}`)
            toast.success("link copied !")
        }
      
    })
   
    return (
        <PageWithCard
            heading='Add Query'
        >
            {
                loading ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={formik.handleSubmit} className='flex flex-col gap-6'>
                        {
                            Object.entries(website)?.map((el,i) => {
                                return el[1]?.docs?.length>0 && <FormikMultiSelect key={i}
                                    formik={formik}
                                    name={el[0]}
                                    label={el[0].toLocaleUpperCase()}
                                    options={el?.[1]?.docs?.map((filter) => {
                                        return {
                                            label: filter?.name,
                                            value: filter?._id
                                        }
                                    }) ?? []}
                                />
                            })
                        }
                        <div>
                            <PrimaryButton type='submit'>Copy Link</PrimaryButton>
                        </div>
                    </form>
                )

            }
        </PageWithCard>
    )
}

export default LinkQuery