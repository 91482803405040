import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "products";

export const createProduct = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProducts = fetchData.generateThunk(
  SLICE_NAME,
  "products/product"
);
export const fetchProduct = fetchEditData.generateThunk(SLICE_NAME, "products/product");
export const editProduct = editData.generateThunk(SLICE_NAME, "products/product");
export const deleteProduct = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const productSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createProduct, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchProducts, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProduct, SLICE_NAME),
    ...editData.generateExtraReducers(editProduct, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProduct, SLICE_NAME),
  },
});

export const getProducts = (state) => state.products;
export default productSlice.reducer;
