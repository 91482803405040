import { useSelector } from "react-redux";

import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";

const ViewProductsModal = ({
    isViewProductModalOpen,
    setIsViewProductModalOpen,
    selectedProduct,
}) => {
    console.log(selectedProduct, "selectedProduct");
    return (
        <ModalBasic
            modalOpen={isViewProductModalOpen}
            setModalOpen={setIsViewProductModalOpen}
        >
            <div className="grid grid-cols-3 pt-0 pb-3 pl-4 pr-4 rounded-2xl">
                {selectedProduct?.__t && (
                    <div className="p-4 border border-grey m-1 rounded-sm text-sm">
                        Category : {selectedProduct?.__t}
                    </div>
                )}

                {selectedProduct?.colorNumber && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Color Number : {selectedProduct?.colorNumber}
                    </div>
                )}
                {selectedProduct?.sku && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        SKU : {selectedProduct?.sku}
                    </div>
                )}
                {selectedProduct?.displayName && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Display Name : {selectedProduct?.displayName}
                    </div>
                )}
                {selectedProduct?.costPrice && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Cost Price : {selectedProduct?.costPrice}
                    </div>
                )}
                {selectedProduct?.tax && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Tax : {selectedProduct?.tax}
                    </div>
                )}
                {selectedProduct?.brand?.name && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Brand: {selectedProduct?.brand?.name}
                    </div>
                )}
                {selectedProduct?.sellPrice && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        SRP : {selectedProduct?.sellPrice}
                    </div>
                )}
                {selectedProduct?.resellerPrice && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Resell Price : {selectedProduct?.resellerPrice}
                    </div>
                )}
                {!_.isNil(selectedProduct?.discount) && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Discount : {selectedProduct.discount}
                    </div>
                )}
                {!_.isNil(selectedProduct?.incentiveAmount) && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Incentive Amount : {selectedProduct?.incentiveAmount}
                    </div>
                )}
                {selectedProduct?.MRP && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        MRP : {selectedProduct.MRP}
                    </div>
                )}
                {(selectedProduct?.oldBarcode || selectedProduct?.newBarcode) && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Barcode :{" "}
                        {selectedProduct?.oldBarcode
                            ? selectedProduct?.oldBarcode
                            : selectedProduct.newBarcode}
                    </div>
                )}
                {selectedProduct?.gender && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Gender : {selectedProduct?.gender}
                    </div>
                )}
                {selectedProduct?.warranty && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Warranty : {selectedProduct?.warranty}
                    </div>
                )}
                {selectedProduct?.weight && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Weight : {selectedProduct?.weight}
                    </div>
                )}
                {selectedProduct?.unit?.name && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Unit : {selectedProduct?.unit?.name}
                    </div>
                )}
                {selectedProduct?.modelNumber && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Model Number : {selectedProduct?.modelNumber}
                    </div>
                )}
                {selectedProduct?.manageStock && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Manage Stock : {selectedProduct?.manageStock ? "Yes" : "No"}
                    </div>
                )}
                {selectedProduct?.inclusiveTax && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Inclusive Tax : {selectedProduct?.inclusiveTax ? "Yes" : "No"}
                    </div>
                )}
                {selectedProduct?.features && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Feature :
                        {selectedProduct?.features
                            ? selectedProduct?.features?.map((el) => el.name).join(", ")
                            : []}
                    </div>
                )}
                {selectedProduct?.frameCollection && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Collection :
                        {selectedProduct?.frameCollection
                            ? selectedProduct?.frameCollection?.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.frameColor && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Color :
                        {selectedProduct?.frameColor
                            ? selectedProduct?.frameColor?.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.frameMaterial && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Material :
                        {selectedProduct?.frameMaterial
                            ? selectedProduct?.frameMaterial?.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.frameShape && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Shape :
                        {selectedProduct?.frameShape
                            ? selectedProduct?.frameShape.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.frameStyle && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Style :
                        {selectedProduct?.frameStyle
                            ? selectedProduct?.frameStyle.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.frameType && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Type :
                        {selectedProduct?.frameType ? selectedProduct?.frameType?.name : {}}
                    </div>
                )}
                {selectedProduct?.frameDimensions && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Dimension:
                        {selectedProduct?.frameDimensions}
                    </div>
                )}
                {selectedProduct?.frameSize && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Size:
                        {selectedProduct?.frameSize}
                    </div>
                )}
                {selectedProduct?.frameWidth && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Frame Width : {selectedProduct?.frameWidth}
                    </div>
                )}
                {selectedProduct?.prescriptionType && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Prescription Type :
                        {selectedProduct?.prescriptionType
                            ? selectedProduct?.prescriptionType?.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.templeMaterial && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Temple Material :
                        {selectedProduct?.templeMaterial
                            ? selectedProduct?.templeMaterial?.name
                            : {}}
                    </div>
                )}
                {selectedProduct?.templeColor && (
                    <div className="p-4 border border-grey m-1 rounded-sm">
                        Temple Color :
                        {selectedProduct?.templeColor
                            ? selectedProduct?.templeColor?.name
                            : {}}
                    </div>
                )}
            </div>
        </ModalBasic>
    );
};

export default ViewProductsModal;
