import React, { useEffect } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { useFormik } from 'formik'
import FormikSelectGroup from "../../components/formik/FormikSelectGroup"
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

import { generateOptions } from '../../utils/Utils'

const LinkGenerator = () => {
 
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      type: ''
    },
    onSubmit: (values) => {
      console.log(values, "sfgrgsrjk")
      navigate(`/generateLink/${values.type}`)
    }
  })

  

  return (
    <PageWithCard heading='Generate Link' >
         
          <form onSubmit={formik.handleSubmit} className='flex flex-col gap-6'>
            <FormikSelectGroup
              formik={formik}
              name="type"
              label="Select Type"
              options={generateOptions({
                array: [
                  { label: "All Glasses", value: "all" },
                  { label: "Eye Glasses", value: "eyeGlasses" },
                  { label: "Accessories", value: "accessories" },
                  { label: "Sun Glasses", value: "sunGlasses" },
                  { label: "Spectacle Lens", value: "spectacleLens" },
                  { label: "Contact Lens", value: "contactLens" },
                  { label: "Reading Glasses", value: "readingGlasses" },
                  { label: "Contact Solutions", value: "contactSolutions" },
                ],
                valueField: "value",
                labelField: "label",
              })}
              required
            />
            <div >
              <PrimaryButton type='submit'>Submit</PrimaryButton>
            </div>
          </form>     


    </PageWithCard>
  )
}

export default LinkGenerator