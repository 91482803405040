import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "coupon";

export const createCoupon = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCoupons = fetchData.generateThunk(SLICE_NAME, "coupon/admin");
export const updateCoupon = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const updateGeneralCoupon = editData.generateThunk(
  SLICE_NAME,
  "/coupon/general"
);
export const deleteCoupon = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCoupon = fetchEditData.generateThunk(
  SLICE_NAME,
  "coupon/admin"
);
const couponSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createCoupon, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchCoupons, SLICE_NAME),
    ...editData.generateExtraReducers(updateCoupon, SLICE_NAME),
    ...editData.generateExtraReducers(updateGeneralCoupon, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCoupon, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCoupon, SLICE_NAME),
  },
});

export const getCoupons = (state) => state.coupons;
export default couponSlice.reducer;
