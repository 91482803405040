import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchData, fetchEditData } from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";
import { authAxiosInstance } from "../../../utils/axiosConfig";
import { toast } from "react-toastify";
import QueryString from "qs";

const initialState = {
  frameType: [],
  loading: false,
};

export const fetchFrameTypes = createAsyncThunk(
  `fetchFrameType/fetchData`,
  async (data, { rejectWithValue }) => {
    try {
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`master/frameType?${string}`);
      return resp.data;
    } catch (error) {
      console.error(error);
      toast.error("failed to fetch data");
      return rejectWithValue(JSON.stringify(error));
    }
  }
);

const frameType = createSlice({
  name: "frameType",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFrameTypes.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchFrameTypes.fulfilled]: (state, action) => {
      state.loading = false;
      state.frameType = action.payload.data;
    },
    [fetchFrameTypes.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const getFrameType = (state) => state.frameType;
export default frameType.reducer;
