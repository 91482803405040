import React, { useEffect, useMemo, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { X } from 'react-feather';
import { ClipLoader } from 'react-spinners';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikCheckbox from '../../components/formik/FormikCheckbox';

import { array, boolean, number, object, string } from 'yup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { createCoupon } from '../../app/reducers/Coupon/couponSlice';
import StoreFrontModal from './StoreFrontModal';
import { generateOptions } from '../../utils/Utils';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { COUPON_PAGE_TYPE, COUPON_USES_TYPE } from '../../utils/dropdownOptions';
import { fetchBrands, getBrands } from '../../app/reducers/Brand/brandSlice';
import { useSelector } from 'react-redux';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';

const AddGeneralCoupon = () => {
  const dispatch = useDispatch();
  const { brands } = useSelector(getBrands);
  useEffect(() => {
    dispatch(fetchBrands());
  }, []);

  const brandArr = useMemo(() => (brands ? brands : [][brands]));

  const formik = useFormik({
    initialValues: {
      code: '',
      expiryDate: '',
      status: true,
      maxUse: '',
      photo: '',
      rate: '',
      type: '',
      minPrice: '',

      description: '',
      category: '',
      brand: [],
      is_public: false,
      is_global: false,
    },
    validationSchema: object({
      code: string().required(),
      expiryDate: string().required(),
      maxUse: number().required(),
      status: boolean().required(),
      rate: number().required(),
      photo: string(),
      description: string(),
      minPrice: number(),
      category: string(),
      brand: array(),
      type: string().required(),
      is_public: boolean().required(),
      is_global: boolean(),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log('values==>', values);
      dispatch(createCoupon({ ...values, __t: 'generalCoupon' }));
      resetForm();
    },
  });
  return (
    <PageWithCard heading="Create Coupon">
      {formik.isSubmitting ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="Coupon Code" name="code" formik={formik} required />
          <FormikInputDateGroup label="Valid Till" name="expiryDate" formik={formik} required />
          <FormikInputGroup
            type="number"
            label="Maximum Use"
            name="maxUse"
            formik={formik}
            required
          />
          <FormikInputGroup type="number" label="Rate" name="rate" formik={formik} required />
          <FormikDirectFileUpload
            name={`photo`}
            formik={formik}
            label={`Photo`}
            location={`eyesdeal/coupon/`}
            randomize={true}
          />
          <FormikTextareaGroup name={`description`} formik={formik} label={`Description`} />
          <FormikSelectGroup
            name={`type`}
            label="Select Type"
            formik={formik}
            options={generateOptions({
              array: COUPON_PAGE_TYPE,
              labelField: 'label',
              valueField: 'value',
            })}
          />
          <FormikSelectGroup
            formik={formik}
            name="category"
            label="Select Category"
            options={generateOptions({
              array: [
                { label: 'Eye Glasses', value: 'eyeGlasses' },
                { label: 'Accessories', value: 'accessories' },
                { label: 'Sun Glasses', value: 'sunGlasses' },
                { label: 'Spectacle Lens', value: 'spectacleLens' },
                { label: 'Contact Lens', value: 'contactLens' },
                { label: 'Reading Glasses', value: 'readingGlasses' },
                { label: 'Contact Solutions', value: 'contactSolutions' },
              ],
              valueField: 'value',
              labelField: 'label',
            })}
          />
          <FormikMultiSelect
            formik={formik}
            name="brand"
            label="Select Brand"
            options={generateOptions({
              array: brandArr,
              valueField: '_id',
              labelField: 'name',
            })}
          />
          <FormikSelectGroup
            name={'uses_type'}
            label="Uses type"
            formik={formik}
            options={COUPON_USES_TYPE}
          />
          <FormikInputGroup
            type="number"
            label="Min Price"
            name="minPrice"
            formik={formik}
            required
          />
          <FormikCheckbox name="status" label="Status" formik={formik} />
          <FormikCheckbox name="is_public" label="Show in public" formik={formik} />
          <FormikCheckbox name="is_global" label="Global Coupon" formik={formik} />
          <div>
            <PrimaryButton type="submit">submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddGeneralCoupon;
