import { useMemo } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Trash } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import MediaLibraryAssets from "../MediaLibrary/MediaLibraryAssets";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  deleteCoupon,
  fetchCoupons,
  getCoupons,
} from "../../app/reducers/Coupon/couponSlice";
import moment from "moment";
import { CouponEditForm } from "./CouponEditForm";
import PaginationClassic from "../../components/pagination/PaginationClassic";

export const ViewGeneralCoupon = () => {
  const [page, setPage] = useState(1);
  const { coupon, loading } = useSelector(getCoupons);
  const { baseUrl } = useSelector(getMediaLibrary);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [singleCouponData, setSingleCouponData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if (params.group_name) {
      dispatch(
        fetchCoupons({
          __t: "generalCoupon",
          populate: true,
          group_name: params.group_name,
          page,
        })
      );
    } else {
      dispatch(fetchCoupons({ __t: "generalCoupon", populate: true, page }));
    }
  }, [page]);

  const data = useMemo(() => (coupon?.docs ? coupon?.docs : []), [coupon]);

  const handleEditCoupon = (e, storeFrontData) => {
    e.stopPropagation();
    setSingleCouponData(storeFrontData);
    setEditModalOpen(true);
  };

  const cols = [
    {
      Header: "Photo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.photo && (
              <img src={baseUrl + row.original.photo} height={70} width={100} />
            )}
          </div>
        );
      },
    },
    {
      Header: "Coupon Code",
      accessor: "code",
    },
    {
      Header: "Valid Till",
      Cell: ({ row }) => {
        return (
          <div>{moment(row.original.expiryDate).format("DD/MM/YYYY")}</div>
        );
      },
    },
    {
      Header: "Max Use",
      accessor: "maxUse",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Min Price",
      accessor: "minPrice",
    },
    {
      Header: "Group",
      accessor: "group_name",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={() => {
                navigate(`/coupon/editGeneralCoupon/${row.original._id}`);
              }}
            >
              Edit General Coupon
            </PrimaryButton>
            <Trash
              onClick={async () => {
                setIsLoading(true);
                await dispatch(deleteCoupon({ id: row.original._id }));
                if (params.group_name) {
                }
                if (params.group_name) {
                  dispatch(
                    fetchCoupons({
                      __t: "generalCoupon",
                      populate: true,
                      group_name: params.group_name,
                    })
                  );
                } else {
                  dispatch(
                    fetchCoupons({ __t: "generalCoupon", populate: true })
                  );
                }
                setIsLoading(false);
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="View General Coupon">
      <MediaLibraryAssets />
      <CouponEditForm
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        data={singleCouponData}
      />
      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <>
          <TableWithHeadingAndSearch
            data={data}
            columns={cols}
            heading="View General Coupon"
          />
          <PaginationClassic
            paginationDetails={{
              ...coupon,
              docs: coupon.docs,
            }}
            setPage={setPage}
          />
        </>
      )}
    </PageWithCard>
  );
};
