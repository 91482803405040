import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editPage,
  fetchPage,
  fetchPages,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import { useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { async } from "regenerator-runtime";
import { Delete, Trash2, X } from "react-feather";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { COMPONENTS_LIST, PAGE_STATUS } from "../../utils/dropdownOptions";

import ComponentFormModal from "../../components/componentFormModal/ComponentFormModal";
import AddComponentModal from "../../components/addComponentModal/AddComponentModal";
import { deleteComponent } from "../../app/reducers/Components/componentSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import EditPageContentModal from "./EditPageContentModal";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr className="w-full">
        {headers?.map((header) => (
          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-semibold text-left">{header}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const EditPageData = () => {
  const { id } = useParams();
  const [pageContentData, setPageContentData] = useState([]);
  const [editPageContentModalOpen, setEditPageContentModalOpen] =
    useState(false);
  const { editDataLoading, elementEditData } = useSelector(getPages);
  const dispatch = useDispatch();
  const [isViewComponentModalOpen, setIsViewComponentModalOpen] =
    useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [addNewComponentOpen, setAddNewComponentOpen] = useState(null);

  useEffect(() => {
    dispatch(fetchPage({ _id: id, populate: true }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: elementEditData,
    onSubmit: async (values, { resetForm }) => {
      const { content: contentArr, ...rest } = values;
      const pageData = {
        _id: id,
        ...rest,
      };

      const content = values?.content?.length
        ? values.content.map((ele) => ({
            name: ele.name,
            component: ele?.component?._id,
          }))
        : [];
      pageData.content = content;

      const pageResp = await dispatch(editPage(pageData));
      await dispatch(fetchPage({ _id: id, populate: true }));
    },
  });

  const handleDeleteComponent = async (ele) => {
    const confirm = window.confirm(
      "are you sure you want to delete this component"
    );
    if (confirm) {
      const remainingData = elementEditData.content.filter(
        (item) => item.component._id !== ele.component._id
      );

      await dispatch(
        deleteComponent({ name: ele.name, component: ele.component._id })
      );
      await dispatch(editPage({ _id: id, content: remainingData }));

      await dispatch(fetchPage({ _id: id, populate: true }));
    }
  };

  return (
    <PageWithCard heading="Edit Page">
      <EditPageContentModal
        pageContentData={pageContentData}
        editPageContentModalOpen={editPageContentModalOpen}
        setEditPageContentModalOpen={setEditPageContentModalOpen}
      />
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <div className="flex flex-col gap-3">
          <ComponentFormModal
            selectedComponent={selectedComponent}
            setIsViewComponentModalOpen={setIsViewComponentModalOpen}
            isViewComponentModalOpen={isViewComponentModalOpen}
            pageId={id}
          />
          <AddComponentModal
            formik={formik}
            addNewComponentOpen={addNewComponentOpen}
            setAddNewComponentOpen={setAddNewComponentOpen}
            pageId={id}
          />
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <div className="w-full mb-8 flex flex-col gap-4">
              <ShowDropdown allowedRoles={["admin"]} heading="Add Seo Details">
                <FormikInputGroup
                  label="Title"
                  name="seoTitle"
                  formik={formik}
                />
                <FormikTextareaGroup
                  label="Description"
                  name="seoDescription"
                  formik={formik}
                />
                <FormikDirectFileUpload
                  name={`seoImage`}
                  formik={formik}
                  label={`Image `}
                  location={`eyesdeal/website/image/seo/`}
                  randomize={true}
                />
              </ShowDropdown>
            </div>
            <FormikInputGroup
              name="name"
              label="Page Name"
              formik={formik}
              required
            />
            <FormikSelectGroup
              label="Page Status"
              name={"status"}
              options={PAGE_STATUS}
              formik={formik}
            />

            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
            <table className="text-sm divide-y divide-slate-200 w-full">
              <TableHeader headers={["Components", "Action"]} />

              {elementEditData?.content &&
                elementEditData.content.map((ele, i) => (
                  <tr key={i}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      {ele.name}
                    </td>

                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <PrimaryButton
                        onClick={(e) => {
                          console.log(ele.component._id);
                          e.stopPropagation;
                          setIsViewComponentModalOpen(true);
                          setSelectedComponent({
                            component: ele?.component,
                            name: ele.name,
                            index: i,
                          });
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap cursor-pointer">
                      <Trash2
                        onClick={async () => {
                          handleDeleteComponent(ele);
                          // await dispatch(deleteComponent({ name: ele.name, component: ele.component._id, }));
                          // dispatch(fetchPage({ _id: ele._id }));
                        }}
                        color="red"
                      />
                    </td>
                  </tr>
                ))}
            </table>

            <div>
              <PrimaryButton
                onClick={() => {
                  setAddNewComponentOpen(true);
                }}
              >
                Add Component
              </PrimaryButton>
            </div>
          </form>
          <div>
            <SecondaryButton
              onClick={async (e) => {
                e.stopPropagation();
                const pageResp = await dispatch(
                  fetchPage({ _id: id, populate: true })
                );
                if (pageResp) {
                  const pagesData = pageResp?.payload?.data?.docs[0];
                  setPageContentData(pagesData);
                  setEditPageContentModalOpen(true);
                } else {
                  setPageContentData([]);
                }
              }}
              type="button"
            >
              Edit Page Content
            </SecondaryButton>
          </div>
        </div>
      )}
    </PageWithCard>
  );
};

export default EditPageData;
