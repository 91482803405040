import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsers,
  getEditUserData,
  getUsers,
  resetUsers,
  setEditUserId,
} from '../../app/reducers/Users/userSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import EditUserForm from './EditUserForm';
import { resetPrescriptions } from '../../app/reducers/Users/prescriptionSlice';
import { fetchMarketingReferences } from '../../app/reducers/Users/marketingReferenceSlice';
import { useParams, useSearchParams } from 'react-router-dom';

const EditUser = () => {
  //   const { editUserId, users, editUserLoading } = useSelector(getUsers);

  const { id: editUserId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMarketingReferences());
    if (editUserId) {
      dispatch(getEditUserData(editUserId));
    } else {
      dispatch(fetchUsers());
    }
    return () => {
      console.log('============== resetting prescriptions ====================');
      dispatch(resetPrescriptions());
      dispatch(resetUsers());
    };
  }, [editUserId]);

  return (
    <PageWithCard>
      {editUserId ? (
        <>
          {false ? (
            <ClipLoader />
          ) : (
            <>
              <EditUserForm />
            </>
          )}
        </>
      ) : (
        <>
          <label>Select User to edit</label>
          <Select
            options={users.docs.map(user => ({
              label: user.phone,
              value: user._id,
            }))}
            onChange={selectedOption => {
              dispatch(setEditUserId(selectedOption.value));
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          />
        </>
      )}
    </PageWithCard>
  );
};

export default EditUser;
