import React from 'react';
import _ from 'lodash';

const SpecPrescriptionTableView = ({ specsData }) => {
  let left = specsData && specsData.left;
  let right = specsData && specsData.right;
  if (specsData) {
    return (
      <>
        <h1>Specs Power</h1>
        <table className="border-collapse border border-slate-400 table-auto w-full divide-y divide-slate-200">
          {/* Table header */}
          <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
            <tr>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left"></div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RESPH</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RECYL</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RAXIS</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">RVISION</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">ADD</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold">||</div>
              </th>

              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LESPH</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LECYL</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LAXIS</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">LVision</div>
              </th>
              <th className="border border-slate-300 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold">Add</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="max-w-[20px] border text-center">D</td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.distance && right.distance.sph}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.distance && right.distance.cyl}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.distance && right.distance.axis}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.distance && right.distance.vs}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.distance && right.distance.add}</p>
              </td>
              <td></td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.distance && left.distance.sph}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.distance && left.distance.cyl}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.distance && left.distance.axis}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left.distance.vs}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.distance && left.distance.add}</p>
              </td>
            </tr>
            <tr>
              <td className="max-w-[20px] border text-center">N</td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.near && right.near.sph}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.near && right.near.cyl}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.near && right.near.axis}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{right && right.near && right.near.vs}</p>
              </td>
              <td className="max-w-[70px] border p-2"></td>
              <td></td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.near && left.near.sph}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.near && left.near.cyl}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.near && left.near.axis}</p>
              </td>
              <td className="max-w-[70px] border p-2">
                <p>{left && left.near && left.near.vs}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full gap-2 flex mt-2 border p-2">
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Psm(R)</label>

            <p>{right.psm}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Pd(R)</label>

            <p>{right.pd}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Fh(R)</label>

            <p>{right.fh}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">IPD</label>

            <p>{specsData.ipd}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Psm(L)</label>

            <p>{left.psm}</p>
          </div>

          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Pd(L)</label>

            <p>{left.pd}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Fh(L)</label>

            <p>{left.fh}</p>
          </div>
        </div>
        <div className="w-full gap-2 flex mt-2 border p-2">
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Asize</label>
            <p>{specsData.aSize}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Bsize</label>

            <p>{specsData.bSize}</p>
          </div>

          <div className="grow flex gap-1 items-center">
            <label className="text-xs">DBL</label>

            <p>{specsData.dbl}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Fth</label>
            <p>{specsData.fth}</p>
          </div>

          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Pdesign</label>

            <p>{specsData.pDesign}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">Ftype</label>

            <p>{specsData.ft}</p>
          </div>
          <div className="grow flex gap-1 items-center">
            <label className="text-xs">DE</label>

            <p>{specsData.de}</p>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default SpecPrescriptionTableView;
