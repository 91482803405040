import React from 'react'
import FormikInputGroup from '../formik/FormikInputGroup';
import { FieldArray, FormikProvider } from 'formik';
import DangerButton from '../infrastructure/Buttons/DangerButton';
import SecondaryButton from '../infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../infrastructure/Buttons/PrimaryButton';

const CollapseContainer = ({ edit = false, index, formik }) => {
    return (
        <>
            <FormikInputGroup
                name={`content.${index}.component.heading`}
                label="Heading"
                formik={formik}
            />

            <FormikProvider value={formik}>
                <FieldArray
                    name={`content.${index}.component.value`}
                    render={(arrayHelpers) => {
                        return (
                            <>
                                {formik?.values?.content?.[index]?.component?.value?.map(
                                    (column, i) => (
                                        <div className="grid grid-cols-3 gap-3" key={i}>

                                            <FormikInputGroup
                                                name={`content.${index}.component.value.${i}.title`}
                                                formik={formik}
                                                label={`title ${i + 1}`}
                                            />
                                            <FormikInputGroup
                                                name={`content.${index}.component.value.${i}.description`}
                                                formik={formik}
                                                label={`description ${i + 1}`}
                                            />
                                            <div className="flex items-center">
                                                <DangerButton
                                                    onClick={() => {
                                                        arrayHelpers.remove(i);
                                                    }}
                                                >
                                                    Remove
                                                </DangerButton>
                                            </div>
                                        </div>
                                    )
                                )}
                                <div className="my-2 flex gap-4">
                                    <SecondaryButton onClick={() => arrayHelpers.push("")}>
                                        Add More value
                                    </SecondaryButton>
                                    {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
                                </div>
                            </>
                        );
                    }}
                />
            </FormikProvider>
        </>
    )
}

export default CollapseContainer