import React from "react";
import ProductSlider from "./ProductSlider";
import ImageBanner from "./ImageBanner";
import { useFormik } from "formik";
import TwoColumnContainer from "./TwoColumnContainer";
import TestimonialContainer from "./TestimonialContainer";
import CollapseContainer from "./CollapseContainer";
import ParagraphContainer from "./ParagraphContainer";
import StorefrontBanner from "./StorefrontBanner";
import StorefrontSlider from "./StorefrontSlider";
import StorefrontGrid from "./StorefrontGrid";
import ImageGridContainer from "./ImageGridContainer";
import ImageSlider from "./ImageSlider";

const FormSelector = (props) => {
  // console.log("formSelector", props);
  const component = props.component;

  return (
    <>
      {
        {
          productSlider: <ProductSlider {...props} />,
          imageBanner: <ImageBanner {...props} />,
          twoColumnContainer: <TwoColumnContainer {...props} />,
          testimonialContainer: <TestimonialContainer {...props} />,
          collapseContainer: <CollapseContainer {...props} />,
          paragraphContainer: <ParagraphContainer {...props} />,
          storefrontBanner: <StorefrontBanner {...props} />,
          storefrontSlider: <StorefrontSlider {...props} />,
          storefrontGrid: <StorefrontGrid {...props} />,
          imageGridContainer: <ImageGridContainer {...props} />,
          imageSlider: <ImageSlider {...props} />,
        }[component]
      }
    </>
  );
};

export default FormSelector;
