import React, { useEffect, useState } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  editPage,
  fetchPage,
  fetchPages,
  getPages,
} from "../../app/reducers/Page/pageSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";

const EditPageContentModal = ({
  pageContentData,
  editPageContentModalOpen,
  setEditPageContentModalOpen,
}) => {
  const [dragData, setDragData] = useState([]);
  const { elementEditData } = useSelector(getPages);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData },
    onSubmit: async (values) => {
      await dispatch(editPage({ ...values, content: dragData }));
      await dispatch(fetchPage({ _id: pageContentData?._id }));
      setEditPageContentModalOpen(false);
    },
  });

  useEffect(() => {
    setDragData(pageContentData?.content);
  }, [pageContentData]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderItems = reorder(
      dragData,
      result.source.index,
      result.destination.index
    );
    setDragData(reorderItems);
  }

  return (
    <ModalBasic
      title={"Edit Page Content"}
      modalOpen={editPageContentModalOpen}
      setModalOpen={setEditPageContentModalOpen}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-4 p-4 h-full "
      >
        {dragData && (
          <div className="overflow-y-auto h-full">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="body-item flex flex-col gap-2 w-full "
                  >
                    {dragData?.map((asset, index) => (
                      <Draggable
                        draggableId={asset._id}
                        index={index}
                        key={asset._id}
                      >
                        {(provided, snapshot) => (
                          <div
                            className=" border-dotted border-2 body-item-draggable p-3 w-full "
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={index}
                          >
                            <div
                              key={index}
                              className="text text-base font-semibold"
                            >
                              {asset?.name}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default EditPageContentModal;
