export const CONTACTFIELDS = {
  __t: "contacts",
  name: "",
  doctorName: "",
  prescribedBy: "",
  relation: "",
  gender: "",
  right: {
    k: "",
    dia: "",
    bc: "",
    distance: {
      add: "",
      sph: "",
      cyl: "",
      axis: "",
    },
    near: {
      sph: "",
      cyl: "",
      axis: "",
    },
  },
  left: {
    k: "",
    dia: "",
    bc: "",
    distance: {
      add: "",
      sph: "",
      cyl: "",
      axis: "",
    },
    near: {
      sph: "",
      cyl: "",
      axis: "",
    },
  },
};
