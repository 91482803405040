import React from "react";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

export const ProductPhotoSlider = ({ data }) => {
  const { baseUrl } = useSelector(getMediaLibrary);
  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      navigation={true}
      modules={[Navigation]}
    >
      {data && data?.photos?.length > 0
        ? data?.photos?.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={`${baseUrl}${img}`} />
            </SwiperSlide>
          ))
        : "No Image "}
    </Swiper>
  );
};
