import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { fetchCoupons } from "../../app/reducers/Coupon/couponSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import {
  COUPON_PAGE_TYPE,
  COUPON_USES_TYPE,
} from "../../utils/dropdownOptions";

export const CouponEditForm = ({ modalOpen, setModalOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...data, is_public: data?.is_public || false },
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        _id: data._id,
        ...values,
      };
      try {
        setIsLoading(true);
        const updatedStoreFront = await authAxiosInstance.patch(
          `coupon`,
          payload
        );
        await dispatch(fetchCoupons({ __t: "product" }));
        resetForm();
        setModalOpen(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error("Failed to update Store Front");
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={`Edit Coupon`}
    >
      {isLoading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col p-5 gap-2"
        >
          <FormikInputGroup
            label="Coupon Code"
            name="code"
            formik={formik}
            required
          />
          <FormikInputDateGroup
            label="Valid Till"
            name="expiryDate"
            formik={formik}
            required
          />
          <FormikInputGroup
            type="number"
            label="Maximum Use"
            name="maxUse"
            formik={formik}
            required
          />
          <FormikSelectGroup
            name={`type`}
            label="Select Type"
            formik={formik}
            options={generateOptions({
              array: COUPON_PAGE_TYPE,
              labelField: "label",
              valueField: "value",
            })}
          />
          <FormikInputGroup
            type="number"
            label="Rate"
            name="rate"
            formik={formik}
            required
          />
          <FormikDirectFileUpload
            name={`photo`}
            formik={formik}
            label={`Photo`}
            location={`eyesdeal/coupon/`}
            randomize={true}
          />
          <FormikTextareaGroup
            name="description"
            formik={formik}
            label="Description"
          />
          <FormikSelectGroup
            name={"uses_type"}
            label="Uses type"
            formik={formik}
            options={COUPON_USES_TYPE}
          />
          <FormikCheckbox name="status" label="Status" formik={formik} />
          <FormikCheckbox
            name="is_public"
            label="Show in public"
            formik={formik}
          />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </ModalBasic>
  );
};
