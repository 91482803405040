import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteStoreFront,
  fetchStoreFront,
  fetchStoreFronts,
  getStoreFront,
  updateStoreFront,
} from '../../app/reducers/StoreFront/storeFrontSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useMemo } from 'react';
import { Trash } from 'react-feather';
// import { PhotoProductModal } from './PhotoProductModal';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
// import { AddMoreProduct } from './AddMoreProduct';
import QueryString from 'qs';
import { fetchCoupon, fetchCoupons, getCoupons } from '../../app/reducers/Coupon/couponSlice';
import { AddMoreProduct } from './AddMoreProduct';
import { PhotoProductModal } from '../StoreFront/PhotoProductModal';

export const EditCoupon = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [singleProductData, setSingleProductData] = useState(null);
  const { elementEditData, editDataLoading } = useSelector(getCoupons);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [couponId, setCouponId] = useState();
  useEffect(() => {
    dispatch(fetchCoupon({ _id: id, __t: 'product', populate: true }));
  }, [id]);

  const viewMore = (e, id) => {
    e.stopPropagation();
    setSingleProductData(id);
    setPhotoModalOpen(true);
  };

  const data = useMemo(
    () => (elementEditData?.product ? elementEditData?.product : []),
    [elementEditData]
  );

  const cols = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <>
            {row.original?.product?.photos?.length > 0 ? (
              <div style={{ position: 'relative' }} className=" w-[52px]">
                <img
                  src={`https://s3.ap-south-1.amazonaws.com/eyesdeal.blinklinksolutions.com/${row.original?.product?.photos[0]}`}
                  style={{ width: '50px', height: '50px' }}
                />
                {row.original?.product?.photos.length > 0 && (
                  <div
                    style={{
                      textDecoration: 'underline',
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                    onClick={e => viewMore(e, row?.original?.product)}
                  >
                    View More
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
    {
      Header: 'SKU',
      accessor: 'sku',
    },
    {
      Header: 'Barcode',
      Cell: ({ row }) => {
        return row?.original?.oldBarcode ? row?.original?.newBarcode : '';
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <Trash
              onClick={async () => {
                setIsLoading(true);
                const filterProduct = data?.filter((el, i) => el._id !== row?.original?._id);
                try {
                  const updateCoupon = await authAxiosInstance.patch(`coupon`, {
                    _id: id,
                    product: filterProduct,
                  });
                  await dispatch(fetchCoupon({ _id: id, __t: 'product', populate: true }));
                  setIsLoading(false);
                } catch (error) {
                  console.log(error);
                  setIsLoading(false);
                } finally {
                  setIsLoading(false);
                }
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];

  const handleAddMoreProduct = e => {
    e.stopPropagation();
    setEditModalOpen(true);
    setCouponId(id);
  };
  useEffect(() => {
    console.log(editModalOpen, 'edit open');
  }, [editModalOpen]);

  return (
    <PageWithCard heading="Coupon Product">
      <AddMoreProduct
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        couponId={couponId}
      />
      <PhotoProductModal
        photoModalOpen={photoModalOpen}
        setPhotoModalOpen={setPhotoModalOpen}
        data={singleProductData}
      />
      {editDataLoading || isLoading ? (
        <ClipLoader />
      ) : (
        <>
          <TableWithHeadingAndSearch
            data={data}
            columns={cols}
            heading={`${elementEditData?.code} - Coupon Product`}
          />
        </>
      )}
      <PrimaryButton onClick={e => handleAddMoreProduct(e)} className={'mt-3'}>
        Add more Product
      </PrimaryButton>
    </PageWithCard>
  );
};
