import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "lensPackage";

export const createLensPackage = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchLensPackages = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const updateLensPackage = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteLensPackage = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchLensPackage = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
const lensPackage = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createLensPackage , SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchLensPackages, SLICE_NAME),
    ...editData.generateExtraReducers(updateLensPackage, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteLensPackage, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchLensPackage, SLICE_NAME),
  },
});

export const getLensPackage = (state) => state.lensPackage;
export default lensPackage.reducer;
