import { createSlice } from "@reduxjs/toolkit";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = "storeFront";

export const createStoreFront = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchStoreFronts = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const updateStoreFront = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteStoreFront = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchStoreFront = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
const storeFrontSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {},
  extraReducers: {
    ...createData.generateExtraReducers(createStoreFront, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchStoreFronts, SLICE_NAME),
    ...editData.generateExtraReducers(updateStoreFront, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteStoreFront, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStoreFront, SLICE_NAME),
  },
});

export const getStoreFront = (state) => state.storeFront;
export default storeFrontSlice.reducer;
