import CartAnalysis from "../pages/CartAnalysis/CartAnalysis";
import ConvertToSale from "../pages/ConvertToSale/ConvertToSale";
import AddCoupon from "../pages/Coupons/AddCoupon";
import AddGeneralCoupon from "../pages/Coupons/AddGeneralCoupon";
import { EditCoupon } from "../pages/Coupons/EditCoupon";
import EditCouponUpload from "../pages/Coupons/EditCouponUpload";
import EditGeneralCOupon from "../pages/Coupons/EditGeneralCoupon";
import GiftCard from "../pages/Coupons/GiftCard";
import { ViewCoupons } from "../pages/Coupons/ViewCoupons";
import { ViewGeneralCoupon } from "../pages/Coupons/ViewGeneralCoupon";
import ViewGiftCard from "../pages/Coupons/ViewGiftCard";
import Dashboard from "../pages/Dashboard";
import AddEmails from "../pages/Emails/AddEmails";
import EditEmails from "../pages/Emails/EditEmails";
import ViewEmails from "../pages/Emails/ViewEmails";
import EditCategory from "../pages/Lens/EditCategory";
import EditPackage from "../pages/Lens/EditPackage";
import { LensCategory } from "../pages/Lens/LensCategory";
import { LensPackage } from "../pages/Lens/LensPackage";
import { ViewLensPackage } from "../pages/Lens/ViewLensPackage";
import LinkGenerator from "../pages/LinkGenerater/LinkGenerator";
import LinkQuery from "../pages/LinkGenerater/LinkQuery";
import MediaLibrary from "../pages/MediaLibrary/MediaLibrary";
import CreatePage from "../pages/Page/CreatePage";
import EditPage from "../pages/Page/EditPage";
import EditPageData from "../pages/Page/EditPageData";
import ViewPage from "../pages/Page/ViewPage";
import ViewProducts from "../pages/Products/ViewProducts";
import Sale from "../pages/Sale/Sale";
import { CreateStoreFront } from "../pages/StoreFront/CreateStoreFront";
import { EditStoreFront } from "../pages/StoreFront/EditStoreFront";
import EditStoreFrontBulkUpload from "../pages/StoreFront/EditStoreFrontBulkUpload";
import { ViewStoreFront } from "../pages/StoreFront/ViewStoreFront";
import EditUser from "../pages/Users/EditUser";

export const routes = [
  {
    route: "/",
    element: <Dashboard />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/convertToSale/:id",
    element: <ConvertToSale />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/sale/:websiteOrderId/:storeId",
    element: <Sale />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/editUser/:websiteOrderId/:storeId/:id",
    element: <EditUser />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/generateLink",
    element: <LinkGenerator />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/generateLink/:type",
    element: <LinkQuery />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/emails/addEmails",
    element: <AddEmails />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/emails/viewEmails",
    element: <ViewEmails />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/emails/editEmails",
    element: <EditEmails />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/page/createPage",
    element: <CreatePage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/page/viewPage",
    element: <ViewPage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/page/editPage",
    element: <EditPage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/page/editPage/:id",
    element: <EditPageData />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/lens/category",
    element: <LensCategory />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/lens/package",
    element: <LensPackage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/lens/view/package",
    element: <ViewLensPackage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/lens/edit/category/:id",
    element: <EditCategory />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/lens/edit/package/:id",
    element: <EditPackage />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/storeFront/createStoreFront",
    element: <CreateStoreFront />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/storeFront/viewStoreFront",
    element: <ViewStoreFront />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/storeFront/viewStoreFront/:id",
    element: <EditStoreFront />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/storeFront/editStoreFrontBulkUpload/:id",
    element: <EditStoreFrontBulkUpload />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/products/ViewProducts",
    element: <ViewProducts />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/mediaLibrary",
    element: <MediaLibrary />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/mediaLibrary/*",
    element: <MediaLibrary />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/cartAnalysis",
    element: <CartAnalysis />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/createCoupon",
    element: <AddCoupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/viewCoupon",
    element: <ViewCoupons />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/viewCoupon/:id",
    element: <EditCoupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/editCouponBulkUpload/:id",
    element: <EditCouponUpload />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/editGeneralCoupon/:id",
    element: <EditGeneralCOupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/createGeneralCoupon",
    element: <AddGeneralCoupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/viewGeneralCoupon",
    element: <ViewGeneralCoupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/giftCard",
    element: <GiftCard />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/viewGiftCard",
    element: <ViewGiftCard />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
  {
    route: "/coupon/viewGiftCard/:group_name",
    element: <ViewGeneralCoupon />,
    allowedRoles: ["admin", "ecommerce_manager"],
  },
];
