import React, { useEffect, useState } from "react";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { X } from "react-feather";
import { ClipLoader } from "react-spinners";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikCheckbox from "../../components/formik/FormikCheckbox";

import { array, boolean, number, object, string } from "yup";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { createCoupon } from "../../app/reducers/Coupon/couponSlice";
import {
  fetchStoreFront,
  fetchStoreFronts,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import StoreFrontModal from "./StoreFrontModal";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  COUPON_PAGE_TYPE,
  COUPON_USES_TYPE,
} from "../../utils/dropdownOptions";
import { generateOptions } from "../../utils/Utils";

const AddCoupon = () => {
  const dispatch = useDispatch();
  const [storeFrontModal, setStoreFrontModal] = useState(false);
  useEffect(() => {
    // dispatch(fetchStoreFronts());
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
      expiryDate: "",
      status: true,
      photo: "",
      description: "",
      maxUse: "",
      rate: "",
      type: "",
      product: [""],
      is_public: false,
    },
    validationSchema: object({
      code: string().required(),
      expiryDate: string().required(),
      status: boolean().required(),
      photo: string().required(),
      maxUse: string().required(),
      description: string(),
      rate: number().required(),
      type: string().required(),
      product: array(),
      is_public: boolean().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(createCoupon({ ...values, __t: "product" }));
      formik.setFieldValue("product", [""]);
      resetForm();
    },
  });
  return (
    <PageWithCard heading="Create Coupon">
      <StoreFrontModal
        storeFrontModal={storeFrontModal}
        setStoreFrontModal={setStoreFrontModal}
        formik={formik}
      />
      {formik.isSubmitting ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup
            label="Coupon Code"
            name="code"
            formik={formik}
            required
          />
          <FormikInputDateGroup
            label="Valid Till"
            name="expiryDate"
            formik={formik}
            required
          />
          <FormikInputGroup
            type="number"
            label="Maximum Use"
            name="maxUse"
            formik={formik}
            required
          />
          <FormikSelectGroup
            name={`type`}
            label="Select Type"
            formik={formik}
            options={generateOptions({
              array: COUPON_PAGE_TYPE,
              labelField: "label",
              valueField: "value",
            })}
          />
          <FormikInputGroup
            type="number"
            label="Rate"
            name="rate"
            formik={formik}
            required
          />
          <FormikDirectFileUpload
            name={`photo`}
            formik={formik}
            label={`Photo`}
            location={`eyesdeal/coupon/`}
            randomize={true}
          />
          <FormikTextareaGroup
            name={`description`}
            formik={formik}
            label={`Description`}
          />
          <FormikSelectGroup
            name={"uses_type"}
            label="Uses type"
            formik={formik}
            options={COUPON_USES_TYPE}
          />
          <FormikCheckbox name="status" label="Status" formik={formik} />
          <FormikCheckbox
            name="is_public"
            label="Show in public"
            formik={formik}
          />
          {/* <div>
            <PrimaryButton
              onClick={e => {
                e.stopPropagation();
                console.log('Hi');
                setStoreFrontModal(true);
              }}
              type="button"
            >
              Fetch Store Front
            </PrimaryButton>
          </div> */}
          <FormikProvider value={formik}>
            <FieldArray
              name="product"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-4 border rounded-lg p-5">
                    {formik.values.product.map((ele, i) => (
                      <div
                        key={i}
                        className="grid grid-cols-2 gap-4 w-full border p-8  rounded-lg"
                      >
                        <FormikAsyncSelect
                          name={`product.${i}`}
                          formik={formik}
                          label="Product"
                          getOptions={async (value) => {
                            console.log("getOptions", value);
                            const query = {
                              search: value,
                              activeInWebsite: true,
                            };
                            const string = QueryString.stringify(query);
                            const products = await authAxiosInstance.get(
                              `products/product?${string}`
                            );

                            const options = products.data.data.docs?.map(
                              (ele) => {
                                const barcode =
                                  ele?.oldBarcode ?? ele?.newBarcode;
                                return {
                                  label: `${barcode}/${ele.sku}`,
                                  value: ele._id,
                                };
                              }
                            );
                            return options;
                          }}
                        />
                        <div>
                          <DangerButton
                            className="mt-7"
                            onClick={() => arrayHelpers.remove(i)}
                          >
                            Remove
                          </DangerButton>
                        </div>
                      </div>
                    ))}
                    <div className="flex gap-4">
                      <PrimaryButton
                        type="button"
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                      >
                        Add Product
                      </PrimaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddCoupon;
