import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";
import PersistLogin from "./components/infrastructure/PersistLogin";
import "./css/style.scss";
import Login from "./pages/Auth/Login";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/Auth/Logout";
import RouteWithHeaderAndSidebar from "./components/infrastructure/RouteWithHeaderAndSidebar";
import "react-datepicker/dist/react-datepicker.css";
import MediaLibraryAssets from "./pages/MediaLibrary/MediaLibraryAssets";
import { routes } from "./utils/routes";
import RoleAccess from "./pages/RoleAccess";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <MediaLibraryAssets />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route exact path="/logout" element={<Logout />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<RouteWithHeaderAndSidebar />}>
              {routes.map((ele, i) => (
                <Route
                  key={i}
                  element={<RoleAccess roles={ele.allowedRoles} />}
                >
                  <Route path={ele.route} element={ele.element} />
                </Route>
              ))}
            </Route>
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
