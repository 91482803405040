import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'orders';

export const fetchOrders = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

const orderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
    resetOrders: (state, action) => {
      state.orders = {};
      state.loading = false;
    },
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchOrders, SLICE_NAME),
  },
});

export const getOrders = state => state.orders;
export const { setEditId, resetOrders } = orderSlice.actions;
export default orderSlice.reducer;
