import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { fetchStoreFronts } from "../../app/reducers/StoreFront/storeFrontSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";

export const StoreFrontEditForm = ({ modalOpen, setModalOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...data },
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        _id: data._id,
        ...values,
      };
      try {
        setIsLoading(true);
        const updatedStoreFront = await authAxiosInstance.patch(
          `storeFront`,
          payload
        );
        await dispatch(fetchStoreFronts());
        resetForm();
        setModalOpen(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error("Failed to update Store Front");
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={`Edit Store Front`}
    >
      {isLoading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col p-5 gap-2"
        >
          <div className="w-full mb-8 flex flex-col gap-4">
            <ShowDropdown allowedRoles={["admin"]} heading="Add Seo Details">
              <FormikInputGroup label="Title" name="seoTitle" formik={formik} />
              <FormikTextareaGroup
                label="Description"
                name="seoDescription"
                formik={formik}
              />
              <FormikDirectFileUpload
                name={`seoImage`}
                formik={formik}
                label={`Image `}
                location={`eyesdeal/website/image/storefront/seo/`}
                randomize={true}
              />
            </ShowDropdown>
          </div>
          <FormikInputGroup
            formik={formik}
            name="name"
            label="Name"
            // required
            readOnly
          />

          <FormikDirectFileUpload
            name={`banner`}
            formik={formik}
            label={`Image Banner`}
            location={`eyesdeal/website/banner/storeFront/`}
            randomize={true}
          />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </ModalBasic>
  );
};
