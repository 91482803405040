import React, { useEffect, useState } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useDispatch, useSelector } from "react-redux";
import {
  editProduct,
  fetchProduct,
  fetchProducts,
  getProducts,
} from "../../app/reducers/Products/productSlice";
import { useFormik } from "formik";

import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { X } from "react-feather";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";

const QuickEditProductModal = ({ quickEditOpen, setQuickEditOpen }) => {
  const dispatch = useDispatch();
  const { baseUrl } = useSelector(getMediaLibrary);

  const [DragData, setDragData] = useState([]);
  const { elementEditData } = useSelector(getProducts);
  console.log(
    "🚀 ~ file: QuickEditProductModal.jsx:10 ~ QuickEditProductModal ~ products:",
    elementEditData
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData },
    onSubmit: (values) => {
      dispatch(editProduct({ ...values, photos: DragData }));
    },
  });

  useEffect(() => {
    setDragData(elementEditData?.photos);
  }, [elementEditData?.photos]);
  console.log(DragData, "dsdfaf");
  const reorder = (list, startIndex, endIndex) => {
    console.log("reorderList", list);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    console.log("result", result);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderItems = reorder(
      DragData,
      result.source.index,
      result.destination.index
    );
    console.log("reorderItems", reorderItems);
    setDragData(reorderItems);
  }

  console.log(DragData, "values");

  return (
    <ModalBasic
      title={"Edit Product"}
      modalOpen={quickEditOpen}
      setModalOpen={setQuickEditOpen}
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
        <FormikInputGroup
          name={"displayName"}
          formik={formik}
          label="Display Name"
        />
        <FormikInputGroup
          name={"sellPrice"}
          formik={formik}
          label="Sell Price"
        />
        {DragData && (
          <div className="grid h-[10rem] gap-6 overflow-x-auto">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="body-item"
                  >
                    {DragData?.map((asset, index) => (
                      <Draggable draggableId={asset} index={index} key={asset}>
                        {(provided, snapshot) => (
                          <div
                            className="border body-item-draggable"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={index}
                          >
                            {console.log(asset, "asset")}
                            <X
                              size={20}
                              className="cursor-pointer z-40"
                              onClick={async (e) => {
                                e.stopPropagation();
                                const string = QueryString.stringify({
                                  keys: [asset],
                                });
                                const resp = await authAxiosInstance.delete(
                                  `/mediaLibrary?${string}`
                                );

                                if (resp) {
                                  let newPhotos = formik.values.photos;
                                  let updateData = {
                                    photos: newPhotos.filter(
                                      (d, i) => i !== index
                                    ),
                                    _id: formik.values._id,
                                    __t: formik.values.__t,
                                  };
                                  const updatedProduct =
                                    await authAxiosInstance.patch(
                                      `products/${updateData.__t}`,
                                      updateData
                                    );
                                  if (updatedProduct) {
                                    window.location.reload();
                                  }
                                }
                              }}
                            />
                            <img src={`${baseUrl}${asset}`} key={index} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default QuickEditProductModal;
