import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchInventories,
  getInventory,
} from "../../app/reducers/Inventory/inventorySlice";
import { ClipLoader } from "react-spinners";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { getWebsiteOrders } from "../../app/reducers/websiteOrders/websiteOrderSlice";
import { percentOf } from "../../utils/Utils";

const ProductRow = ({ productId, storeId, formik, i }) => {
  const dispatch = useDispatch();
  const { inventory, loading } = useSelector(getInventory);
  const { websiteOrder } = useSelector(getWebsiteOrders);
  const date = new Date(websiteOrder?.docs?.[0]?.createdAt);

  useEffect(() => {
    dispatch(
      fetchInventories({
        populate: true,
        "product._id": productId,
        "store._id": storeId,
      })
    ).then((resp) =>
      formik.setFieldValue(
        `productStock.${i}.${productId}`,
        resp.payload?.data?.docs[0]?.quantity
      )
    );
  }, []);

  return (
    <>
      {loading ? (
        <ClipLoader />
      ) : (
        <React.Fragment key={productId}>
          <tr>
            <td className="px-2 py-3">
              {inventory?.docs?.[0]?.product?.newBarcode}
            </td>
            <td className="px-2 py-3">{inventory?.docs?.[0]?.product?.sku}</td>
            <td className="px-2 py-3">
              <img
                src={`${"https://s3.ap-south-1.amazonaws.com/eyesdeal.blinklinksolutions.com/"}${
                  inventory?.docs?.[0]?.product?.photos?.[0]
                }`}
                style={{ width: "150px", height: "150px" }}
                alt="No Image"
              />
            </td>
            <td className="px-2 py-3">{inventory?.docs?.[0]?.quantity}</td>

            <td className="px-2 py-3">{inventory?.docs?.[0]?.product?.MRP}</td>
            <td className="px-2 py-3">
              {inventory?.docs?.[0]?.product?.sellPrice}
            </td>
            <td className="px-2 py-3">
              {inventory?.docs?.[0]?.product?.tax}% (inc.)
            </td>
            <td className="px-2 py-3">
              {percentOf(
                inventory?.docs?.[0]?.product?.sellPrice,
                inventory?.docs?.[0]?.product?.tax
              )}
            </td>
            <td className="px-2 py-3">
              {inventory?.docs?.[0]?.product?.discount} %
            </td>
            <td className="px-2 py-3">
              {Number(inventory?.docs?.[0]?.product?.sellPrice)}
            </td>
          </tr>
        </React.Fragment>
      )}
    </>
  );
};

export default ProductRow;
