import { useMemo } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStoreFront,
  fetchStoreFronts,
  getStoreFront,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import { useEffect } from "react";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import MediaLibraryAssets from "../MediaLibrary/MediaLibraryAssets";
import { StoreFrontEditForm } from "./StoreFrontEditForm";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import ShowDropdown from "../../components/infrastructure/ShowDropdown";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { toast } from "react-toastify";

export const ViewStoreFront = () => {
  const { storeFront, loading } = useSelector(getStoreFront);
  const { baseUrl } = useSelector(getMediaLibrary);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [singleStoreFrontData, setSingleStoreFrontData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchStoreFronts());
  }, []);

  const data = useMemo(
    () => (storeFront?.docs ? storeFront?.docs : []),
    [storeFront]
  );

  const handleEditStoreFront = (e, storeFrontData) => {
    e.stopPropagation();
    setSingleStoreFrontData(storeFrontData);
    setEditModalOpen(true);
  };

  const cols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Seo",
      Cell: ({ row }) => {
        const {
          seoTitle: title,
          seoDescription: description,
          seoImage: image,
        } = row?.original;
        return (
          <div className="">
            <ShowDropdown allowedRoles={["admin"]} heading="Seo Data">
              {title || description || image ? (
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <div style={{ position: "relative" }}>
                      <img
                        src={`${baseUrl}${image}`}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 justify-left items-center">
                    <p className="text text-base font-semibold">Title:</p>
                    <p>{title}</p>
                  </div>
                  <div className="flex flex-row gap-1 justify-left items-center">
                    <p className="text text-base font-semibold">Description:</p>
                    <p className="whitespace-pre-wrap">{description}</p>
                  </div>
                </div>
              ) : (
                <div>No Seo Data Found</div>
              )}
            </ShowDropdown>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={(e) => handleEditStoreFront(e, row?.original)}
            >
              Edit Store Front
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setIsLoading(true);
                navigate(`/storeFront/viewStoreFront/${row.original._id}`);
                setIsLoading(false);
              }}
            >
              Edit Store Front Product
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                navigate(
                  `/storeFront/editStoreFrontBulkUpload/${row.original._id}`
                );
              }}
            >
              Edit BulkUpload
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                const url = `https://eyesdeal.com/storefront/${row?.original?.name}`;

                navigator.clipboard.writeText(url);
                toast.info("url copied");
              }}
            >
              Copy Link
            </PrimaryButton>

            <Trash
              onClick={async () => {
                setIsLoading(true);
                await dispatch(deleteStoreFront({ id: row.original._id }));
                dispatch(fetchStoreFronts());
                setIsLoading(false);
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWithCard heading="View Store Front">
      <MediaLibraryAssets />
      <StoreFrontEditForm
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        data={singleStoreFrontData}
      />
      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch
          data={data}
          columns={cols}
          heading="View Store Front"
        />
      )}
    </PageWithCard>
  );
};
