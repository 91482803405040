import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStoreFront, getStoreFront } from '../../app/reducers/StoreFront/storeFrontSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikFileInput from '../../components/formik/FormikFileInput';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { parseCsv } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { fetchCoupon, getCoupons } from '../../app/reducers/Coupon/couponSlice';

const EditCouponUpload = () => {
  const { id } = useParams();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { elementEditData } = useSelector(getCoupons);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCoupon({ _id: id, populate: true }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData, csv: '' },
    onSubmit: async (values, { resetForm }) => {
      const files = values.csv;
      const parseCsvResp = await parseCsv(files);
      const parsedSku = parseCsvResp?.data?.data?.docs;
      if (parsedSku?.length > 0) {
        setProgressLoading(true);
        setTotalItem(parsedSku.length);

        for (let i = 0; i < parsedSku.length; i++) {
          let skuData = parsedSku[i];
          console.log(skuData);
          try {
            if (skuData.sku) {
              const string = QueryString.stringify({
                sku: skuData.sku,
              });

              const filteredProduct = await authAxiosInstance.get(`products/product?${string}`);

              const filteredProductObj = filteredProduct?.data?.data?.docs[0];
              const query = QueryString.stringify({ _id: id });
              const couponDataResp = await authAxiosInstance.get(`/coupon?${query}`);
              const filterCouponData = couponDataResp?.data?.data?.docs[0];

              const existingProductInCoupon = filterCouponData?.product?.findIndex(
                el => el === filteredProductObj._id
              );
              if (existingProductInCoupon === -1) {
                const { product, ...rest } = filterCouponData;

                const updatedProduct = [...product, filteredProductObj._id];
                let updatedData = { ...rest, product: updatedProduct };

                const updatedStoreFront = await authAxiosInstance.patch(
                  `coupon?${query}`,
                  updatedData
                );
                if (updatedStoreFront) {
                  setCurrentItem(prevState => prevState + 1);
                }
              } else {
                setErrorData(prev => [
                  ...prev,
                  {
                    ...parsedSku[i],
                    error: 'This Product Is Already present In Coupon',
                  },
                ]);
              }
            } else {
              setErrorData(prev => [
                ...prev,
                {
                  ...parsedSku[i],
                  error: 'Data not found & please check key must be sku',
                },
              ]);
            }
          } catch (error) {
            setCurrentItem(prevState => prevState + 1);
            toast.error('Error uploading Product');
            setErrorData(prev => [
              ...prev,
              {
                ...parsedSku[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      }
    },
  });
  return (
    <PageWithCard heading="BulkUpload">
      {progressLoading ? (
        <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <FormikInputGroup label="Coupon COde" name="code" formik={formik} readOnly />
          <FormikFileInput name="csv" formik={formik} label="Upload Csv File" />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditCouponUpload;
