import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchStoreFront,
  getStoreFront,
} from "../../app/reducers/StoreFront/storeFrontSlice";
import { useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { parseCsv } from "../../utils/Utils";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import ProgressBar from "../../components/progressBar/ProgressBar";

const EditStoreFrontBulkUpload = () => {
  const { id } = useParams();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { elementEditData } = useSelector(getStoreFront);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStoreFront({ _id: id, populate: true }));
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...elementEditData, csv: "" },
    onSubmit: async (values, { resetForm }) => {
      const files = values.csv;
      const parseCsvResp = await parseCsv(files);
      const parsedSku = parseCsvResp?.data?.data?.docs;
      if (parsedSku?.length > 0) {
        setProgressLoading(true);
        setTotalItem(parsedSku.length);

        for (let i = 0; i < parsedSku.length; i++) {
          let skuData = parsedSku[i];
          try {
            if (skuData.sku) {
              const string = QueryString.stringify({
                sku: skuData.sku,
              });

              const filteredProduct = await authAxiosInstance.get(
                `products/product?${string}`
              );

              const filteredProductObj = filteredProduct?.data?.data?.docs[0];

              let { storeFront, ...restFields } = filteredProductObj;

              const query = QueryString.stringify({ _id: id });
              const storeFrontDataResp = await authAxiosInstance.get(
                `/storeFront?${query}`
              );
              const filteredStoreFrontObj =
                storeFrontDataResp?.data?.data?.docs[0];

              const existingProductInStoreFront =
                filteredStoreFrontObj?.product?.findIndex(
                  (el) => el === filteredProductObj._id
                );
              if (existingProductInStoreFront === -1) {
                const { product, ...rest } = filteredStoreFrontObj;

                const updatedProduct = [...product, filteredProductObj];
                let updatedData = { ...rest, product: updatedProduct };

                const updatedStoreFront = await authAxiosInstance.patch(
                  `storeFront?${query}`,
                  updatedData
                );

                const existingStoreFront = storeFront.findIndex(
                  (el) => el._id === id
                );
                if (existingStoreFront === -1) {
                  const updatedStoreFrontData = [
                    ...storeFront,
                    filteredStoreFrontObj,
                  ];

                  const updatedProductStoreFront = {
                    ...restFields,
                    storeFront: updatedStoreFrontData,
                  };
                  const queryString = QueryString.stringify({
                    _id: filteredProductObj._id,
                  });

                  const updatedProductData = await authAxiosInstance.patch(
                    `products/product?${queryString}`,
                    updatedProductStoreFront
                  );
                } else {
                  setErrorData((prev) => [
                    ...prev,
                    {
                      ...parsedSku[i],
                      error: "Some of The Store Front  Is Already there",
                    },
                  ]);
                }

                if (updatedStoreFront && existingStoreFront === -1) {
                  setCurrentItem((prevState) => prevState + 1);
                }
              } else {
                setErrorData((prev) => [
                  ...prev,
                  {
                    ...parsedSku[i],
                    error: "This Product Is Already present In Store Front",
                  },
                ]);
              }
            } else {
              setErrorData((prev) => [
                ...prev,
                {
                  ...parsedSku[i],
                  error: "Data not found & please check key must be sku",
                },
              ]);
            }
          } catch (error) {
            setCurrentItem((prevState) => prevState + 1);
            toast.error("Error uploading Product");
            setErrorData((prev) => [
              ...prev,
              {
                ...parsedSku[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      }
    },
  });
  return (
    <PageWithCard heading="BulkUpload">
      {progressLoading ? (
        <ProgressBar
          currentItem={currentItem}
          totalItem={totalItem}
          errorData={errorData}
        />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <FormikInputGroup
            label="StoreFront"
            name="name"
            formik={formik}
            readOnly
          />
          <FormikFileInput name="csv" formik={formik} label="Upload Csv File" />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditStoreFrontBulkUpload;
