import React from "react";
import FormikInputGroup from "../formik/FormikInputGroup";
import { FieldArray, FormikProvider } from "formik";
import DangerButton from "../infrastructure/Buttons/DangerButton";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import PrimaryButton from "../infrastructure/Buttons/PrimaryButton";
import FormikEditorInput from "../formik/FormikEditorInput";

const ParagraphContainer = ({ edit = false, index, formik }) => {
  return (
    <>
      <FormikInputGroup
        name={`content.${index}.component.title`}
        label="title"
        formik={formik}
      />
      <FormikInputGroup
        name={`content.${index}.component.sub_heading`}
        label="Sub Heading"
        formik={formik}
      />
      <FormikEditorInput
        name={`content.${index}.component.paragraph`}
        label="Paragraph"
        formik={formik}
      />

      <div className="my-2 flex gap-4">
        {edit && <PrimaryButton type="submit">Submit</PrimaryButton>}
      </div>
    </>
  );
};

export default ParagraphContainer;
