import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "sales";

export const fetchSales = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSales = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSales = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteSales = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSale = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const salesSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSales, SLICE_NAME),
    ...editData.generateExtraReducers(editSales, SLICE_NAME),
    ...createData.generateExtraReducers(createSales, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteSales, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchSale, SLICE_NAME),
  },
});

export const getSales = (state) => state.sales;
export default salesSlice.reducer;
