import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrescription,
  editPrescription,
  getPresctiptions,
  openPrescriptionModal,
  removeEditPrescriptionData,
} from "../../app/reducers/Users/prescriptionSlice";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import {
  GENDER,
  PRESCRIBEDBY,
  PRESCRIPTIONTYPE,
  RELATIONS,
} from "../../utils/dropdownOptions";
import { SPECSFIELDS } from "../../utils/specsFields";
import SpecsPrescriptionTable from "./SpecsPrescriptionTable";
import _ from "lodash";

const PrescriptionModal = ({ initialFormik }) => {
  const { addPrescriptionModalOpen, editPrescriptionData } =
    useSelector(getPresctiptions);

  const formik = useFormik({
    initialValues: {
      name: initialFormik?.values?.name,
      relation: RELATIONS[0],
      prescribedBy: { label: "Select", value: "" },
      gender: initialFormik?.values?.gender,
      doctorName: "",
      ...(_.isEmpty(editPrescriptionData) ? SPECSFIELDS : editPrescriptionData),
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (_.isEmpty(editPrescriptionData)) {
        dispatch(addPrescription(values));
      } else {
        dispatch(editPrescription(values));
      }
      dispatch(openPrescriptionModal(""));
      dispatch(removeEditPrescriptionData());
      resetForm({
        values: {
          name: "",
          gender: "",
          ...SPECSFIELDS,
        },
      });
    },
  });

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (addPrescriptionModalOpen === "specs") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [addPrescriptionModalOpen]);
  useEffect(() => {
    if (!modalOpen) {
      dispatch(openPrescriptionModal(""));
    }
  }, [modalOpen]);

  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Add Power"
      max_width="max-w-8xl"
      ignoreClick={true}
    >
      <div className="p-4">
        <form onSubmit={formik.handleSubmit}>
          {/* <div className="flex gap-4 flex-wrap"> */}
          {/* <div className="flex-grow"> */}
          {/* </div> */}
          {/* </div> */}
          <div className="flex gap-4 flex-wrap">
            <div className="flex-grow">
              <FormikInputGroup
                formik={formik}
                name="pres_id"
                required
                label="prescriptionId"
                readOnly
              />
            </div>
            <div className="flex-grow">
              <FormikInputGroup
                formik={formik}
                name="doctorName"
                required
                label={
                  formik.values.prescribedBy == "employee"
                    ? "Employee Name"
                    : "Doctor Name"
                }
              />
            </div>
            <div className="flex-grow">
              <FormikInputGroup
                formik={formik}
                name="__t"
                required
                label="Type"
                readOnly
              />
            </div>
            <div className="flex-grow">
              <FormikSelectGroup
                formik={formik}
                options={PRESCRIBEDBY}
                name="prescribedBy"
                label="Prescribed By"
                required
              />
            </div>
          </div>
          <div className="mt-2">
            <SpecsPrescriptionTable formik={formik} />
          </div>
          {_.isEmpty(editPrescriptionData) ? (
            <PrimaryButton className="mt-4" type="submit">
              Add
            </PrimaryButton>
          ) : (
            <PrimaryButton className="mt-4" type="submit">
              OK
            </PrimaryButton>
          )}
        </form>
      </div>
    </ModalBasic>
  );
};

export default PrescriptionModal;
